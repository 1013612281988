import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { DismissalFormValues } from 'Components/interfaces';
import { useGetMeQuery } from 'Services/authApi';
import { useGetEmployeesFullListQuery } from 'Services/employeesApi';
import { useCreateDismissalMutation } from 'Services/Applications/dismissalApi';
import useRoleHooks from 'Routers/role-hooks';

export const useDismissalHooks = () => {
  const [showDataTable, setShowDataTable] = useState<boolean>(true);
  const [createDismissal, { isSuccess, isLoading }] =
    useCreateDismissalMutation();
  const { data: employees } = useGetEmployeesFullListQuery();
  const { data: currentUser } = useGetMeQuery();
  const { currentRole } = useRoleHooks();
  const minDate = new Date().toISOString().split('T')[0];
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<DismissalFormValues | FieldValues>({
    mode: 'all',
    defaultValues: {
      employee:
        currentRole !== 'HR'
          ? `${currentUser?.data.name} ${currentUser?.data.surname}`
          : undefined,
    },
  });

  const onSubmit = (data: any) => {
    // @ts-ignore
    if (currentRole === 'HR') {
      data.user_id = data.employee.id;
      delete data.employee;
    } else {
      delete data.employee;
    }
    createDismissal(data);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowDataTable(true);
      toast.success(t('Aplication_Page.Successfully_Created'));
      reset();
    }
  }, [isSuccess]);

  return {
    showDataTable,
    currentRole,
    employees,
    isLoading,
    control,
    minDate,
    errors,
    watch,
    register,
    onSubmit,
    handleSubmit,
    setShowDataTable,
  };
};
