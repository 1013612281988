import { useTranslation } from "react-i18next";
import moment from "moment";
export const useApplicationItem = ()=>{
    const {t} = useTranslation();
    const formatDate = (date:Date)=>{
        return (
            moment(date).format("DD / MM / YYYY")
        )
    }
    return {
        t,
        formatDate
    }
}