import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { VacationFormValues } from 'Components/interfaces';
import { calculateDateDifference } from 'Utils';
import { useCreateVacationMutation } from 'Services/Applications/vacationApi';
import { useGetEmployeesFullListQuery } from 'Services/employeesApi';
import { useGetMeQuery } from 'Services/authApi';
import { AppScreenType } from 'Pages/interfaces';
import useRoleHooks from 'Routers/role-hooks';

export const useVacationHooks = (
  cb?: Dispatch<SetStateAction<AppScreenType>>
) => {
  const { data: currentUser } = useGetMeQuery();
  const { currentRole } = useRoleHooks();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<VacationFormValues | FieldValues>({
    mode: 'all',
    defaultValues: {
      user_id:
        currentUser && currentUser?.data?.employee
          ? `${currentUser?.data.name} ${currentUser?.data.surname}`
          : undefined,
    },
  });
  const total = calculateDateDifference(watch('start_date'), watch('end_date'));
  const [createVacation, { isSuccess }] = useCreateVacationMutation();
  const [showDataTable, setShowDataTable] = useState<boolean>(true);
  const { data: employees } = useGetEmployeesFullListQuery();

  const onSubmit: SubmitHandler<VacationFormValues | FieldValues> = (
    data: VacationFormValues | FieldValues
  ) => {
    const payload = {
      start_date: data.start_date,
      end_date: data.end_date,
      total: total,
      user_id: data.user_id.id ?? currentUser?.data?.id,
    };
    createVacation(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowDataTable(true);
      cb && cb!(AppScreenType.HISTORY);
      toast.success(t('Aplication_Page.Successfully_Created'));
      reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (currentUser && currentUser?.data?.employee) {
      setValue(
        'user_id',
        currentUser && currentUser?.data?.employee
          ? `${currentUser?.data.name} ${currentUser?.data.surname}`
          : undefined
      );
    }
  }, [currentUser]);

  const employeeList = employees?.result?.map((item) => {
    return {
      id: item.userId,
      name: `${item?.user?.name} ${item?.user?.surname}`,
    };
  });

  return {
    showDataTable,
    isValid,
    errors,
    total,
    watch,
    register,
    onSubmit,
    handleSubmit,
    setShowDataTable,
    employeeList,
    control,
    currentRole,
  };
};
