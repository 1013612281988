import { useGetGroupsQuery } from 'Services/groupsApi';
import { useState } from 'react';

export const useGroupHooks = () => {
  const [page, setPage] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = (e: Event) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const {
    data: groupList,
    isLoading,
    isError,
  }: any = useGetGroupsQuery({ page, offset: 12 });

  return {
    page,
    setPage,
    groupList,
    handleOpen,
    handleClose,
    open,
    isLoading,
    isError,
  };
};
