import Button from 'Components/Button/button';
import { ButtonTypes } from 'Components/interfaces';
import noDataImg from '../../Assets/Images/No notifcations.svg';
import styles from './noData.module.scss';

interface IProps {
  withButton: boolean;
  btnText?: string;
  title?: string;
  description?: string;
  btnOnclick?: () => void;
}

const NoData: React.FC<IProps> = (props) => {
  const { withButton, btnText, btnOnclick, title, description } = props;
  return (
    <div className={styles['no-data__cont']}>
      <div className={styles['no-data__section']}>
        <img className={styles['no-data__section-img']} src={noDataImg} alt='img'/>
        <div className={styles['no-data__section-title']}>
          {title}
        </div>
        <div className={styles['no-data__section-description']}>
          {description}
        </div>
        {withButton && (
          <div className={styles['no-data__section-btn-cont']}>
            <Button
              buttonType={ButtonTypes.Primery}
              title={btnText}
              onClick={btnOnclick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NoData;
