import { createApi } from '@reduxjs/toolkit/query/react';
import { IPensionData } from 'Components/interfaces';
import {
  IAvailableStatusesResp,
  IChangeStatusData,
  ICreateDismissalResp,
  IPaginationQuery,
  IPensionByIdResp,
  IPensionListResp,
} from 'Services/responce_types';
import axiosBaseQuery from '../API';


export const pensionApi = createApi({
  reducerPath: 'pensionApi',
  tagTypes: ['Pension'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    createPension: builder.mutation<ICreateDismissalResp, IPensionData>({
      query: (pension) => ({
        url: '/pension',
        method: 'POST',
        data: pension,
      }),
      invalidatesTags: ['Pension'],
    }),
    getUserPensionList: builder.query<IPensionListResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/pension/my_list/${offset}/${page}`,
        method: "GET",
      }),
      providesTags: ["Pension"],
    }),
    getPensionById: builder.query<IPensionByIdResp, number>({
      query: (id) => ({
        url: `/pension/${id}`,
        method: 'GET',
      }),
      providesTags: ['Pension'],
    }),
    getPensionsList: builder.query<IPensionListResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/pension/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Pension'],
    }),
    getPensionAvailableStatuses: builder.query<IAvailableStatusesResp, number>({
      query: (id) => ({
        url: `/pension/available_statuses/${id}`,
        method: "GET",
      }),
    }),
    changePensionStatus: builder.mutation<number, IChangeStatusData>({
      query: (data) => ({
        url: `/pension/update_status/${data?.id}`,
        method: "PUT",
        data: {
          description: data.description,
          status_from_id: data.status_from_id,
          status_to_id: data.status_to_id,
          userId: data?.userId
        },
      }),
      invalidatesTags: ["Pension"],
    }),
  }),
});

export const {
  useGetPensionByIdQuery,
  useGetPensionAvailableStatusesQuery,
  useChangePensionStatusMutation,
  useGetUserPensionListQuery,
  useGetPensionsListQuery,
  useCreatePensionMutation,
} = pensionApi;
