import {
  IAbsenseRespResult,
  IAbsenseRespResultUser,
  ICompany,
} from 'Services/responce_types';
import React from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IValue {
  value: string;
}

interface InviteItemValue {
  email: IValue | null;
  role: IValue | null;
}

export interface InviteRequestData {
  email: string;
  roleId: number;
  companyId: number;
}

export interface RequestFormValues {
  inviteRequests: InviteItemValue[];
}

export interface IOption {
  value: string;
  text: string;
}

export interface IForgetPasswordEmail {
  email: string;
}

export interface INewPassword {
  password: string;
}

export interface InviteError {
  message: string;
}

export type RequestError = {
  value: IRequestErrorMessage;
};

export interface IRequestErrorMessage {
  message: string;
}

export enum ButtonTypes {
  Primery = 'primery-button',
  ButtonWithIcon = 'Button_with_left_icon',
  Disabled = 'disabled_button',
  WithoutBg = 'Without_background',
}

export interface DismissalFormValues {
  employee?: string | number;
  user_id?: number;
  dismissal_date: Date;
  grounds: string;
}

export interface IPensionData {
  employee: string;
  company_id: number;
  user_id?: number;
  start_date: Date;
  end_date: Date;
  files: any[];
}

export interface IReferenceProvideFormValues {
  location: string;
  end_date: Date;
  user_id:number;
  place: string
}

export type VacationFormValues = {
  start_date: Date;
  end_date: Date;
  total: number;
  user_id: number
};

export type EmployeeHistoryFilterFormValues = {
  from:Date | string,
  to:Date | string,
  type:string,
  status:string
}

export interface ISendCodeToEmailData {
  data: ISendCodeToEmailResp;
}

export interface ISendCodeToEmailResp {
  code: number;
  data: boolean;
  message: string;
}

export interface INavList {
  link: string;
  title: string;
  available: boolean
}

export interface ILayoutData {
  title: string;
  grayIcon: string;
  whiteIcon: string;
  activeMenu: string;
  list?: INavList[];
  link?: string;
  available: boolean
}

export interface ILayoutBiRoles {
  highPosition: ILayoutData[],
  employee:ILayoutData[],
  visitor:ILayoutData[],
}

export interface IApplicationTabs {
  label: string;
  path: string;
}

export interface IChangePassword {
  password: string;
  newPassword: string;
}
export interface IChangeEmail{
  email: string
}
export interface IUserData {
  name?: string;
  surname?: string;
  avatar?: string;
  number?: string;
  gender?: string;
  socialSecurityCard?: string;
  birthDate?: string;
}

export interface IChangeUserInfoData {
  data: IUserData;
  id: number;
}

export interface IChangeUserAvatar {
  avatar: string;
  id: number;
}

export enum HistoryTypes {
  Vacation = 'Vacation_history',
  Absence = 'Absence_history',
  Pension = 'Pension_history',
  Dismissal = 'Dismissal_history',
  Reference = 'Reference_history',
}

export interface IPensionCard {
  nameSurname: string;
  startDate: Date;
  endDate: Date;
  status: HistoryItemCardStatusStates;
}

export interface IDismissalCard {
  nameSurname: string;
  date: Date;
  status: HistoryItemCardStatusStates;
}

export interface IReferenceCard {
  place: string;
  endDate: Date;
  status: HistoryItemCardStatusStates;
}

export interface IcustomTabPanelDataItem {
  index: number;
  component: React.ReactNode;
}

export enum HistoryItemStatusTypes {
  'finished',
  'going',
  'rejected',
}

export enum CardTypes {
  WithEndDate = 'With_End_Date',
  WithoutEndDate = 'From_end_date',
}
export type HistoryItemCardStatusStates = 'finished' | 'going' | 'rejected';

export enum HeaderTypes {
  Navigation = 'AuthHeader_Navigation',
  Logo = 'AuthHeader_with_Logo',
}

export interface IVacationData {
  startDate?: Date | string;
  endDate?: Date | string;
  total?: number;
  title?: string;
  status?: HistoryItemCardStatusStates;
  id?: string;
}

export interface IEmployeeItem {
  id: string;
  name: string;
  position: string;
  higher?: boolean;
  source?: string;
}

export interface IGroupItem {
  groupMembers: Array<string | number>;
  membersCount: number;
  groupTLName: any;
  name: string;
  id: number;
}

export interface ILanguages {
  id: number;
  icon: string;
  code: string;
}

export interface IAppDetailInfo {
  label: string;
  value: any;
}

export enum UserProfilePages {
  Main = 'main',
  Edit = 'edit',
  Password = 'password',
}

export interface UserInfoFormValues {
  name?: string,
  surname?: string,
  password?: string,
  email?: string,
  confirmPassword?: string,
  newPassword?: string
}

export interface IAppDetailHistoryData {
  result: IAbsenseRespResult;
  status: number;
}

export enum ApplicationTypes {
  Company_Applications = 'company_Apps',
  User_Applications = 'user_Apps',
}

export enum InvitationsStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Canceled = 'canceled',
}

export interface IMyInvitationRole {
  createdAt: Date;
  id: number;
  name: string;
  updatedAt: Date;
}

export interface IMyInvitationItem {
  company: ICompany;
  createdAt: Date;
  id: number;
  role: IMyInvitationRole;
  roleId: number;
  status: InvitationsStatus;
  user: IAbsenseRespResultUser;
}

export interface IOrder {
  employeeId?:number,
  orderTypes?:string,
  documentNumber?:number,   /// Ենթակա է փոփոխման
  documentDate?:Date,
  serialNumber?:number,
  isHr?:boolean,
  orderTypeFields?:any
}
