import React from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { ICreateGroupForm } from 'Pages/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import { ButtonTypes } from 'Components/interfaces';
import { InputField } from 'Components/AuthComponents/AuthInputs';
import Button from 'Components/Button/button';
import styles from '../authForm.module.scss'

interface ICreateGroupProps {
  register: UseFormRegister<FieldValues | ICreateGroupForm>,
  onSubmit: () => void,
  errors: FieldErrors<ICreateGroupForm | FieldValues>,
  isLoading:boolean
}
const AddGroupForm: React.FC<ICreateGroupProps> = (props) => {
  const { register, onSubmit, errors,isLoading } = props;
  const { t } = useGeneralHooks()

  return (
    <div className={styles.addGropContainer}>
      <div className={styles.header}>
      <div className={styles.title}>
        {t("Groups.Create_Group")}
      </div>
        <p className={styles.describtion}>
          {t("Groups.Describtion")}
        </p>
        </div>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.inputDiv}>
            <InputField
              label={t("Groups.Group_Name")}
              placeholder={t("Groups.Input_Placeholder")}
              register={register}
              registerName='name'
              inputStyle={styles.inputField}
              error={errors.name}
            />
          </div>
          <Button
            buttonType={ButtonTypes.Primery}
            type='submit'
            title={t("Groups.Create")}
            buttonStyle={styles.button}
            isLoading={isLoading}
          />
        </form>
      </div>
  )
}

export default AddGroupForm
