import React from "react";
import CustomPagination from "Components/Pagination/pagination";
import { useGeneralHooks } from "Pages/general.hooks";
import NoData from "Components/NoData/noData";
import useOrderHistoryHooks from "Components/Orders/orderHistory-hooks";
import ResponsiveOrderItem from "Components/ResponsiveComponent/ResponsiveOrders/responsiveOrderItem";
import styles from "./responsivePage.module.scss";

const ResponsivePageOrders: React.FC = () => {
  const { t } = useGeneralHooks();
  const { OrderList, setActivePage, activePage } = useOrderHistoryHooks();
  const options = (OrderList?.result ?? []).map((item) => item);
  return (
    <>
      {
        options?.length ? (
          <div className={styles.container}>
            <div className={styles.cardContent}>
              {options.map(
                (order, index) => (
                  <ResponsiveOrderItem
                    key={order.documentNumber!}
                    documentDate={order?.documentDate!}
                    documentNumber={order?.documentNumber!}
                    orderTypes={order?.orderTypes.orderName!}
                  />
                )
              )}
            </div>
            <div className={styles.buttonContent}>
              {options?.length > 10 && (
                <CustomPagination
                  count={Math.ceil(
                    options?.length! / 10
                  )}
                  page={activePage}
                  onChange={(_, page) => {
                    setActivePage(page);
                    window.scrollTo(0, 0);
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <NoData
            withButton={false}
            title={t("Order.Empty_List")}
          />
        )
      }
    </>
  )
};

export default ResponsivePageOrders;
