import { SearchInputValues } from "Pages/interfaces"
import { useState } from "react"
import { FieldValues, useForm } from "react-hook-form"

const useSearchHooks = () => {
    const [searchValue, setSearchValue] = useState<string>("");
    const { register } = useForm<SearchInputValues | FieldValues>({ mode: "all" });

    return {
        searchValue,
        setSearchValue,
        register
    }
};

export default useSearchHooks