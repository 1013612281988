import { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useGetMeQuery } from 'Services/authApi';
import { IAppDetailInfo } from 'Components/interfaces';
import {
  IAvailableStatusesRespResult,
  IChangeStatusData,
} from 'Services/responce_types';
import { IVacationStatusChangeValues } from 'Pages/interfaces';
import { CurrentStatus } from 'Components/Application/ApplicationItems';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import { useGetDismissalAvailableStatusesQuery } from 'Services/Applications/dismissalApi';
import { useApplicationItem } from 'Components/Application/ApplicationItems/applicationItem.hooks';
import {
  useChangeAbsenceStatusMutation,
  useGetAbsenceByIdQuery,
} from '../../Services/Applications/absenceApi';
import useRoleHooks from 'Routers/role-hooks';

const useAbsenceDetailHooks = () => {
  const { id } = useParams();

  const [changeAbsenceStatus, { isSuccess }] = useChangeAbsenceStatusMutation();
  const { data: absenceByIdInfo, isFetching } = useGetAbsenceByIdQuery(+id!);
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const { data: absenceAvailableStatuses } =
    useGetDismissalAvailableStatusesQuery(
      +absenceByIdInfo?.result?.status?.id!
    );
  const { currentRole } = useRoleHooks();
  const { formatDate } = useApplicationItem();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IVacationStatusChangeValues | FieldValues>({ mode: 'all' });
  const onSubmit: SubmitHandler<IVacationStatusChangeValues | FieldValues> = (
    data
  ) => {
    const payload: IChangeStatusData = {
      id: +id!,
      description: data.description,
      status_to_id: data.status_id.id,
      status_from_id: absenceByIdInfo?.result?.status?.id!,
      userId: absenceByIdInfo?.result?.user?.id,
    };
    changeAbsenceStatus(payload);
  };

  const statuses = absenceAvailableStatuses?.result?.map(
    (item: IAvailableStatusesRespResult) => ({
      name: t(`Statuses_From_Server.${item.statusTo.name}`),
      id: item.statusTo.id,
    })
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        t('Aplication_Page.App_Status.Status_Successfully_Changed')
      );
      reset();
      handleClose();
      // navigate('/main/documents/applications/vacation');
    }
  }, [isSuccess]);

  const renderItemData: IAppDetailInfo[] = [
    {
      label: t('Aplication_Page.Vacation.Start'),
      value: formatDate(absenceByIdInfo?.result?.startDate!),
    },
    {
      label: t('Aplication_Page.Vacation.End'),
      value: formatDate(absenceByIdInfo?.result?.endDate!),
    },
    {
      label: t('Aplication_Page.Vacation.Total'),
      value: `${absenceByIdInfo?.result?.total} ${t(
        'Aplication_Page.Vacation.Day'
      )}`,
    },
    {
      label: t('Aplication_Page.Vacation.Status'),
      value: (
        <CurrentStatus
          onClick={currentRole !== 'EMP' ? handleOpen : () => {}}
          statusName={t(
            `Statuses_From_Server.${absenceByIdInfo?.result?.status?.name}`
          )}
          bgColor={absenceByIdInfo?.result?.status?.color!}
        />
      ),
    },
    {
      label: t('Aplication_Page.Absence.Reason'),
      value: absenceByIdInfo?.result?.reason,
    },
  ];

  return {
    id,
    control,
    errors,
    statuses,
    openModal,
    isFetching,
    renderItemData,
    absenceByIdInfo,
    absenceAvailableStatuses,
    handleSubmit,
    handleClose,
    onSubmit,
    register,
  };
};

export default useAbsenceDetailHooks;
