import React from 'react';
import { FieldErrors, FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Button/button';
import AuthFormTitle from '../AuthForms/authFormTitle';
import { InputField } from '../AuthInputs';
import BottomLink from '../BottomLink/bottomLink';
import hidden from 'Assets/Icons/hidden.svg';
import eye from 'Assets/Icons/eye.svg';
import { ButtonTypes } from 'Components/interfaces';
import { usePassword } from '../AuthInputs/authInput-hooks';
import { IForgetPassword } from 'Pages/interfaces';
import styles from './forgetPassword.module.scss';


interface IForgetPasswordProps {
    onSubmit: () => void,
    register: UseFormRegister<FieldValues>,
    errors: FieldErrors<FieldValues>,
    isValid?: boolean,
    watch: UseFormWatch<IForgetPassword | FieldValues>,
}

const ForgetPasswordForm: React.FC<IForgetPasswordProps> = (props) => {
    const { register, onSubmit, errors, isValid,  watch } = props
    const {password, changeType, confirmPswrd, changeConfirmType} = usePassword()
    const { t } = useTranslation()  
        
    return (
        <>
            <div className={styles.formContainer}>
                <AuthFormTitle 
                    title={t('Forget_Password.Third_page.Title')} 
                    description={t('Forget_Password.Third_page.Description')} />
                <form onSubmit={onSubmit} className={`${styles.forgetPasswordForm} ${styles.formPassword}`}>
                    <InputField
                        type={password ? 'password' : 'text'}
                        id="password"
                        placeholder={t('Forget_Password.Third_page.New_password_placeholder')}
                        label={t('Forget_Password.Third_page.New_password_label')}
                        register={register}
                        registerName='password'
                        message={t('Input_Errors.Password')}
                        patternValue={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#/+_,.^])[A-Za-z\d@$#!%*.,^/+_?&]{8,}$/igm}
                        error={errors.password}
                    >
                        <span onClick={changeType}>
                            <img className={styles.inputIcon} src={password ? hidden : eye} alt='show' />
                        </span>
                    </InputField>
                    <InputField
                        type={confirmPswrd ? 'password' : 'text'}
                        id="confirmPassword"
                        placeholder={t('Forget_Password.Third_page.New_password_placeholder')}
                        label={t('Forget_Password.Third_page.Repeat_password_label')}
                        register={register}
                        registerName='confirmPassword'
                        validation={(value) => value === watch('password') || t('Input_Errors.PasswordMatch')}
                        error={errors.confirmPassword}
                    >
                        <span onClick={changeConfirmType}>
                            <img className={styles.inputIcon} src={confirmPswrd ? hidden : eye} alt='show' />
                        </span>
                    </InputField>
                    <Button
                        buttonType={isValid ? ButtonTypes.Primery : ButtonTypes.Disabled}
                        type='submit'
                        title={t('Forget_Password.Third_page.Submit')}
                        disable={!isValid}
                    />
                </form>
            </div>
            <div className={styles.bottomBox}>
                <BottomLink
                    text={t('Forget_Password.Third_page.Remember_password')}
                    linkTitle={t('Forget_Password.Third_page.Sign_in')}
                    link='/' />
            </div>
        </>
    )
}

export default ForgetPasswordForm