import React, { Dispatch, SetStateAction } from 'react';
import { Controller, FieldErrors, FieldValues, UseFormRegister, Control } from 'react-hook-form';
import { useGeneralHooks } from 'Pages/general.hooks';
import { AutoComplete, InputField } from 'Components/AuthComponents/AuthInputs';
import { ButtonTypes, VacationFormValues } from 'Components/interfaces';
import { IRole } from 'Services/responce_types';
import Button from 'Components/Button/button';
import Arrow from 'Assets/Icons/arrow_back.svg';
import styles from '../appliacationItems.module.scss';


interface IVacationFormProps {
  onSubmit: () => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setShowDataTable?: Dispatch<SetStateAction<boolean>>
  employeeList?: IRole[];
  role: any;
  control?: Control<FieldValues | VacationFormValues, any>;
}

const RefernceProfideForm: React.FC<IVacationFormProps> = (props) => {
  const { t, largeScreen, mobileScreen } = useGeneralHooks();
  const {
    register,
    onSubmit,
    setShowDataTable,
    errors,
    role,
    control,
    employeeList
  } = props;
  const minDate = new Date().toISOString().split('T')[0];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.header}>
            {!largeScreen
              &&
              <div className={styles.title}>
                {t('Aplication_Page.Tab_Labels.Reference')}
              </div>
            }
            <div className={styles.describtion}>
              {t('Aplication_Page.Reference.Description')}
            </div>
          </div>
          <form onSubmit={onSubmit} className={`${styles.formBox} formBox`} >
            <div>
              <div className={styles.form} style={{ width: '100%' }}>
                <InputField
                  type='text'
                  placeholder={t('Aplication_Page.Reference.Input_placeholder')}
                  id='place'
                  label={t('Aplication_Page.Reference.Place')}
                  register={register}
                  registerName='place'
                  error={errors.location}
                  validation={(value) =>
                    value >= minDate || t('Input_Errors.Invalid_Date')
                  }
                  inputStyle={styles.inputField}
                />
                <InputField
                  type='date'
                  minDate={minDate}
                  id='endDate'
                  label={t('Aplication_Page.Reference.Date')}
                  register={register}
                  registerName='end_date'
                  validation={(value) =>
                    value >= minDate || t('Input_Errors.Invalid_Date')
                  }
                  error={errors.endDate}
                  inputStyle={mobileScreen ? styles.inputHalfWidth : styles.inputField}
                />
              </div>
              {role && role === "EMP" ? (
                <InputField
                  type="text"
                  disabled={true}
                  id="worker"
                  label={t("Aplication_Page.Desmissal.Employee")}
                  register={register}
                  registerName="user_id"
                  inputStyle={styles.inputField}
                />
              ) : (
                <Controller
                  control={control}
                  name="user_id"
                  rules={{
                    required: t("Input_Errors.Required"),
                  }}
                  render={({ field: { onChange, name } }) => {
                    return (
                      <AutoComplete
                        name={name}
                        onChange={onChange}
                        id="user_id"
                        data={employeeList}
                        label={t("Aplication_Page.Desmissal.Employee")}
                        placeholder={t("Aplication_Page.Desmissal.Employee_placeholder")}
                        error={errors.user_id}
                      />
                    );
                  }}
                />
              )}
            </div>

            <div className={styles.buttonContent}>
              <Button
                title={t('Aplication_Page.Button')}
                type='button'
                buttonStyle={styles.withoutBtn}
                buttonTitleStyle={styles.withoutBtnTitle}
                buttonType={ButtonTypes.WithoutBg}
                onClick={() => { setShowDataTable!(true) }} />
              <Button
                type='submit'
                rightIcon={Arrow}
                buttonTitleStyle={styles.BtnTitle}
                title={t('Aplication_Page.Vacation.Buuton_Apply')}
                buttonType={ButtonTypes.ButtonWithIcon}
                buttonStyle={styles.btn}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RefernceProfideForm;


