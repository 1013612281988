import React, {useEffect } from 'react';
import { useGeneralHooks } from 'Pages/general.hooks';

type Props = {
    children?: React.ReactNode
}

const PrivatePage: React.FC<Props> = ({children}) => {
   const {navigate,accessToken} = useGeneralHooks();

    useEffect(()=>{
        if(!accessToken){     
            navigate("/login")
        }
    }, [accessToken]);

   return(
    <div style={{width: "100%"}}>{children}</div>
   )
}

export default PrivatePage