import { useGetAllUsersQuery } from 'Services/employeesApi';
import { useState } from 'react';

const UseEmployeesHooks = () => {
  const [page, setPage] = useState<number>(1);
  const { data: allUsersList, isLoading } = useGetAllUsersQuery({
    page,
    offset: 12,
  });
  return { isLoading, allUsersList, page, setPage };
};

export default UseEmployeesHooks;
