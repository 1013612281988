import React from 'react';
import {  TableCell, TableRow } from '@mui/material';
import { useGeneralHooks } from 'Pages/general.hooks';
import styles from './orderHistory.module.scss';
import { DownloadSVG } from 'Assets';
import { IEmployeeOrder } from 'Services/responce_types';

export interface IOrderHistoryProps {
    data: IEmployeeOrder[] | [];
};

const OrderHistoryItem: React.FC<IOrderHistoryProps> = (props) => {
    const { data } = props;
    const { t, formatDate } = useGeneralHooks();

    return (
        <>
            {data?.map((order: IEmployeeOrder, index: number) => {
                return (
                    <TableRow
                        sx={{ mt: 10 }}
                        className={`${styles.orderRow}`}
                        key={index}
                    >
                        <TableCell>
                            {formatDate(order?.documentDate!)}
                        </TableCell>
                        <TableCell>
                            {`№ ${order?.documentNumber}`}
                        </TableCell>
                        <TableCell>{order?.orderTypes?.orderName!}</TableCell>
                        <TableCell>
                            <img src={DownloadSVG} alt='download' />
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export default OrderHistoryItem;
