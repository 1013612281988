import { t } from "i18next";
import HistoryItemStatus from "Components/Application/AppHistory/Status/historyItemStatus";
import defaultImg from "../../Assets/Images/Employee.png";
import styles from "./applicationDetailHistory.module.scss";

interface IProps {
  img?: string | undefined;
  name: string;
  surname: string;
  status_from: string;
  status_from_color: string;
  status_to: string;
  status_to_color: string;
  time: string;
  description?: string | null;
}

const ApplicationDetailHistoryItem: React.FC<IProps> = (props) => {
  const {
    img,
    name,
    surname,
    status_from,
    status_to,
    time,
    description,
    status_from_color,
    status_to_color,
  } = props;
  return (
    <div className={styles["history-item__container"]}>
      <div className={styles["img-container"]}>
        <img
          className={styles["profile-img"]}
          src={img ? `${process.env.REACT_APP_API_KEY}${img}` : defaultImg}
          alt="ProfileImage"
        />
      </div>
      <div className={styles["content"]}>
        <div className={styles["user-info"]}>
          <div className={styles["left-part"]}>
            <div className={styles["name-surname"]}>
              <div className={styles["user-name"]}>
                {name} {surname} {"  "}
              </div>
              <div className={styles["application-status"]}>
                {t("Aplication_Page.App_Status.Changed_Status")}
              </div>
            </div>
          </div>
          <div className={styles["application-action-time"]}>{time}</div>
        </div>
        <div className={styles["statuses-container"]}>
          <div>
            <HistoryItemStatus text={t(`Statuses_From_Server.${status_from}`)} bgColor={status_from_color} />
          </div>
          <span className={styles["arrow"]}>&#8594;</span>
          <div>
            <HistoryItemStatus text={t(`Statuses_From_Server.${status_to}`)} bgColor={status_to_color} />
          </div>
        </div>
        <div className={styles["description"]}>{description}</div>
      </div>
    </div>
  );
};

export default ApplicationDetailHistoryItem;
