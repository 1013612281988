import activeCompanyIcon from '../../Assets/Icons/active-company-icon.svg';
import companyIcon from '../../Assets/Icons/company-icon.svg';
import styles from './drawerCompanies.module.scss';

interface IProps {
  src?: string;
  name?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const DrawerCompanyItem: React.FC<IProps> = (props) => {
  const { src, name, isActive, onClick } = props;
  return (
    <div className={styles['drawer-company__item']} onClick={onClick}>
      <div className={styles['drawer-company__item-left-part']}>
        <img
          src={src ? `${process.env.REACT_APP_API_KEY}${src}` : companyIcon}
          alt='company'
        />
        <div className={styles['drawer-company__item-name']}>{name}</div>
      </div>
      {isActive && (
        <div className={styles['drawer-company__item-current']}>
          <img src={activeCompanyIcon} alt='company' />
        </div>
      )}
    </div>
  );
};

export default DrawerCompanyItem;
