import { useNavigate } from "react-router";
import { useGeneralHooks } from "Pages/general.hooks"
import { useCallback } from "react";

const useLogOutHooks = () => {
    const { t } = useGeneralHooks();
    const title = t("LayoutMenu.Log_Out");
    const navigate = useNavigate();
    const logOut = useCallback(() => {
        localStorage.removeItem("access-token");
        navigate("/")
    }, [navigate]);

    return {
        title,
        logOut
    }
};

export default useLogOutHooks