import { Controller } from "react-hook-form";
import { t } from "i18next";
import Button from "Components/Button/button";
import arrow from "../../Assets/Icons/arrow.svg";
import { CustomModal, Header } from "Components";
import { useGeneralHooks } from "Pages/general.hooks";
import UseMakeEmployeeHooks from "./makeEmployee.hooks";
import mekumLogo from "../../Assets/Icons/Mekum.logo.svg";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { ButtonTypes, HeaderTypes } from "Components/interfaces";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import MekumEmployeeModal from "Components/MekumEmployee/mekumEmployeeModal";
import { useCustomModalHooks } from "Components/CustomModal/customModal.hooks";
import { AutoComplete, InputField } from "Components/AuthComponents/AuthInputs";
import styles from "./makeEmployee.module.scss";

const MakeEmployee: React.FC = () => {
  const { largeScreen, navigate } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();
  const {
    register,
    onSubmit,
    handleSubmit,
    documentTypeList,
    isLoading,
    mekumId,
    control,
    errors,
    defaultDate,
    defaulDocumentTypeValue
  } = UseMakeEmployeeHooks();
  
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();

  return (
    <>
      {!largeScreen && (
        <Header
          type={HeaderTypes.Navigation}
          navTitle={
            window.innerWidth > 480
              ? t("Make_Employee.Add_Employee")
              : t("Make_Employee.Add")
          }
          iconOnClick={() => navigate(-1)}
        />
      )}
      <div className={styles["make-employee-page"]}>
        {largeScreen && (
          <>
            <BreadCrumbs data={pathsToRender} />
            <div className={styles["page-title"]}>
              {t("Make_Employee.Add_Employee")}
            </div>
          </>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${styles["add-employee-form"]} add-employee-form`}
        >
          <div>
            <Controller
              control={control}
              name="document_type_id"
              rules={{
                required: t("Input_Errors.Required"),
              }}
              render={({ field: { onChange, name } }) => {
                return (
                  <AutoComplete
                    value={defaulDocumentTypeValue?.[0] ? defaulDocumentTypeValue?.[0] : null}
                    name={name}
                    onChange={onChange}
                    id="document_type_id"
                    data={documentTypeList?.data}
                    label={t("Make_Employee.Document_Type_Label")}
                    placeholder={
                      window.innerWidth > 480
                        ? t("Make_Employee.Document_Type_Placeholder")
                        : t("Make_Employee.Document_Type_Placeholder_Short")
                    }
                    error={errors.document_type_id}
                  />
                );
              }}
            />
            <InputField
              type="text"
              id="passport_number"
              placeholder={"AM 0434555"}
              label={t("Make_Employee.Pasport_Number")}
              register={register}
              registerName="passport_number"
              message={t("Login_Form.Login_Error")}
              error={errors.passport_number}
              inputStyle={styles.inputField}
            />
            <InputField
              type="date"
              id="date_of_issue"
              label={t("Make_Employee.Due_Date")}
              register={register}
              registerName="date_of_issue"
              error={errors.date_of_issue}
              inputStyle={styles.inputField}
              defaultValue={defaultDate}
            />
            <InputField
              type="number"
              id="authority"
              placeholder={t("Make_Employee.By_Whom_Placeholder")}
              label={t("Make_Employee.By_Whom_Label")}
              register={register}
              registerName="authority"
              message={t("Login_Form.Login_Error")}
              patternValue={/^[0-9]*$/i}
              error={errors.authority}
              inputStyle={styles.inputField}
              minDate={"0"}
            />
            {mekumId && (
              <div
                className={styles["settings-table__row"]}
                onClick={handleOpen}
              >
                <div className={styles["settings-table__content"]}>
                  <div className={styles["left-part"]}>
                    <div className={styles["take-from"]}>
                      {t("Mekum.Take_from")}
                    </div>
                    <img src={mekumLogo} alt="mekum" />
                    <div className={styles["from-mekum"]}>
                      {t("Mekum.From_Mekum")}
                    </div>
                  </div>
                </div>
                <img src={arrow} alt="arrow" />
              </div>
            )} 
            <CustomModal
              open={openModal}
              title={t("Mekum.Get_From_Mekum")}
              loading={isLoading}
              buttonTitle={t("Settings.Join_To")}
              handleClose={handleClose}
              dialogClassname={"settingsModal"}
              children={<MekumEmployeeModal handleClose={handleClose} />}
            ></CustomModal>
          </div>
          <Button
            type="submit"
            buttonTitleStyle={styles.BtnTitle}
            title={t("Aplication_Page.Vacation.Buuton_Apply")}
            buttonType={ButtonTypes.ButtonWithIcon}
            buttonStyle={styles.btn}
            isLoading={isLoading}
          />
        </form>
      </div>
    </>
  );
};

export default MakeEmployee;
