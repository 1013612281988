import React from "react";
import { ButtonTypes, HeaderTypes } from "Components/interfaces";
import { CustomModal, Header, Languages } from "Components";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { SearchInput, TextArea } from "Components/AuthComponents/AuthInputs";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import { useGeneralHooks } from "Pages/general.hooks";
import mekumLogo from "../../Assets/Icons/Mekum.logo.svg";
import arrow from "../../Assets/Icons/arrow.svg";
import enabled from "../../Assets/Icons/enabledIcon.svg";
import useSettingsHooks from "./settings.hooks";
import styles from "./settings.module.scss";

const Settings: React.FC = () => {
  const { pathsToRender } = useAppPathsHooks();
  const { largeScreen, t } = useGeneralHooks();

  const {
    openModal,
    handleClose,
    handleOpen,
    register,
    errors,
    mekumId,
    handleSubmit,
    onSubmit,
    isLoading
  } = useSettingsHooks();

  return (
    <>
      {largeScreen ? (
        <div className={styles["settings-page"]}>
          <div className={styles["settings-page__heading"]}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
          <div className={styles["settings-page__title"]}>
            {t("Settings.Page_Title")}
          </div>
          <div className={styles["settings-table"]}>
            <div className={styles["settings-table__row"]}>
              <div className={styles["settings-table__label"]}>
                {t("Settings.Languages")}
              </div>
              <div className={styles["settings-table__value"]}>
                <Languages />
              </div>
            </div>
            {
              mekumId ?
                (<div className={styles["settings-table__row"]}>
                  <div className={styles["settings-table__connected"]}>
                    <img src={mekumLogo} alt="mekum" />{t("Settings.Connected")}
                  </div>
                  <div className={styles["settings-table__value"]}>
                    <img src={enabled} alt="arrow" />
                  </div>
                </div>) :
                (<div className={styles["settings-table__row"]} onClick={handleOpen}>
                  <div className={styles["settings-table__label"]}>
                    {t("Settings.Join_To")} <img src={mekumLogo} alt="mekum" />
                  </div>
                  <div className={styles["settings-table__value"]}>
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>)
            }
          </div>
          {/* <div> */}
          <CustomModal
            open={openModal}
            title={t("Settings.Join")}
            loading={isLoading}
            buttonTitle={t("Settings.Join_To")}
            onClick={handleSubmit(onSubmit)}
            handleClose={handleClose}
            dialogClassname={'settingsModal'}
          >
            <p className={styles.modalText}>
              {t("Settings.Join_Description")}
            </p>
            <form style={{paddingBottom: '8px'}}>
              <TextArea
                label={t("Settings.Token_Label")}
                placeholder={t("Settings.Token_Placeholder")}
                register={register}
                registerName={'mekum_token'}
                id="token"
                error={errors.mekum_token}
                inputStyle={styles.dismissalField}
                rows={5}
              />
            </form>
          </CustomModal>
        </div>
      ) : (
        <div>
          <Header type={HeaderTypes.Navigation} navTitle={"settings"} />
          <div className={styles["mobile-settings-page"]}>
            <div className={styles["settings-table"]}>
              <div className={styles["settings-table__row"]}>
                <div className={styles["settings-table__label"]}>
                  {t("Settings.Languages")}
                </div>
                <div className={styles["settings-table__value"]}>
                  <Languages />
                </div>
              </div>
              {
                mekumId ?
                  (<div className={styles["settings-table__row"]}>
                    <div className={styles["settings-table__connected"]}>
                      <img src={mekumLogo} alt="mekum" />{t("Settings.Connected")}
                    </div>
                    <div className={styles["settings-table__value"]}>
                      <img src={enabled} alt="arrow" />
                    </div>
                  </div>) :
                  (<div
                    className={styles["settings-table__row"]}
                    onClick={handleOpen}
                  >
                    <div className={styles["settings-table__label"]}>
                      {t("Settings.Join_To")}{" "}
                      <img src={mekumLogo} alt="mekumLogo" />
                    </div>
                    <div className={styles["settings-table__value"]}>
                      <img src={arrow} alt="arrow" />
                    </div>
                  </div>)
              }
            </div>
            <div>
              {/* <CustomModal
                title={t("Settings.Join")}
                handleClose={handleClose}
                open={open}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                typography={t("Settings.Join_Description")}
                textfield
                register={register}
                errors={errors}
                registerName={"mekum_token"}
                isLoading={isLoading}
              /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;
