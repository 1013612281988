import { t } from "i18next";
import Button from "Components/Button/button";
import Employee from "Assets/Images/Employee.png";
import { ButtonTypes } from "Components/interfaces";
import styles from "./mekumEmployee.module.scss";

interface IProps {
  src?: string;
  name: string;
  role?: string;
  onClick: () => void
}

const MekumEmployeeItem: React.FC<IProps> = (props) => {
  const { src, name, role, onClick } = props;
  
  return (
    <div className={styles["mekum-employee-item"]}>
      <div className={styles["mekum-employee-item__content"]}>
        <img
          src={src ? `${process.env.REACT_APP_MEKUM_BASE_URL}${src}` : Employee}
          alt="mekumEmployeeItem"
          className={styles["img"]}
        />
        <div>
          <div className={styles["mekum-employee-item__content__name"]}>
            {name}
          </div>
          <div className={styles["mekum-employee-item__content__role"]}>
            {role}
          </div>
        </div>
      </div>
      <div>
        <Button
          title={t("Mekum.Chose")}
          buttonType={ButtonTypes.Primery}
          buttonStyle={styles.button}
          buttonTitleStyle={styles.buttonTitle}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default MekumEmployeeItem;
