import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import CustomPagination from "Components/Pagination/pagination";
import Loading from "Components/Loading/loading";
import { useGeneralHooks } from "Pages/general.hooks";
import useOrderHistoryHooks from "./orderHistory-hooks";
import NoData from "Components/NoData/noData";
import OrderHistoryItem from "./orderHistoryItem";
import styles from "./orderHistory.module.scss";


const OrderHistory: React.FC = () => {
    const { t } = useGeneralHooks();
    const { OrderList, setActivePage, activePage, isLoading, tabHeaders, } = useOrderHistoryHooks();
    const options = (OrderList?.result ?? []).map((item) => item);
    return (
        <div className="orderTable">
            <div className={styles.container}>
                {isLoading ? (
                    <Loading />
                ) :
                    options.length === 0
                        ? (
                            <NoData
                                withButton={false}
                                title={t("Order.Empty_List")}
                            />
                        ) : (
                            <>
                                <TableContainer sx={{ width: "inherit" }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {tabHeaders.map(
                                                    (title: string, index: number) => {
                                                        return (
                                                            <TableCell
                                                                key={index}
                                                                className={index > 1 ? styles.header : undefined}
                                                            >
                                                                {title}
                                                            </TableCell>
                                                        )
                                                    }
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={styles.tableBody}>
                                            <OrderHistoryItem data={options} />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {options?.length > 10 && (
                                    <CustomPagination
                                        count={Math.ceil(
                                            options?.length!! / 10
                                        )}
                                        page={activePage}
                                        onChange={(_, page) => {
                                            setActivePage(page);
                                            window.scrollTo(0, 0);
                                        }}
                                    />
                                )}
                            </>
                        )}
            </div>
        </div>
    );
};

export default OrderHistory;
