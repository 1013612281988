import React  from 'react';
import { IApplicationComponentProps } from 'Components/Application/ApplicationItems/Vacation/vacationComponent';
import { useVacationHooks } from 'Components/Application/ApplicationItems/Vacation/vacation-hooks';
import VacationForm from 'Components/Application/ApplicationItems/Vacation/vacationForm';
import { AppScreenType } from 'Pages/interfaces';
import useHistory from 'Components/Application/AppHistory/history-hooks';
import { ButtonTypes, CardTypes } from '../../Components/interfaces';
import CustomPagination from 'Components/Pagination/pagination';
import { useGeneralHooks } from 'Pages/general.hooks';
import { ResponsiveVactionCard } from 'Components';
import Button from 'Components/Button/button';
import NoData from 'Components/NoData/noData';
import styles from './responsivePage.module.scss';

const ResponsivePageVacation: React.FC<IApplicationComponentProps> = (
  props
) => {
  const { ordered_by,screenType, setScreenType } = props;
  const {
    currentRole,
    total,
    errors,
    isValid,
    control,
    setShowDataTable,
    handleSubmit,
    register,
    onSubmit,
    watch,
  } = useVacationHooks(setScreenType!);
  const { t, navigate } = useGeneralHooks();
  const { RESPONSIVE_ITEM_DATA, activePage, setActivePage, } = useHistory();

  return (
    <>
      {screenType === AppScreenType.HISTORY ? (
        RESPONSIVE_ITEM_DATA.vacation[`${ordered_by}`]?.result.length ? (
          <div className={styles.container}>
            <div className={styles.cardContent}>
              {RESPONSIVE_ITEM_DATA.vacation[`${ordered_by}`]?.result?.map(
                (card: any) => (
                  <ResponsiveVactionCard
                    type={CardTypes.WithEndDate}
                    title={`${card.total} ${card.total > 1
                        ? t('ResponsiveVacation.Days')
                        : t('ResponsiveVacation.Day')
                      }`}
                    key={card.id}
                    text={card.status.name}
                    bgColor={card.status.color}
                    startDate={card.startDate}
                    endDate={card.endDate}
                    name={`${card?.user?.name} ${card?.user?.surname}`}
                    onPress={() =>
                      navigate(
                        `/main/documents/applications/vacation/${card.id}`
                      )
                    }
                  />
                )
              )}
            </div>
            <div className={styles.buttonContent}>
              {RESPONSIVE_ITEM_DATA.vacation[`${ordered_by}`]?.count! > 10 && (
                <CustomPagination
                  count={Math.ceil(
                    +RESPONSIVE_ITEM_DATA.vacation[`${ordered_by}`]?.count! / 10
                  )}
                  page={activePage}
                  onChange={(_, page) => {
                    setActivePage(page);
                    window.scrollTo(0, 0);
                  }}
                />
              )}
              <Button
                buttonStyle={styles.button}
                onClick={() => {
                  setShowDataTable(false)
                  setScreenType && setScreenType(AppScreenType.FORM)
                }}
                type='submit'
                title={t('ResponsivePageAbsence.Button_Apply')}
                buttonType={ButtonTypes.ButtonWithIcon}
              />
            </div>
          </div>
        ) : (
          <NoData
            withButton={true}
            title={t('No_Data.Title')}
            description={t('No_Data.Description')}
            btnText={t('No_Data.Btn_Text')}
            btnOnclick={() => {
              setScreenType && setScreenType(AppScreenType.FORM)
            }}
          />
        )
      ) : (
        <div className={styles.form}>
          <VacationForm
            isValid={isValid}
            register={register}
            errors={errors}
            total={total}
            watch={watch}
            onSubmit={handleSubmit(onSubmit)}
            role={currentRole}
            control={control}
          />
        </div>
      )}
    </>
  );
};

export default ResponsivePageVacation;
