import React from 'react';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import MobileNavDrawer from 'Components/MobileNavDrawer/mobileNavDrawer';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import Header from 'Components/Header/header';
import useMobileNavDrawerHooks from 'Components/MobileNavDrawer/mobileNavDrawer-hooks';
import styles from './orders.module.scss';
import '../../App/App.scss';
import OrderHistory from 'Components/Orders/orderHistory';
import ResponsivePageOrders from 'Pages/ResponsivePage/respponsivePageOrders';
import { MenuSVG } from 'Assets';

const Orders: React.FC = () => {
  const { t, largeScreen } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();
  const { open, setOpen, onDrawerClose } = useMobileNavDrawerHooks();

  return (
    <>
      {largeScreen ? (
        <div className='page'>
          <div className={styles.container}>
            <div className={styles.searchContainer}>
              <BreadCrumbs data={pathsToRender} />
              <SearchInput />
            </div>
            <div className={styles.title}>{t('LayoutMenu.NavList.Orders')}</div>
            <OrderHistory />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.mobileBox}>
            <Header
              icon={MenuSVG}
              type={HeaderTypes.Navigation}
              navTitle={t('LayoutMenu.NavList.Orders')}
              iconOnClick={() => setOpen(true)}
            />
            <MobileNavDrawer open={open} onClose={onDrawerClose} />
            <div className={styles.mobileContent}>
              <ResponsivePageOrders />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
