import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  MessageList,
  MessageInput,
  Thread,
  Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import styles from './chat.module.scss';
import '../../App/App.scss';

const filters = { members: { $in: ['4'] } };
const options = { state: true, presence: true, limit: 10 };
const sort: any = { last_message_at: -1 };

const CustomChat: React.FC = () => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const newClient = new StreamChat('upshy6pu477x');

    const handleConnectionChange = ({ online = false }) => {
      if (!online) return console.log('connection lost');
      // @ts-ignore
      setClient(newClient);
    };

    newClient.on('connection.changed', handleConnectionChange);

    newClient.connectUser(
      {
        id: '4',
      },
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNCJ9.tI84IsNFoyo-GfH7xCUtTtkJnlPS55elBAd0eyiYo4o'
    );

    return () => {
      newClient.off('connection.changed', handleConnectionChange);
      //   newClient.disconnectUser().then(() => console.log('connection closed'));
    };
  }, []);

  if (!client) return null;

  return (
    <div className={styles['chat-page']}>
      <Chat client={client}>
        <div className={styles['chat-container']}>
          <ChannelList filters={filters} sort={sort} options={options} />
          <div className={styles['devider']}></div>
          <Channel>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </div>
      </Chat>
    </div>
  );
};

export default CustomChat;
