import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import AuthFormTitle from '../authFormTitle';
import Button from 'Components/Button/button';
import { usePassword } from 'Components/AuthComponents/AuthInputs/authInput-hooks';
import { InputField } from 'Components/AuthComponents/AuthInputs';
import { RegisterFormValues } from 'Pages/interfaces';
import { ButtonTypes } from 'Components/interfaces';
import BottomLink from 'Components/AuthComponents/BottomLink/bottomLink';
import TermsAndConditions from 'Components/AuthComponents/TermsAndConditions/termsAndConditions';
import eye from 'Assets/Icons/eye.svg';
import hidden from 'Assets/Icons/hidden.svg';
import authFormStyles from '../authForm.module.scss';
import signUpStyles from './signUpForm.module.scss';

interface ISignUpProps {
  onSubmit: () => void;
  register: UseFormRegister<FieldValues | RegisterFormValues>;
  errors: FieldErrors<FieldValues | RegisterFormValues>;
  isValid: boolean;
  isLoading: boolean;
  defaultEmai?: string
}

const SignUpForm: React.FC<ISignUpProps> = (props) => {
  const { t } = useTranslation();
  const { onSubmit, register, errors, isValid, isLoading, defaultEmai } = props;
  const { changeType, password } = usePassword();
  const screenWidth = window.innerWidth;
  const [agree, setAgree] = useState<boolean>(true);

  return (
    <div className={authFormStyles.container}>
      <AuthFormTitle
        title={t('Register.Title')}
        description={!(screenWidth <= 920) && t('Register.Typography')}
        linkTitle={t('Register.Link')}
        link='/'
      />
      <form
        className={`${authFormStyles.form} ${signUpStyles.form}`}
        onSubmit={onSubmit}
      >
        <InputField
          id='name'
          type='text'
          label={t('Register.First_name')}
          placeholder={
            screenWidth < 481
              ? t('Register_Mobile_Placeholders.name')
              : t('Register.Firstname_placeholder')
          }
          register={register}
          registerName='name'
          message={t('Input_Errors.FirstName')}
          patternValue={/^\s*[\p{L}-]{3,}\s*$/u}
          error={errors.name}
        />
        <InputField
          id='surname'
          type='text'
          label={t('Register.Surname')}
          placeholder={
            screenWidth < 481
              ? t('Register_Mobile_Placeholders.surname')
              : t('Register.Surname_placeholder')
          }
          register={register}
          registerName='surname'
          message={t('Input_Errors.Surname')}
          patternValue={/^\s*[\p{L}-]{3,}\s*$/u}
          error={errors.surname}
        />
        <InputField
          defaultValue={defaultEmai && defaultEmai}
          disabled={typeof defaultEmai === 'string'}
          id='email'
          type='text'
          label={t('Register.Email_label')}
          placeholder={
            screenWidth <= 480
              ? t('Register_Mobile_Placeholders.email')
              : t('Register.Email_placeholder')
          }
          register={register}
          registerName='email'
          message={t('Input_Errors.Email')}
          patternValue={/^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/gim}
          error={errors.email}
        />
        <InputField
          id='password'
          type={password ? 'password' : 'text'}
          label={t('Register.Password_label')}
          placeholder={
            screenWidth <= 480
              ? t('Register_Mobile_Placeholders.password')
              : t('Register.Password_placeholder')
          }
          register={register}
          registerName='password'
          message={t('Input_Errors.Password')}
          patternValue={
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#/+_,.^])[A-Za-z\d@$#!%*.,^/+_?&]{8,}$/gm
          }
          error={errors.password}
        >
          <span onClick={changeType}>
            <img
              className={authFormStyles.inputIcon}
              src={password ? hidden : eye}
              alt='show'
            />
          </span>
        </InputField>
        <Button
          disable={!isValid || isLoading || !agree}
          buttonStyle={signUpStyles.button}
          buttonType={
            isValid && agree ? ButtonTypes.Primery : ButtonTypes.Disabled
          }
          title={t('Register.Button_text')}
          isLoading={isLoading}
          type='submit'
        />
      </form>
      {screenWidth <= 920 && (
        <BottomLink
          link='/'
          linkTitle={t('Login_Form.Button_text')}
          text={t('Register.dontHaveAccount')}
        />
      )}
      {screenWidth <= 920 && (
        <TermsAndConditions cb={setAgree} checked={agree} />
      )}
    </div>
  );
};

export default SignUpForm;
