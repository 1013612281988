import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Cookies from 'js-cookie';
import { useAppDispatch } from "Store/store";


export const useGeneralHooks = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams()
  const accessToken = localStorage.getItem("access-token");
  const dispatch = useAppDispatch();
  
  const formatDate = (date: Date) => {
    return (
      moment(date).format("DD.MM.YYYY")
    )
  }
  const mobileScreen: boolean = window.innerWidth < 480;
  const largeScreen: boolean = window.innerWidth > 920;

  return {
    t,
    i18n,
    params,
    location,
    accessToken,
    largeScreen,
    mobileScreen,
    dispatch,
    navigate,
    formatDate
  };
};
