import TablePaySheet from 'Components/TablePaySheet/tablePaySheet'
import React from 'react'
import styles from './paySheet.module.scss'
const PaySheet: React.FC = () => {
  return (
    <div className={styles.container}>
      {/* <TablePaySheet /> */}

    </div>
  )
}

export default PaySheet