import { ClipLoader } from "react-spinners";
import MekumEmployeeItem from "./mekumEmployeeItem";
import UseMekumEmployeeHooks from "./mekumEmployee.hooks";
import {
  IMekumEmployeesResp,
  IMekumEmployeesRespResult,
} from "Services/responce_types";
import { useGeneralHooks } from "Pages/general.hooks";
import { keepMekumUser } from "Store/Slices/mekumSlice";
import CustomPagination from "Components/Pagination/pagination";
import styles from "./mekumEmployee.module.scss";

interface IProps {
  mekumEmployeeList: IMekumEmployeesResp | undefined;
  handleClose?: () => void
}

const MekumEmployeeList: React.FC<IProps> = (props) => {
  const { mekumEmployeeList, handleClose } = props;
  const { page, setPage, isLoading } = UseMekumEmployeeHooks();
  const { dispatch } = useGeneralHooks();

  if (isLoading) {
    return (
      <div className={styles["loading-container"]}>
        <ClipLoader color="#707EAE" size={40} />
      </div>
    );
  }

  return (
    <div className={styles["mekum-employee__list"]}>
      {mekumEmployeeList?.result?.map(
        (item: IMekumEmployeesRespResult) => {
          return (
            <MekumEmployeeItem
              name={item?.fullName}
              onClick={() => {
                dispatch(keepMekumUser(item));
                handleClose!()
              }}
              src={item?.path}
            />
          );
        }
      )}
      {mekumEmployeeList?.count! > 10 && (
        <CustomPagination
          count={Math.ceil(props?.mekumEmployeeList?.count! / 10)}
          page={page}
          onChange={(_, page) => {
            setPage(page);
            window.scrollTo(0, 0);
          }}
        />
      )}
    </div>
  );
};

export default MekumEmployeeList;
