import { t } from 'i18next';
import Loading from 'Components/Loading/loading';
import { IGroupItem } from 'Components/interfaces';
import CustomPagination from 'Components/Pagination/pagination';
import { useGroupHooks } from './useGroupHooks';
import { GroupItem } from './groupItem';
import styles from './groups.module.scss';
import NoData from 'Components/NoData/noData';

export const GroupList: React.FC = () => {
  const {
    page,
    setPage,
    groupList,
    isLoading,
    isError,
    handleOpen,
    handleClose,
    open,
  } = useGroupHooks();


  return (
    <>
      <div className={styles['group-list-cont']}>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <NoData
            withButton={false}
            title={t('Groups.No_Groups_Title')}
            description={t('Groups.No_Groups_Description')}
          />
        ) : groupList?.count === 0 ? (
          <NoData
            withButton={false}
            title={t('Groups.No_Groups_Title')}
            description={t('Groups.No_Groups_Description')}
          />
        ) : (
          <>
            {groupList?.result?.map((item: IGroupItem) => {              
              return (
                <GroupItem
                  key={item.id}
                  id={item.id}
                  groupName={item.name}
                  groupMemberCount={item.membersCount}
                  // groupTLeads={teamLeads}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  open={open}
                />
              );
            })}
          </>
        )}
      </div>
      {groupList?.count > 12 && (
        <CustomPagination
          count={Math.ceil(groupList?.data?.count / 12)}
          page={page}
          onChange={(_, page) => {
            setPage(page);
            window.scrollTo(0, 0);
          }}
        />
      )}
    </>
  );
};
