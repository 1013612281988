import React from "react";
import { t } from "i18next";
import { ButtonTypes, HeaderTypes } from "Components/interfaces";
import { SearchInput } from "Components/AuthComponents/AuthInputs";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { EmployeeItem,  Header } from "Components";
import Button from "Components/Button/button";
import employeeProfile from "../EmployeeProfile/employeeProfile.module.scss";
import employeeList from "Components/Employees/employeeComponet.module.scss"
import addMemberStyles from "../AddMemberToGroup/addMember.module.scss";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import useMarkSuperiorHooks from "./markSuperior.hooks";

const tmp = [
    {
        byWhom: "016",
        documentType: { id: 1, name: "ՀՀ անձնագիր" },
        documentTypeId: 1,
        dueDate: "2023-02-15T00:00:00.000Z",
        id: 1,
        passportNumber: "AH0585965",
        user: { id: 1, email: 'lenulstep@gmail.com', name: 'Lena', surname: 'Stepanyan', avatar: null, },
        userId: 1
    },
    {
        byWhom: "016",
        documentType: { id: 1, name: "ՀՀ անձնագիր" },
        documentTypeId: 1,
        dueDate: "2023-02-15T00:00:00.000Z",
        id: 1,
        passportNumber: "AH0585965",
        user: { id: 1, email: 'lenulstep@gmail.com', name: 'Lena', surname: 'Stepanyan', avatar: null, },
        userId: 2
    },
    {
        byWhom: "016",
        documentType: { id: 1, name: "ՀՀ անձնագիր" },
        documentTypeId: 1,
        dueDate: "2023-02-15T00:00:00.000Z",
        id: 1,
        passportNumber: "AH0585965",
        user: { id: 1, email: 'lenulstep@gmail.com', name: 'Lena', surname: 'Stepanyan', avatar: null, },
        userId: 3
    }
]
const MarkSuperior: React.FC = () => {
    const { pathsToRender } = useAppPathsHooks();
    const { markSuperiorReq, checkSuperior, largeScreen, navigate, superiorId } = useMarkSuperiorHooks()
    return (
        <div className={employeeProfile.container}>
            {largeScreen ? (
                <div className={employeeProfile.headerContainer}>
                    <div className={employeeProfile.breadCrumbBox}>
                        <BreadCrumbs data={pathsToRender} />
                        <SearchInput />
                    </div>
                    <div className={addMemberStyles.heading}>
                        <p className={addMemberStyles.title}>{t("Groups.Mark_Superior")}</p>
                        <Button
                            title={t("Groups.Choose_Button")}
                            buttonStyle={addMemberStyles.chooseBtn}
                            buttonType={ButtonTypes.Primery}
                            onClick={markSuperiorReq}
                        />
                    </div>
                </div>
            ) : (
                <div className={employeeProfile.mobileBox}>
                    <Header
                        type={HeaderTypes.Navigation}
                        navTitle={t("Groups.Mark_Superior")}
                        iconOnClick={() => navigate(-1)}
                    />
                </div>
            )}
            {/* <EmployeeList onClick={(id) => setSuperiorId(id!)} typeChecked={true} /> */}
            <div className={employeeList["employee-list__cont"]}>
                {tmp.map(
                    (item: any, i) => {
                        return (
                            <EmployeeItem
                                key={i} 
                                checkSuperior={superiorId === item.userId}
                                name={`${item.user.name} ${item.user.surname}`}
                                onClick={() => { checkSuperior(item.userId) }}
                            />
                        );
                    }
                )}
            </div>
            {!largeScreen ?
                <div className={addMemberStyles.buttonContent}>
                    <Button
                        title={t("Groups.Choose_Button")}
                        buttonStyle={addMemberStyles.chooseBtn}
                        buttonType={ButtonTypes.Primery}
                        onClick={markSuperiorReq} />
                </div> : null
            }
        </div>
    )
}
export default MarkSuperior
