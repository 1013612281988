import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import styles from "./pagination.module.scss";

interface IProps {
  count: number;
  page: number;
  onChange: (_: any, page: number) => void;
}

const CustomPagination: React.FC<IProps> = (props) => {
  const { count, page, onChange } = props;  

  return (
    <div className={styles["pagination-container"]}>
      <Stack spacing={2}>
        <Pagination
          count={count}
          page={page}
          showFirstButton
          showLastButton
          onChange={onChange}
        />
      </Stack>
    </div>
  );
};

export default CustomPagination;
