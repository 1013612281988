import { FieldValues } from 'react-hook-form';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IMekumToken } from 'Pages/interfaces';
import axiosBaseQuery from './API';
import {
  IConnectMekumResp, IMekumEmployeesData, IMekumEmployeesResp,
} from './responce_types';

export const mekumApi = createApi({
  reducerPath: 'mekumApi',
  tagTypes: ['mekum'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    connectMekum: builder.mutation<IConnectMekumResp,IMekumToken | FieldValues>({
      query: (data) => ({
        url: `/mekum/connect`,
        method: 'POST',
        data: data,
      }),
    }),
    getMekumEmployees: builder.query<IMekumEmployeesResp, IMekumEmployeesData>({
        query: (data) => ({
          url: `/mekum/employee/${data.offset}/${data.page}/search?searchTerm=${data.search}`,
          method: 'GET',
        }),
      }),
  }),
});

export const {
  useConnectMekumMutation,
  useGetMekumEmployeesQuery,
} = mekumApi;
