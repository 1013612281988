import moment from 'moment';
import CustomPagination from 'Components/Pagination/pagination';
import { useGeneralHooks } from 'Pages/general.hooks';
import UseMyInvitationsHooks from './myInvitations.hooks';
import { IMyInvitationsRespResult } from 'Services/responce_types';
import MyInvitationsItem from './myInvitationItem';
import Loading from 'Components/Loading/loading';
import NoData from 'Components/NoData/noData';
import styles from './myInvitations.module.scss';

const MyInvitationsList: React.FC = () => {
  const { page, setPage, data, isLoading } = UseMyInvitationsHooks();
  const {t} = useGeneralHooks();

  if(isLoading) {
    return <Loading />
  }

  if(data?.count === 0) {
    return <NoData withButton={false} title={t("My_Invitations.No_Data_Title")} description={t("My_Invitations.No_Data_Description")}/>
  }  

  return (
    <div className={styles['my-invitations__list']}>
        <>
          {data?.result?.map((item: IMyInvitationsRespResult) => {
            return (
              <MyInvitationsItem
                key={item?.id}
                invitationId={item?.id}
                src={item?.createdBy.avatar}
                name={item?.createdBy?.name}
                surname={item?.createdBy?.surname}
                time={moment(item?.createdAt).fromNow()}
                invitor={item?.company?.name}
                role={item?.role?.name}
                currentStatus={item?.status}
                id={item?.id}
              />
            );
          })}
          {data?.count! > 10 && (
            <CustomPagination
              count={Math.ceil(data?.count! / 10)}
              page={page}
              onChange={(_, page) => {
                setPage(page);
                window.scrollTo(0, 0);
              }}
            />
          )}
        </>
    </div>
  );
};

export default MyInvitationsList;
