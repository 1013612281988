import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import useForgetPassword from './forgetPassword-hook';
import {
  ForgetPasswordEmail,
  ForgetPasswordForm,
  Header,
  LeftSide,
  OtpInput,
  PasswordChanged,
  RightSide,
} from 'Components';
import { IForgetPassword } from 'Pages/interfaces';
import authFormstyles from 'Components/AuthComponents/AuthForms/authForm.module.scss';
import forgetPasswordStyles from './forgetPassword.module.scss';
import { HeaderTypes } from 'Components/interfaces';
import { Vector } from 'Assets';
import { t } from 'i18next';

const ForgetPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<IForgetPassword | FieldValues>({ mode: 'all' });
  const {
    activeComponent,
    otp,
    userEmail,
    sendCodeLoading,
    otpChange,
    sendCode,
    sendPassword,
    sendEmail,
  } = useForgetPassword();
  const screenWidth = window.innerWidth;

  return (
    <div className={forgetPasswordStyles.container}>
      {screenWidth > 480 ? (
        <LeftSide
          title={t('Leftside_Texts.Title')}
          describtion={t('Leftside_Texts.Description')}
        >
          <img src={Vector} alt='Vector' />
        </LeftSide>
      ) : (
        <Header type={HeaderTypes.Logo} />
      )}
      <RightSide>
        <div
          className={`${authFormstyles.container} ${forgetPasswordStyles.forgetPasswordContainer}`}
        >
          {activeComponent === 'enterEmail' && (
            <ForgetPasswordEmail
              onSubmit={handleSubmit(sendEmail)}
              register={register}
              isValid={isValid}
              errors={errors}
              isLoading={sendCodeLoading}
            />
          )}
          {activeComponent === 'enterCode' && (
            <OtpInput
              otp={otp}
              otpChange={otpChange}
              sendCode={sendCode}
              userEmail={userEmail}
            />
          )}
          {activeComponent === 'enterPassword' && (
            <ForgetPasswordForm
              onSubmit={handleSubmit(sendPassword)}
              watch={watch}
              register={register}
              isValid={isValid}
              errors={errors}
            />
          )}
          {activeComponent === 'passwordChanged' && <PasswordChanged />}
        </div>
      </RightSide>
    </div>
  );
};

export default ForgetPassword;
