import moment from "moment";
import { INotificationRespResult } from "Services/responce_types";
import CustomPagination from "Components/Pagination/pagination";
import UseNotificationHooks from "./notification.hooks";
import NotificationItem from "./notificationItem";
import styles from "./notification.module.scss";

const NotificationList: React.FC = () => {
  const { notifications, page, setPage, changeNotificationStatus } = UseNotificationHooks();

  return (
    <div className={styles["notification-list-container"]}>
      {notifications?.result?.map((item: INotificationRespResult) => {        
        return (
          <NotificationItem
            name={`${item.createdBy?.name!} ${item?.createdBy?.surname!}`}
            img={item?.createdBy?.avatar!}
            date={moment(item?.createdAt).fromNow()}
            changedStatus="Ավարտված"
            read={item?.seen}
            statusColor={"#57DBA6"}
            onClick={() => changeNotificationStatus({id: item?.id, seen: item?.seen ? 0 : 1})}
          />
        );
      })}
      {notifications?.count! > 10 && (
        <CustomPagination
          count={Math.ceil(notifications?.count! / 10)}
          page={page}
          onChange={(_, page) => {
            setPage(page);
            window.scrollTo(0, 0);
          }}
        />
      )} 
    </div>
  );
};

export default NotificationList;
