import { useState } from "react";
import { useGeneralHooks } from "Pages/general.hooks";
import am from "../../Assets/Icons/armenia.svg";
import ru from "../../Assets/Icons/russia.svg";
import us from "../../Assets/Icons/united states.svg";

const useLanguagesHooks = () => {
  const languages = [
    {
      id: 0,
      icon: am,
      code: "am",
    },
    {
      id: 1,
      icon: ru,
      code: "ru",
    },
    {
      id: 2,
      icon: us,
      code: "en",
    },
  ];

  const [show, setShow] = useState<boolean>(false);
  const [activeLangSrc, setActiveLangSrc] = useState<string>(
    localStorage.getItem('currentLang') === "en"
      ? us
      : localStorage.getItem('currentLang') === "ru"
      ? ru
      : am
  );
  const filteredLanguages = languages.filter(
    (item) => item.icon !== activeLangSrc
  );

  const { i18n } = useGeneralHooks();

  const changeActiveLanguage = (active: string) => {
    i18n.changeLanguage(active);
    localStorage.setItem('currentLang', active)
  };

  return {
    setShow,
    setActiveLangSrc,
    changeActiveLanguage,
    filteredLanguages,
    activeLangSrc,
    show,
  };
};

export default useLanguagesHooks;
