import ApplicationDetailHistoryList from 'Components/ApplicationDetailHistory/applicationDetailHistoryList';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import { ApplicationDetailInfo, CustomModal, Header, Loading } from 'Components';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import useReferenceDetailHooks from './referenceDetail-hooks';
import styles from './referenceDetail.module.scss';
import { ChangeStatusModal } from 'Components/Application/ApplicationItems';
import { IReferenceByIdResp } from 'Services/responce_types';

const ReferenceDetail = () => {
  const {
    renderItemData,
    referenceByIdInfo,
    isFetching,
    handleClose,
    onSubmit,
    control,
    openModal,
    errors,
    register,
    handleSubmit,
    statuses } = useReferenceDetailHooks();
  const { largeScreen, navigate, t } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();
  return (
    <>
      {!largeScreen && (
        <Header
          type={HeaderTypes.Navigation}
          navTitle={
            window.innerWidth > 480
              ? t('Aplication_Page.Reference.Description')
              : t('Aplication_Page.Reference.Nav_Title')
          }
          iconOnClick={() => navigate(-1)}
        />
      )}
      <div className='page'>
        {isFetching ? (
          <Loading />
        ) : (
          <>
            {largeScreen && (
              <div className={styles['breadCrumbBox']}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
            )}
            <div className={styles['content']}>
              <div className={styles['reference-detail-page__title']}>
                {largeScreen
                  ? t('Aplication_Page.Reference.Description')
                  : t('Aplication_Page.Vacation.Application_Result')}
              </div>
              <ApplicationDetailInfo data={renderItemData} />
              {!largeScreen && (
                <div className={styles['reference-detail-page__history-title']}>
                  {t('Aplication_Page.Application_History')}
                </div>
              )}
              <ApplicationDetailHistoryList data={referenceByIdInfo as IReferenceByIdResp} />
            </div>
            <div>
              <CustomModal
                onClick={handleSubmit(onSubmit)}
                buttonTitle={t('Aplication_Page.App_Status.Button')}
                children={<ChangeStatusModal control={control} errors={errors} register={register} statuses={statuses}/>}
                title={t('Aplication_Page.Vacation.Status')}
                handleClose={handleClose}
                open={openModal}
                dialogClassname={'changeStatusDialog'}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ReferenceDetail;
