import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';
import { ISocialSignInToken } from './responce_types';

export const socialLoginApi = createApi({
  reducerPath: 'socialLoginApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    googleSignIn: builder.mutation<any, ISocialSignInToken>({
      query: (data) => ({
        url: '/auth/login_google',
        method: 'POST',
        data: data,
      }),
    }),
    facebookSignIn: builder.mutation<any, ISocialSignInToken>({
      query: (data) => ({
        url: '/auth/login_facebook',
        method: 'POST',
        data: data,
      }),
    }),
  }),
});

export const { useGoogleSignInMutation, useFacebookSignInMutation } =
  socialLoginApi;
