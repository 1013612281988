import React from "react";
import { AppScreenType } from "Pages/interfaces";
import { IApplicationComponentProps } from "Components/Application/ApplicationItems/Vacation/vacationComponent";
import { useAbsenceHooks } from "Components/Application/ApplicationItems/Absence/absence-hooks";
import AbsenceForm from "Components/Application/ApplicationItems/Absence/absenceForm";
import useHistory from "Components/Application/AppHistory/history-hooks";
import { ButtonTypes, CardTypes } from "../../Components/interfaces";
import CustomPagination from "Components/Pagination/pagination";
import { useGeneralHooks } from "Pages/general.hooks";
import { ResponsiveVactionCard } from "Components";
import Button from "Components/Button/button";
import NoData from "Components/NoData/noData";
import styles from "./responsivePage.module.scss";

const ResponsivePageAbsence: React.FC<IApplicationComponentProps> = (props) => {
  const { ordered_by, screenType, setScreenType } = props;
  const { t, navigate } = useGeneralHooks();
  const {
    employeeList,
    isSuccess,
    isLoading,
    isValid,
    control,
    errors,
    total,
    currentRole,
    watch,
    register,
    onSubmit,
    handleSubmit,
  } = useAbsenceHooks();
  const { RESPONSIVE_ITEM_DATA, setActivePage, activePage } = useHistory();

  return (
    <>
      {screenType === AppScreenType.HISTORY ? (
        RESPONSIVE_ITEM_DATA.absence[`${ordered_by}`]?.result.length ? (
          <div className={styles.container}>
            <div className={styles.cardContent}>
              {RESPONSIVE_ITEM_DATA.absence[`${ordered_by}`]?.result?.map(
                (card: any) => (
                  <ResponsiveVactionCard
                    type={CardTypes.WithEndDate}
                    title={`${card.total} ${card.total > 1
                        ? t("ResponsiveVacation.Days")
                        : t("ResponsiveVacation.Day")
                      }`}
                    key={card.id}
                    text={card.status.name}
                    bgColor={card.status.color}
                    startDate={card.startDate}
                    endDate={card.endDate}
                    onPress={() =>
                      navigate(
                        `/main/documents/applications/absence/${card.id}`
                      )
                    }
                  />
                )
              )}
            </div>
            <div className={styles.buttonContent}>
              {RESPONSIVE_ITEM_DATA.absence[`${ordered_by}`]?.count! > 10 && (
                <CustomPagination
                  count={Math.ceil(
                    +RESPONSIVE_ITEM_DATA.absence[`${ordered_by}`]?.count! / 10
                  )}
                  page={activePage}
                  onChange={(_, page) => {
                    setActivePage(page);
                    window.scrollTo(0, 0);
                  }}
                />
              )}
              <Button
                buttonStyle={styles.button}
                onClick={() => {
                  setScreenType && setScreenType(AppScreenType.FORM)
                }}
                type="submit"
                title={t("ResponsivePageAbsence.Button_Apply")}
                buttonType={ButtonTypes.ButtonWithIcon}
              />
            </div>
          </div>
        ) : (
          <NoData
            withButton={true}
            title={t("No_Data.Title")}
            description={t("No_Data.Description")}
            btnText={t("No_Data.Btn_Text")}
            btnOnclick={() => {
              setScreenType && setScreenType(AppScreenType.FORM)
            }}
          />
        )
      ) : (
        <div className={styles.form}>
          <AbsenceForm
            isValid={isValid}
            register={register}
            errors={errors}
            watch={watch}
            total={total}
            onSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            isSuccess={isSuccess}
            role={currentRole}
            control={control}
            employeeList={employeeList}
          />
        </div>
      )}
    </>
  );
};

export default ResponsivePageAbsence;
