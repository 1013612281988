import { t } from "i18next";
import { useEffect, useState } from "react";
import { useGeneralHooks } from "Pages/general.hooks";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  useAddMemberToGroupMutation,
  useGetMembersToJoinGroupQuery,
} from "Services/groupsApi";

const useAddMemberToGroupHooks = () => {
  const { id } = useParams();
  const [addMembersQuery, { data, isSuccess }] = useAddMemberToGroupMutation();
  const [page, setPage] = useState<number>(1);
  const { data: membersToJoinTheGroup } = useGetMembersToJoinGroupQuery({
    id: +id!,
    page: page,
    limit: 10,
  });
  const { largeScreen, navigate } = useGeneralHooks();
  const [choosedMembers, setChoosedMembers] = useState<number[]>([]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("Groups.Added_Member_Successfully"));
      navigate(-1);
    }
  }, [isSuccess]);

  const addMembersReq = () => {
    if (choosedMembers.length < 1) {
      toast.warning(t("Groups.Warning_Message"));
      return;
    }
    const data = {
      group_id: +id!,
      users: choosedMembers,
    };

    addMembersQuery(data);
  };

  const checkMembers = (id: number) => {
    const tmp = [...choosedMembers];
    if (choosedMembers.includes(id)) {
      const indexOfId = choosedMembers.indexOf(id);
      tmp.splice(indexOfId, 1);
    } else {
      tmp.push(id);
    }
    setChoosedMembers(tmp);
  };

  return {
    checkMembers,
    addMembersReq,
    largeScreen,
    navigate,
    membersToJoinTheGroup,
    page,
    setPage
  };
};

export default useAddMemberToGroupHooks;
