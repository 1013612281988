import { useGeneralHooks } from "Pages/general.hooks";
import { DownloadSVG } from "Assets";
import styles from "./responsiveOrder.module.scss";

interface IRespondsiveOrderItem {
    downloadFunc?: any;
    documentNumber: string,
    orderTypes: string,
    documentDate: Date,
    id?: number
}

const ResponsiveOrderItem: React.FC<IRespondsiveOrderItem> = (props) => {
    const { documentNumber, documentDate, orderTypes, downloadFunc } = props;
    const { t, formatDate } = useGeneralHooks();

    return (
        <>
            <div className={styles.conteiner}>
                <div className={styles.conteinerItem}>
                    <div className={styles.number}>
                        <span className={styles.textStyle}>
                            {`№ ${documentNumber}`}
                        </span>
                    </div>
                    <div className={styles.date}>
                        <span className={styles.textStyle}>
                            {formatDate(documentDate)}
                        </span>
                    </div>
                    <div className={styles.iconDiv} onClick={downloadFunc}>
                        <img src={DownloadSVG} alt="download"/>
                    </div>
                </div>
                <div className={styles.titleDiv}><span className={styles.title}>{t("Order.Type")}</span></div>
                <div><span className={styles.textStyle}>
                    {orderTypes}
                </span></div>
            </div>
        </>
    )
}

export default ResponsiveOrderItem;