import React, { useEffect, useState } from 'react';
import { useUploadFileMutation } from 'Services/applicationsApi';
import { Frame } from 'Assets';
import Camera from 'Assets/Images/Camera.png';
import styles from './mediaPicker.module.scss';

export interface IMediaPickerProps {
  id: string;
  onChange: (data: Object) => void;
}

const MediaPicker: React.FC<IMediaPickerProps> = (props) => {
  const { id, onChange } = props;
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );
  const [uploadFile, { data, isSuccess }] = useUploadFileMutation();

  useEffect(() => {
    isSuccess && onChange(data as Object);
  }, [data, isSuccess, onChange]);

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append('image', file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
        await uploadFile(formData);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className={styles.container}>
        <input
          id={id}
          type='file'
          accept='image/*'
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
        />
        <div className={styles.imageBox}>
          <label htmlFor={id}>
            <div
              className={styles.image}
              style={{
                backgroundImage: selectedImage
                  ? `url("${selectedImage}")`
                  : `url("${Frame}")`,
              }}
            />
            <div className={styles.iconDiv}>
              <div className={styles.cameradiv}>
                <img src={Camera} alt='Camera' />
              </div>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default MediaPicker;
