import React from 'react';
import { t } from 'i18next';
import NoData from 'Components/NoData/noData';
import { EmployeeItem, Loading } from 'Components';
import { IAllUsersRespResult } from 'Services/responce_types';
import CustomPagination from 'Components/Pagination/pagination';
import styles from './employeeComponet.module.scss';
import UseEmployeesHooks from './employees.hooks';

interface Iprops {
  onClick: (id?: number) => void;
  typeChecked?: boolean;
}

const EmployeeList: React.FC<Iprops> = (props) => {
  const { isLoading, allUsersList, page, setPage } = UseEmployeesHooks();
  
  return (
    <div className={styles.employeecontainer}>
      {isLoading ? (
        <Loading />
      ) : allUsersList?.count === 0 ? (
        <NoData
          withButton={false}
          description={t('Employees.No_Employees_Description')}
          title={t('Employees.No_Employees_Title')}
        />
      ) : (
        <div>
          <div className={styles['employee-list__cont']}>
            {allUsersList?.result?.map((item: IAllUsersRespResult) => {              
              return (
                <EmployeeItem
                  role={item.role.name}
                  src={item.user.avatar}
                  typeChecked={props.typeChecked}
                  key={item.id}
                  name={`${item?.user?.name} ${item?.user?.surname}`}
                  onClick={() => {
                    props.onClick(item?.userId);
                  }}
                />
              );
            })}
          </div>
          {+allUsersList?.count! > 12 && (
            <CustomPagination
              count={Math.ceil(+allUsersList?.count! / 12)}
              page={page}
              onChange={(_, page) => {
                setPage(page);
                window.scrollTo(0, 0);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EmployeeList;
