import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store } from 'Store/store';
import './Local/i18next/i18n';
import App from './App/App';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID || ''}>
        <App />
      </GoogleOAuthProvider>
      <ToastContainer />
    </Provider>
  </BrowserRouter>
);
