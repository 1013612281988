import React from 'react'
import { Link } from 'react-router-dom'
import styles from './bottomLink.module.scss'

interface IBottomLink {
  text?: string,
  linkTitle?: string,
  link?: string
}

const BottomLink: React.FC<IBottomLink> = (props) => {
  const { text, link, linkTitle } = props
  return (
    <div className={styles.container}>
      <p> {text} </p>
      {
        link && <Link to={link}>{linkTitle}</Link>
      }
    </div>
  )
}
export default BottomLink