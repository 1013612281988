import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./API";
import { IChangeNotificationData, INotificationResp, IPaginationQuery } from "./responce_types";

export const notificationsApi = createApi({
  reducerPath: "notifications",
  tagTypes: ['Notifications'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getNotifications: builder.query<INotificationResp, IPaginationQuery>({
        query: ({ page, offset }) => ({
          url: `/notification/${offset}/${page}`,
          method: 'GET',
        }),
        providesTags: ['Notifications']
      }),
      changeNotificationStatus: builder.mutation<number, IChangeNotificationData>({
        query: (data) => ({
          url: `/notification/seen/${data.id}`,
          method: 'PUT',
          data: {
            seen: data.seen
          }
        }),
        invalidatesTags: ['Notifications']
      }),
  }),
});

export const {
  useGetNotificationsQuery,
  useChangeNotificationStatusMutation
} = notificationsApi;
