import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { t } from 'i18next';
import {
  useChangePensionStatusMutation,
  useGetPensionAvailableStatusesQuery,
  useGetPensionByIdQuery,
} from 'Services/Applications/pensionApi';
import { useApplicationItem } from 'Components/Application/ApplicationItems/applicationItem.hooks';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import {
  IAvailableStatusesRespResult,
  IChangeStatusData,
} from 'Services/responce_types';
import { CurrentStatus } from 'Components/Application/ApplicationItems';
import { IVacationStatusChangeValues } from 'Pages/interfaces';
import fileUploadIcon from '../../Assets/Icons/file-icon.svg';
import { useGetMeQuery } from 'Services/authApi';
import useRoleHooks from 'Routers/role-hooks';
import styles from './pensionDetail.module.scss';

const usePensionDetailHooks = () => {
  const { id } = useParams();
  const { data: pensionByIdInfo, isFetching } = useGetPensionByIdQuery(+id!);
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const [changePensionStatus, { isSuccess }] = useChangePensionStatusMutation();
  const { data: pensionAvailableStatuses } =
    useGetPensionAvailableStatusesQuery(+pensionByIdInfo?.result?.status?.id!);
  const { currentRole } = useRoleHooks();
  const { formatDate } = useApplicationItem();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IVacationStatusChangeValues | FieldValues>({ mode: 'all' });

  const onSubmit: SubmitHandler<IVacationStatusChangeValues | FieldValues> = (
    data
  ) => {
    const payload: IChangeStatusData = {
      id: +id!,
      description: data.description,
      status_to_id: data.status_id.id,
      status_from_id: pensionByIdInfo?.result?.status?.id!,
      userId: pensionByIdInfo?.result?.user?.id,
    };
    changePensionStatus(payload);
  };
  const statuses = pensionAvailableStatuses?.result?.map(
    (item: IAvailableStatusesRespResult) => ({
      name: t(`Statuses_From_Server.${item.statusTo.name}`),
      id: item.statusTo.id,
    })
  );
  useEffect(() => {
    if (isSuccess) {
      toast.success(
        t('Aplication_Page.App_Status.Status_Successfully_Changed')
      );
      reset();
      handleClose();
      // navigate('/main/documents/applications/vacation');
    }
  }, [isSuccess]);

  const FilesComponent = useMemo(() => {
    return (
      <div className={styles['files-container']}>
        {pensionByIdInfo?.result?.files &&
          pensionByIdInfo?.result?.files?.map((file: string) => {
            return (
              <>
                <div className={styles['files-item']}>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`${process.env.REACT_APP_API_KEY}${file}`}
                  >
                    <img src={fileUploadIcon} alt='fileUploadIcon' />
                    <span className={styles['filename']}>
                      {file?.split('-')[1]?.substring(0, 4) + '... , '}
                    </span>
                  </a>
                </div>
              </>
            );
          })}
      </div>
    );
  }, [pensionByIdInfo?.result?.files]);

  const renderItemData = [
    {
      label: t('Register.First_name'),
      value: pensionByIdInfo?.result?.user?.name,
    },
    {
      label: t('Register.Surname'),
      value: pensionByIdInfo?.result?.user?.surname,
    },
    {
      label: t('Aplication_Page.Vacation.Start'),
      value: formatDate(pensionByIdInfo?.result?.startDate!),
    },
    {
      label: t('Aplication_Page.Vacation.End'),
      value: formatDate(pensionByIdInfo?.result?.endDate!),
    },
    {
      label: t('Aplication_Page.Vacation.Status'),
      value: (
        <CurrentStatus
          onClick={currentRole !== 'EMP' ? handleOpen : () => {}}
          statusName={t(
            `Statuses_From_Server.${pensionByIdInfo?.result?.status?.name}`
          )}
          bgColor={pensionByIdInfo?.result?.status?.color!}
        />
      ),
    },
    {
      label: t('Aplication_Page.Pension.Attached'),
      value: FilesComponent,
    },
  ];

  return {
    pensionAvailableStatuses,
    pensionByIdInfo,
    renderItemData,
    isFetching,
    openModal,
    statuses,
    control,
    errors,
    id,
    register,
    onSubmit,
    handleClose,
    handleSubmit,
  };
};

export default usePensionDetailHooks;
