import React from "react";
import { FieldValues, UseFormRegister, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthFormTitle from "./authFormTitle";
import Button from "Components/Button/button";
import { usePassword } from "../AuthInputs/authInput-hooks";
import { InputField } from "../AuthInputs";
import { SocialLogin } from "Components";
import { LoginFormValues } from "Pages/interfaces";
import { ButtonTypes } from "Components/interfaces";
import BottomLink from "../BottomLink/bottomLink";
import eye from "Assets/Icons/eye.svg";
import hidden from "Assets/Icons/hidden.svg";
import styles from "./authForm.module.scss";

export interface ILoginFormProps {
  onSubmit: () => void;
  register: UseFormRegister<FieldValues | LoginFormValues>;
  errors: FieldErrors<FieldValues | LoginFormValues>;
  defaultEmail?: string,
  isValid?: boolean;
  isLoading?: boolean;
}

const LoginForm: React.FC<ILoginFormProps> = (props) => {
  const { onSubmit, register, errors, defaultEmail } = props;
  const { password, changeType } = usePassword();
  const { t } = useTranslation();
  const screenWidth = window.innerWidth;

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <AuthFormTitle
          title={t("Login_Form.Title")}
          description={!(screenWidth <= 920) && t("Login_Form.Typography")}
          linkTitle={t("Login_Form.Register")}
          link="/registration"
        />
        <form onSubmit={onSubmit} className={styles.form}>
          <InputField
            defaultValue={defaultEmail && defaultEmail}
            disabled={typeof defaultEmail === 'string'}
            type="text"
            id="login"
            placeholder={t("Login_Form.Login_placeholder")}
            label={t("Login_Form.Login_label")}
            register={register}
            registerName="email"
            message={t("Login_Form.Login_Error")}
            patternValue={/^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i}
            error={errors.email}
          />
          <InputField
            type={password ? "password" : "text"}
            error={errors.password}
            id="password"
            placeholder={t("Login_Form.Password_placeholder")}
            label={t("Login_Form.Password_label")}
            register={register}
            registerName="password"
            message={t("Login_Form.Passwor_Error")}
            patternValue={/(?=.{8,})/gim}
          >
            <img
              className={styles.inputIcon}
              src={password ? hidden : eye}
              alt="show"
              onClick={changeType}
            />
          </InputField>
          <div className={styles.forgotPassword}>
            <Link to="/forgetPassword">{t("Login_Form.Forgot")}</Link>
          </div>
          <Button
            buttonType={ButtonTypes.Primery}
            buttonStyle={styles.loginButton}
            type="submit"
            title={t("Login_Form.Button_text")}
            isLoading={props.isLoading}
          />
          <SocialLogin />
          {screenWidth <= 920 && (
            <div className={styles.registerContainer}>
              <BottomLink
                link="/registration"
                linkTitle={t("Register.Title")}
                text={t("Register.dontHaveAccount")}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
