import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';
import {
  IDocumentTypesResp,
  IPaginationQuery,
  IMyInvitationsResp,
  acceptOrRejectInvitationData,
  IGetOrdersResponce,
  IGetOrdersQuery,
  IStatusListResp,
} from './responce_types';

export const generalApi = createApi({
  reducerPath: 'generalApi',
  tagTypes: ['Invitations'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getDocumentTypes: builder.query<IDocumentTypesResp, void>({
      query: () => ({
        url: `/document-type/50/0`,
        method: 'GET',
      }),
    }),
    getMyInvitations: builder.query<IMyInvitationsResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/users/my_invitations/${offset}/${page}`,
        method: 'GET',
      }),
    }),
    acceptOrRejectInvitation: builder.mutation<number,acceptOrRejectInvitationData>({
      query: (data: any) => ({
        url: `/users/invitation/${data.invitationId}`,
        method: 'PUT',
        data: {
          status: data.status,
        },
        invalidatesTags: ['Invitations'],
      }),
    }),
    upDateNotification: builder.mutation<any, number | string>({
      query: (id) => ({
        url: `/notification/${id}`,
        method: "PUT",
      }),
    }), 
    getMyOrders: builder.query<IGetOrdersResponce, IGetOrdersQuery>({
      query: ({ page, offset,mekumId }) => ({
        url: `/mekum/employee_orders/${offset}/${page}/${mekumId}`,
        method: 'GET',
      }),
    }),
    getAppStatuses: builder.query<IStatusListResp, void>({
      query: () => ({
        url: `/status/select`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDocumentTypesQuery,
  useGetMyInvitationsQuery,
  useUpDateNotificationMutation,
  useAcceptOrRejectInvitationMutation,
  useGetMyOrdersQuery,
  useGetAppStatusesQuery
} = generalApi;
