import { Dispatch, SetStateAction } from 'react';
import { useGeneralHooks } from 'Pages/general.hooks';
import profileAvatarImg from '../../Assets/Images/profilrAvatarImg.svg';
import styles from './profileBriefInfo.module.scss';

interface IProps {
  avatar?: string | null;
  name: string;
  role?: string;
  email?: string
  setExpanded:  Dispatch<SetStateAction<string | false>>;
}

export const ProfileBriefInfo: React.FC<IProps> = (props) => {
  const { avatar, name, role, email, setExpanded } = props;
  const {navigate} =  useGeneralHooks();
  return (
    <>
    <div className={styles.briefInfoCont} onClick={()=>{
      navigate("/main/userProfile"); 
      setExpanded(false)}}>
      <img
        src={
          avatar
            ? `${process.env.REACT_APP_API_KEY}${avatar}`
            : profileAvatarImg
        }
        className={styles.profileImg}
        alt='profileImage'
      />
      <div className={styles.personalInfoCont}>
        <p className={styles.name}>{name}</p>
        {email && <p className={styles.email}>{email}</p>}
        {role && <p className={styles.role}>{role}</p>}
      </div>
    </div>
    </>
  );
};
