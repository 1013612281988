import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { RegisterFormValues } from 'Pages/interfaces';
import { Header, LeftSide, RightSide, SignUpForm } from 'Components';
import { useRegisterQueryMutation } from 'Services/authApi';
import { HeaderTypes } from 'Components/interfaces';
import { Vector } from 'Assets';
import styles from '../LogIn/login.module.scss';
import { t } from 'i18next';

const SignUpPage: React.FC = () => {
  const [searchParams, _] = useSearchParams();
  const email = searchParams.get('email');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<RegisterFormValues | FieldValues>({ mode: 'all' });
  const [registerQuery, { data, isSuccess, isLoading }] =
    useRegisterQueryMutation();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<RegisterFormValues | FieldValues> = (
    regiterData: RegisterFormValues | FieldValues
  ) => {
    const payload = {
      name: regiterData.name.trim(),
      surname: regiterData.surname.trim(),
      email: regiterData.email.trim(),
      password: regiterData.password,
    };
    registerQuery(payload);
  };

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.result) {
        localStorage.setItem('access-token', data.result);
      }
      email ? navigate('/main/userProfile') : navigate('/createCompany');
    }
  }, [isSuccess, data?.result, navigate]);

  return (
    <div className={styles.container}>
      {window.innerWidth > 920 ? (
        <LeftSide
          title={t('Leftside_Texts.Title')}
          describtion={t('Leftside_Texts.Description')}
        >
          <img src={Vector} alt='vector' />
        </LeftSide>
      ) : (
        <Header type={HeaderTypes.Logo} />
      )}
      <RightSide>
        <SignUpForm
          isValid={isValid}
          register={register}
          errors={errors}
          isLoading={isLoading}
          onSubmit={handleSubmit(onSubmit)}
          defaultEmai={email!}
        />
      </RightSide>
    </div>
  );
};

export default SignUpPage;
