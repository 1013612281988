import { Link } from "react-router-dom";
import { t } from "i18next";
import personIcon from "../../Assets/Images/person-icon.svg";
import styles from "./groups.module.scss";
import { CustomModal } from "Components";

interface IProps {
  groupName: string;
  groupMemberCount: number;
  // groupTLeads: any;
  id: number;
  handleOpen: (e: Event) => void;
  handleClose: () => void;
  open: boolean
}

export const GroupItem: React.FC<IProps> = (props) => {
  const { groupName, groupMemberCount, id, handleClose, handleOpen, open } = props;
  return (
    <>
      <Link className={styles["group-item"]} to={`/main/groups/${id}`}>
        <div className={styles["group-item__circle-cont"]}>
          <div className={styles["group-item__circle"]}></div>
        </div>
        <div className={styles["first-row"]}>
          <div className={styles["group-name"]}>{groupName}</div>
          <div className={styles["members-count-cont"]}>
            <img src={personIcon} className={styles["person-icon"]} />
            <span className={styles["members-count"]}>
              {groupMemberCount ? groupMemberCount : 0}
            </span>
          </div>
        </div>
        {/* <div className={styles["second-row"]}>
          <div className={styles["label"]}>{t("Groups.teamLead")}</div>
          <div className={styles["name"]}>
            {groupTLeads && groupTLeads[0].name},
            <span onClick={(e: any) => { handleOpen(e) }}>{` +${groupTLeads.length - 1}`}</span>
          </div>
        </div> */}
      </Link>
      {/* <CustomModal
        title={t('Groups.teamLeadListTitle')}
        handleClose={handleClose}
        open={open}
        // teamLeadList={groupTLeads}
        onCLick={()=>{console.log('hhh')}}
        typography="Միանալով Մեկումին դուք հնարավորություն ունեք ավտոմատացնել ձեր կազմակերրպության հաշվապահական գործընթացը" 
        /> */}
    </>

  );
};
