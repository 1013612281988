import React from 'react';
import { Control, Controller, FieldValues, UseFormRegister} from 'react-hook-form';
import { useGeneralHooks } from 'Pages/general.hooks';
import { IResponseDataItem } from 'Services/responce_types';
import { ButtonTypes, EmployeeHistoryFilterFormValues } from 'Components/interfaces';
import { AutoComplete, InputField } from 'Components/AuthComponents/AuthInputs';
import Button from 'Components/Button/button';
import styles from './filterForm.module.scss';

interface IProps {
  register: UseFormRegister<FieldValues>;
  control?: Control<FieldValues | EmployeeHistoryFilterFormValues, any>;
  onSubmit: () => void;
  isFilter: boolean;
  appTypeOptions: IResponseDataItem[];
  statuseOptions: IResponseDataItem[]
};

const EmployeeHistoryFilterForm: React.FC<IProps> = (props) => {
  const { t } = useGeneralHooks();
  const { onSubmit, register, control, appTypeOptions, statuseOptions, isFilter } = props;
  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.inputFields}>
          <div className={styles.datePicker}>
            <InputField
              type="date"
              id="from"
              label={t("Aplication_Page.Vacation.Start")}
              register={register}
              registerName="from"
              required={false}
            />
            <span> - </span>
            <InputField
              type="date"
              id="to"
              label={t("Aplication_Page.Vacation.End")}
              register={register}
              registerName="to"
              required={false}
            />
          </div>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, name, value } }) => {
              return (
                <AutoComplete
                  value={!isFilter ? null : value}
                  name={name}
                  onChange={onChange}
                  id="type"
                  data={appTypeOptions}
                  label={t('Employee_History.App_Type')}
                  placeholder={t("Groups.Choose_Button")}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, name, value } }) => {
              return (
                <AutoComplete
                  value={!isFilter ? null : value}
                  name={name}
                  onChange={onChange}
                  id="status"
                  data={statuseOptions}
                  label={t('Employee_History.Status')}
                  placeholder={t("Groups.Choose_Button")}
                />
              );
            }}
          />
        </div>
        <div className={styles.button}>
          <Button
            title={t("Employee_History.Filter")}
            type="submit"
            buttonType={ButtonTypes.Primery}
            buttonStyle={styles.btnStyle}
          />
        </div>
      </form>
    </div>
  )
}

export default EmployeeHistoryFilterForm
