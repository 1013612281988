import { useState } from "react";
import { useGeneralHooks } from "Pages/general.hooks";
import { useGetMyOrdersQuery } from "Services/generalApi";
import useRoleHooks from "Routers/role-hooks";

const useOrderHistoryHooks = () => {
    const { mekumId } = useRoleHooks();
    const [activePage, setActivePage] = useState<number>(1);
    const { data: OrderList, isLoading } = useGetMyOrdersQuery({
        page: activePage,
        offset: 10,
        mekumId: mekumId!
    });
    const { t } = useGeneralHooks();
    const tabHeaders = [t('Order.Date'), t('Order.Document'), t('Order.Type'), ""]
    

    return {
        activePage,
        setActivePage,
        OrderList,
        isLoading,
        tabHeaders
    }
};

export default useOrderHistoryHooks