import React from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AuthError from 'Components/AuthComponents/AuthError/authError';
import { IEmployeeUser, IResponseDataItem } from 'Services/responce_types';
import styles from "../authInputs.module.scss";

interface IAutoCompleteProps {
  data: IResponseDataItem[] | IEmployeeUser[] | undefined,
  label: string,
  disable?: boolean,
  id: string,
  value?: IResponseDataItem | null,
  name: string,
  onChange: (arg: IResponseDataItem | null) => void,
  placeholder: string,
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
}

const AutoComplete: React.FC<IAutoCompleteProps> = (props) => {
  const { data, label, error, onChange, id, placeholder, disable, value } = props;
  const options = (data ?? []).map((item) => item);

  return (
    <div className={styles.container} >
      <div className='autoCompleteContainer'  >
        <label htmlFor={id} className={styles.label}>
          {label}
          {
            error && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
          }
        </label>
        <div>
          <Autocomplete
            value={!value ? null : value}
            disabled={disable}
            placeholder={placeholder}
            disablePortal
            id={id}
            fullWidth={true}
            options={options}
            getOptionLabel={options => options.name}
            onChange={(_, selected) => {
              onChange(selected)
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            renderInput={(params: Object,) => {
              return (
                <TextField
                  label={placeholder}
                  {...params}
                />
              )
            }}
          />
        </div>
        {<AuthError text={error && error.message} />}
      </div>
    </div>
  );
};

export default AutoComplete;