import React from 'react';
import {  Drawer } from '@mui/material';
import { Layout } from 'Components/Layout/layout';

export interface IMobileNavDrawerProps {
    open:boolean,
    onClose:()=>void
}
const MobileNavDrawer:React.FC<IMobileNavDrawerProps> = (props) => {
    const {open,onClose} = props;
  return (
       <React.Fragment >
          <Drawer
            open={open}
            onClose={onClose}
          >
          <Layout
          onDrawerClose={onClose}
          />
          </Drawer>
        </React.Fragment>
  )
}

export default MobileNavDrawer
