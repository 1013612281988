import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";
import {
  useChangeNotificationStatusMutation,
  useGetNotificationsQuery,
} from "Services/notificationsApi";

const UseNotificationHooks = () => {
  const [page, setPage] = useState<number>(1);
  const { data: notifications } = useGetNotificationsQuery({
    page,
    offset: 10,
  });
  const [changeNotificationStatus, { isSuccess }] = useChangeNotificationStatusMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("Notifications.Notification_Status_Changed"))
    }
  }, [isSuccess]);

  return { notifications, page, setPage, changeNotificationStatus };
};

export default UseNotificationHooks;
