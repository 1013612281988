import React from "react";
import { Controller } from "react-hook-form";
import { useGeneralHooks } from "Pages/general.hooks";
import { ICreateCompanyProps } from "../../createCompanyForm";
import useCreateCompanyHooks from "Pages/CreateCompany/createCompany.hooks";
import { AutoComplete } from "Components/AuthComponents/AuthInputs";

const SecondForm: React.FC<ICreateCompanyProps> = (props) => {
    const { control, watch, errors } = props;
    const { t } = useGeneralHooks();
    const { TaxTypes, OrgTypes, Cities, RoleTypes } = useCreateCompanyHooks();
    return (
        <>
            <Controller
                control={control}
                name="typeOfTaxId"
                rules={{
                    validate: () => watch("typeOfTaxId") !== undefined || t('Input_Errors.Required'),
                }}
                render={({ field: { onChange, name }, }) => {
                    return (
                        <AutoComplete
                            name={name}
                            onChange={onChange}
                            id="taxSystem"
                            data={TaxTypes?.data}
                            label={t('CreateCompany.Tax_label')}
                            placeholder={t('CreateCompany.TaxSystem')}
                            error={errors.typeOfTaxId}
                        />
                    )
                }}
            />
            <Controller
                control={control}
                name="oltId"
                rules={{
                    validate: () => watch("oltId") !== undefined || t('Input_Errors.Required'),
                }}
                render={({ field: { onChange, name }, }) => {
                    return (
                        <AutoComplete
                            name={name}
                            onChange={onChange}
                            id="oltId"
                            data={OrgTypes?.data}
                            label={t('CreateCompany.OrgTypes_label')}
                            placeholder={t('CreateCompany.OrganizationType')}
                            error={errors.oltId}
                        />
                    )
                }}
            />
            <Controller
                control={control}
                name="cityId"
                rules={{
                    validate: () => watch("cityId") !== undefined || t('Input_Errors.Required'),
                }}
                render={({ field: { onChange, name }, }) => {
                    return (
                        <AutoComplete
                            name={name}
                            onChange={onChange}
                            id="cityId"
                            data={Cities?.data}
                            label={t('CreateCompany.City_label')}
                            placeholder={t('CreateCompany.City')}
                            error={errors.cityId}
                        />
                    )
                }}
            />
            <Controller
                control={control}
                name="roleId"
                rules={{
                    validate: () => watch("roleId") !== undefined || t('Input_Errors.Required'),
                }}
                render={({ field: { onChange, name }, }) => {
                    return (
                        <AutoComplete
                            name={name}
                            onChange={onChange}
                            id="position"
                            data={RoleTypes?.data}
                            label={t('CreateCompany.Role_label')}
                            placeholder={t('CreateCompany.Role')}
                            error={errors.roleId}
                        />
                    )
                }}
            />
        </>
    )
}

export default SecondForm
