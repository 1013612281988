import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { IReferenceProvideFormValues } from 'Components/interfaces';
import { useCreateReferenceMutation } from 'Services/Applications/referenceApi';
import { useGetMeQuery } from 'Services/authApi';
import { useGetEmployeesFullListQuery } from 'Services/employeesApi';
import useRoleHooks from 'Routers/role-hooks';

export const useProvideReferenceHooks = () => {
  const { data: currentUser } = useGetMeQuery();
  const {currentRole} = useRoleHooks()
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<IReferenceProvideFormValues | FieldValues>({
    mode: 'all', defaultValues: {
      user_id:
        currentUser && currentUser?.data?.employee
          ? `${currentUser?.data.name} ${currentUser?.data.surname}`
          : undefined,
    },
  });
  const [referenceQuery, { isSuccess }] = useCreateReferenceMutation();
  const [showDataTable, setShowDataTable] = useState<boolean>(true);
  const { data: employees } = useGetEmployeesFullListQuery();

  const onSubmit: SubmitHandler<IReferenceProvideFormValues | FieldValues> = (data) => {
    const payload = {
      place: data.place,
      end_date: data.end_date,
      user_id: data.user_id.id ?? currentUser?.data?.id
    }
    referenceQuery(payload)
  };

  useEffect(() => {
    if (currentUser && currentUser?.data?.employee) {
      setValue(
        "user_id",
        currentUser && currentUser?.data?.employee
          ? `${currentUser?.data.name} ${currentUser?.data.surname}`
          : undefined
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (isSuccess) {
      setShowDataTable(true);
      toast.success(t('Aplication_Page.Successfully_Created'));
      reset();
    }
  }, [isSuccess])

  const employeeList = employees?.result?.map((item) => {
    return {
      id: item.userId,
      name: `${item?.user?.name} ${item?.user?.surname}`,
    };
  });

  return {
    register,
    onSubmit,
    handleSubmit,
    setShowDataTable,
    showDataTable,
    errors,
    currentRole,
    control,
    employeeList,
  };
};
