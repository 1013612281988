import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ApplicationTypes,
  ButtonTypes,
  HistoryTypes,
} from "Components/interfaces";
import Button from "Components/Button/button";
import Arrow from "Assets/Icons/arrow_back.svg";
import CustomPagination from "Components/Pagination/pagination";
import Loading from "Components/Loading/loading";
import NoData from "Components/NoData/noData";
import useHistory from "./history-hooks";
import { useGeneralHooks } from "Pages/general.hooks";
import styles from "./history.module.scss";

export interface IAppHistoryProps {
  type: HistoryTypes;
  setShow: Dispatch<SetStateAction<boolean>>;
  ordered_by: ApplicationTypes;
}
const AppHistory: React.FC<IAppHistoryProps> = (props) => {
  const { type, setShow, ordered_by } = props;
  const { t } = useTranslation();
  const { HISTORY_INNER, setActivePage, activePage } = useHistory();
  const { location } = useGeneralHooks();

  return (
    <div className="tableContainer">
      <div className={styles.container}>
        {HISTORY_INNER[`${type}`].isloading[`${ordered_by}`] ? (
          <Loading />
        ) : HISTORY_INNER[`${type}`].tabBody[`${ordered_by}`].props.data
            ?.length === 0 ? (
          <NoData
            withButton={location.pathname.includes("my_applications") ? true : false}
            title={t("No_Data.Title")}
            description={t("No_Data.Description")}
            btnText={t("No_Data.Btn_Text")}
            btnOnclick={() => {
              setShow(false);
            }}
          />
        ) : (
          <>
            <div>
              <TableContainer>
                <Table sx={{ width: "inherit" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {HISTORY_INNER[`${type}`].tabHeader.map(
                        (title: string, index: number) => {
                          return <TableCell key={index}>{title}</TableCell>;
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {HISTORY_INNER[`${type}`].tabBody[`${ordered_by}`]}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {HISTORY_INNER[`${type}`].count[`${ordered_by}`]! > 10 && (
              <CustomPagination
                count={Math.ceil(
                  HISTORY_INNER[`${type}`].count[`${ordered_by}`]! / 10
                )}
                page={activePage}
                onChange={(_, page) => {
                  setActivePage(page);
                  window.scrollTo(0, 0);
                }}
              />
            )}
            {location.pathname.includes("my_applications") && (
              <div>
                <Button
                  rightIcon={Arrow}
                  title={t("Aplication_Page.AppHistory.Button")}
                  buttonType={ButtonTypes.ButtonWithIcon}
                  buttonStyle={styles.button}
                  buttonTitleStyle={styles.buttonTitle}
                  onClick={() => setShow(false)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AppHistory;
