import React from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import { useGeneralHooks } from 'Pages/general.hooks';
import Button from 'Components/Button/button';
import AuthFormTitle from '../authFormTitle';
import InviteInput from 'Components/AuthComponents/InviteRequest/inviteInput';
import { ButtonTypes, RequestFormValues } from 'Components/interfaces';
import useInviteRequest from 'Pages/InviteRequest/inviteRequest-hooks';
import { IResponseDataItem } from 'Services/responce_types';
import AddUserIcon from 'Assets/Icons/user_add.svg';
import styles from './inviteForm.module.scss';

interface InviteFormProps {
  roles: IResponseDataItem[] | undefined;
  title?: string;
  containerStyle?: string;
}

const InviteForm: React.FC<InviteFormProps> = (props) => {
  const { t } = useGeneralHooks();
  const { roles, title, containerStyle } = props;

  const {
    fields,
    errors,
    addNewRequest,
    handleSubmit,
    register,
    remove,
    onsubmit,
    isLoading,
  } = useInviteRequest();

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <AuthFormTitle title={title ? title : ''} />
      <form className={styles.form} onSubmit={handleSubmit(onsubmit)}>
        <div className={styles.formBox}>
          <div
            className={
              fields.length > 4
                ? `${styles.inputBox} ${styles.scroll}`
                : styles.inputBox
            }
          >
            {fields.map((field, index) => {
              return (
                <InviteInput
                  roles={roles}
                  key={field.id}
                  register={
                    register as UseFormRegister<RequestFormValues | FieldValues>
                  }
                  emailValue={`inviteRequests.${index}.email.value`}
                  roleValue={`inviteRequests.${index}.role.value`}
                  emailError={errors.inviteRequests?.[index]?.email?.value}
                  roleError={errors.inviteRequests?.[index]?.role?.value}
                  iconOnClick={() => remove(index)}
                />
              );
            })}
          </div>
          <div className={styles.smallButtonBox}>
            <Button
              type='button'
              buttonType={ButtonTypes.ButtonWithIcon}
              title={t('Invite_Request.Small_Button_Title')}
              leftIcon={AddUserIcon}
              onClick={addNewRequest}
            />
          </div>
        </div>
        <Button
          disable={isLoading}
          isLoading={isLoading}
          buttonType={ButtonTypes.Primery}
          title={t('Invite_Request.Big_Button_Title')}
          type='submit'
        />
      </form>
    </div>
  );
};

export default InviteForm;
