import React, { useEffect } from 'react';
import InputField from '../inputField';
import { SearchIcon } from 'Assets';
import useSearchHooks from './searchInput-hooks';
import styles from './searchInputstyles.module.scss';
import { t } from 'i18next';

export interface SearchInputValues {
  search: string;
}
const SearchInput: React.FC = () => {
  const { searchValue, setSearchValue, register } = useSearchHooks();

  useEffect(() => {
  }, [searchValue]);

  return (
    <div className={styles.container}>
      <InputField
        type='text'
        id='search'
        placeholder={t('Search_Input_Placeholder')}
        label=''
        inputStyle={styles.inputStyle}
        register={register}
        registerName='search'
        onChange={(e) => setSearchValue(e.target.value)}
      >
        <span className={styles.searchIcon}>
          <img src={SearchIcon} alt='Search' />
        </span>
      </InputField>
    </div>
  );
};

export default SearchInput;
