import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { IAbsenseRespResult } from 'Services/responce_types';
import { useGeneralHooks } from 'Pages/general.hooks';
import { useApplicationItem } from 'Components/Application/ApplicationItems/applicationItem.hooks';
import HistoryItemStatus from '../Status/historyItemStatus';
import styles from '../history.module.scss';

export interface IVacationHistoryProps {
  data: IAbsenseRespResult[];
}

const VacationHistoryItem: React.FC<IVacationHistoryProps> = (props) => {
  const { data } = props;
  const { formatDate } = useApplicationItem();
  const { navigate, location, t } = useGeneralHooks();
  const options = (data ?? []).map((item) => item);

  return (
    <>
      {options?.map((item: IAbsenseRespResult, index: number) => {
        return (
          <TableRow
            className={styles['application-row']}
            onClick={() =>
              location.pathname.includes('vacation')
                ? navigate(`/main/documents/applications/vacation/${item.id}`)
                : navigate(`/main/documents/applications/absence/${item.id}`)
            }
            key={index}
          >
            <TableCell>
              {item?.user?.name} {item?.user?.surname}
            </TableCell>
            <TableCell>
              {+item.total! > 1
                ? item.total + ' ' + t('Aplication_Page.Vacation.Days')
                : item.total + ' ' + t('Aplication_Page.Vacation.Day')}
            </TableCell>
            <TableCell>{formatDate(item.startDate!)}</TableCell>
            <TableCell>{formatDate(item.endDate!)}</TableCell>
            <TableCell>
              <HistoryItemStatus
                bgColor={item?.status?.color}
                text={t(`Statuses_From_Server.${item?.status?.name}`)}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default VacationHistoryItem;
