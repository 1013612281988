import React from 'react';
import { t } from 'i18next';
import { useGeneralHooks } from 'Pages/general.hooks';
import AuthError from 'Components/AuthComponents/AuthError/authError';
import styles from './uploadFile.module.scss';

interface IUploadFiles {
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void,
  selectedFiles: any[],
  deleteFile: (i: number) => void,
  error?: any
}

const UploadFile: React.FC<IUploadFiles> = ({ onChangeFile, deleteFile, selectedFiles, error }) => {
  const { largeScreen } = useGeneralHooks();
  return (
    <div>
      {largeScreen
        ?
        <div>
          <div className={styles.container}>
            <div className={styles.files}>
              {
                selectedFiles.length > 0 && selectedFiles.map((file, i) => {
                  return (
                    <p key={i}>
                      {file.split('-')[1]?.substring(0, 4) + '...'}
                      <span onClick={() => { deleteFile(i) }}>x</span>
                    </p>
                  )
                })
              }
            </div>
            <div>
              <label
                htmlFor='upload'
                className={`${styles.uploadFile} ${selectedFiles.length > 4 && styles.disabled}`}>
                {t('Aplication_Page.Pension.File')}
              </label>
              <input
                id='upload'
                multiple={true}
                type='file'
                accept=".json,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                disabled={selectedFiles.length > 4}
                onChange={onChangeFile}
              />
            </div>
          </div>
          {
            <AuthError text={error && error.message} />
          }
        </div>
        :
        <div className={styles.mobileBox}>
          <div className={styles.label}>
            {t('Aplication_Page.Pension.File')}
          </div>
          <div className={`${styles.mobileUpload} ${selectedFiles.length > 4 && styles.disabled}`}>
            <label
              htmlFor='upload'
            >
              {t('Aplication_Page.Pension.File')}
            </label>
            <input
              id='upload'
              multiple={true}
              type='file'
              accept=".json,.doc, .docx, .ppt, .pptx,.txt,.pdf"
              disabled={selectedFiles.length > 4}
              onChange={onChangeFile}
            />
          </div>
          <div className={styles.mobileFiles}>
              {
                selectedFiles.length > 0 && selectedFiles.map((file, i) => {
                  return (
                    <p key={i}>
                      {file?.name.substring(0, 4) + '...'}
                      <span onClick={() => { deleteFile(i) }}>x</span>
                    </p>
                  )
                })
              }
            </div>
        </div>
      }
    </div>
  )
}

export default UploadFile
