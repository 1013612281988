import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { ICreateGroupForm } from "Pages/interfaces";
import { useCreateGroupMutation } from "Services/groupsApi";

const useCreateGroupHooks = () => {
    const { register, handleSubmit, formState: { errors }, } = useForm<ICreateGroupForm | FieldValues>();
    const [createGroup,{data:postGroupResponse,isLoading,isSuccess}] = useCreateGroupMutation();

    const onSubmit: SubmitHandler<ICreateGroupForm | FieldValues> = (data) => {
        createGroup(data)
    }
    return {
        register,
        onSubmit,
        handleSubmit,
        errors,
        isLoading,
        isSuccess
    }
};

export default useCreateGroupHooks