import React, { useEffect } from 'react';
import AbsenceForm from './absenceForm';
import AppHistory from 'Components/Application/AppHistory/appHistory';
import { ApplicationTypes, HistoryTypes } from 'Components/interfaces';
import { useAbsenceHooks } from './absence-hooks';
import { IApplicationComponentProps } from '../Vacation/vacationComponent';

const AbsenceComponent: React.FC<IApplicationComponentProps> = (props) => {
  const { ordered_by } = props;
  const {
    showDataTable,
    employeeList,
    isLoading,
    isSuccess,
    isValid,
    control,
    errors,
    currentRole,
    total,
    watch,
    register,
    onSubmit,
    handleSubmit,
    setShowDataTable,
  } = useAbsenceHooks();

  useEffect(() => {
    setShowDataTable(true)
  }, [ordered_by]);
  
  return (
    <>
      {
        ordered_by === ApplicationTypes.Company_Applications
          ?
          <AppHistory
            type={HistoryTypes.Absence}
            ordered_by={ordered_by}
            setShow={setShowDataTable}
          />
          :
          showDataTable ? (
            <AppHistory
              type={HistoryTypes.Absence}
              ordered_by={ordered_by}
              setShow={setShowDataTable}
            />
          ) : (
            <AbsenceForm
              isValid={isValid}
              register={register}
              errors={errors}
              watch={watch}
              total={total}
              onSubmit={handleSubmit(onSubmit)}
              isLoading={isLoading}
              setShowDataTable={setShowDataTable}
              isSuccess={isSuccess}
              role={currentRole}
              control={control}
              employeeList={employeeList}
            />
          )
      }
    </>
  );
};

export default AbsenceComponent;
