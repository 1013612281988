import React, { useEffect } from "react";
import { IApplicationComponentProps } from "../Vacation/vacationComponent";
import { PensionForm } from "./pensionForm";
import { usePensionHooks } from "./pension-hooks";
import { ApplicationTypes, HistoryTypes } from "Components/interfaces";
import AppHistory from "Components/Application/AppHistory/appHistory";

const Pension: React.FC<IApplicationComponentProps> = (props) => {
  const { ordered_by } = props;
  const { showDataTable, setShowDataTable, onSubmit } = usePensionHooks();

  useEffect(() => {
    setShowDataTable(true)
  }, [ordered_by]);

  return (
    <>
      {
        ordered_by === ApplicationTypes.Company_Applications
          ?
          <AppHistory
            type={HistoryTypes.Pension}
            ordered_by={ordered_by}
            setShow={setShowDataTable}
          />
          :
          showDataTable ? (
            <AppHistory
              type={HistoryTypes.Pension}
              ordered_by={ordered_by}
              setShow={setShowDataTable}
            />
          ) : (
            <PensionForm onSubmit={onSubmit} setShowDataTable={setShowDataTable} />
          )
      }
    </>
  );
};
export default Pension;
