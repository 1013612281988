import React, { Dispatch, SetStateAction } from 'react';
import Loading from 'Components/Loading/loading';
import NoData from 'Components/NoData/noData';
import { useGeneralHooks } from 'Pages/general.hooks';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomPagination from 'Components/Pagination/pagination';
import styles from '../../Application/AppHistory/history.module.scss';

interface IProps {
    data: any,
    activePage: number,
    setActivePage: Dispatch<SetStateAction<number>>,
    isLoading: boolean
}
const EmployeeHistoryList: React.FC<IProps> = (props) => {
    const { data, activePage, setActivePage, isLoading } = props;
    const { t } = useGeneralHooks();
    return (
        <div className="tableContainer">
            <div className={styles.container}>
                {isLoading ? (
                    <Loading />
                ) : data.tabBody.props.data
                    ?.length === 0 ? (
                    <NoData
                        title={t("No_Data.Title")}
                        withButton={false}
                    />
                ) : (
                    <>
                        <div>
                            <TableContainer>
                                <Table sx={{ width: "inherit" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {data.tabHeader.map(
                                                (title: string, index: number) => {
                                                    return <TableCell key={index}>{title}</TableCell>;
                                                }
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.tabBody}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {data.tabBody.props.data.length! > 10 && (
                            <CustomPagination
                                count={Math.ceil(
                                    data.tabBody.props.data.length! / 10
                                )}
                                page={activePage}
                                onChange={(_, page) => {
                                    setActivePage(page);
                                    window.scrollTo(0, 0);
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default EmployeeHistoryList
