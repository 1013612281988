import React from 'react';
import { useTranslation } from 'react-i18next';
import HistoryItemStatus from '../Application/AppHistory/Status/historyItemStatus';
import { CardTypes } from '../interfaces';
import styles from './responiveVacation.module.scss';
import moment from 'moment';
import { useLocation } from 'react-router';

interface IVacationCardProps {
  onPress: () => void;
  type: CardTypes;
  startDate?: Date | string;
  endDate?: Date | string;
  title?: string;
  days?: string | number;
  text: string;
  bgColor: string;
  name?: string;
}

const ResponsiveVactionCard: React.FC<IVacationCardProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const { onPress, type, startDate, endDate, title, text, bgColor, name } =
    props;
  return (
    <div onClick={onPress} className={styles.container}>
      <div className={styles.flexStyle}>
        {title && <p className={styles.name}>{title}</p>}
        {text && <HistoryItemStatus text={t(`Statuses_From_Server.${text}`)} bgColor={bgColor} />}
      </div>
      {type === CardTypes.WithEndDate && (
        <>
          <div className={styles.dateContent}>{name}</div>
          <div>
            <p className={styles.dateContent}>
              {t('ResponsiveVacation.From_to_end')}
            </p>
          </div>
          <div>
            <p className={styles.date}>
              {`${moment(startDate).format('DD/MM/YYYY')} - ${moment(
                endDate
              ).format('DD/MM/YYYY')}`}
            </p>
          </div>
        </>
      )}
      {type === CardTypes.WithoutEndDate && (
        <>
          <div className={styles.flexStyle}>
            <p className={styles.dateContent}>
              {path === 'dismissal'
                ? t('ResponsiveVacation.DismissDay')
                : t('ResponsiveVacation.GivDay')}
            </p>
            <p className={`${styles.date} ${styles.datePadding}`}>
              {moment(startDate).format('DD/MM/YYYY')}
            </p>
          </div>
          <div className={styles.dateContent}>{name}</div>
        </>
      )}
    </div>
  );
};

export default ResponsiveVactionCard;
