import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';
import { IFileResponse, IMultipleUpload } from './responce_types';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    uploadFile: builder.mutation<IFileResponse, FormData>({
      query: (file) => ({
        url: '/upload/single',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: file,
      }),
    }),
    uploadMultiple: builder.mutation<IMultipleUpload, FormData>({
      query: (files) => ({
        url: '/upload/multiple',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: files,
      }),
    }),
  }),
});

export const { useUploadFileMutation, useUploadMultipleMutation } =
  applicationsApi;
