import { IRoleInCompany } from 'Services/responce_types';
import DrawerCompanyItem from './DrawerCompanyItem';
import useDrawerCompaniesHooks from './DrawerCompanies.hooks';
import styles from './drawerCompanies.module.scss';

const DrawerCompanyList: React.FC = () => {
  const { currentUser, setActiveCompany } = useDrawerCompaniesHooks();
  return (
    <div className={styles['drawer-company-list__container']}>
      {currentUser?.data?.userRoleInCompany?.map((item: IRoleInCompany) => {
        return (
          <DrawerCompanyItem
            isActive={
              localStorage.getItem('active-company-id')
                ? +localStorage.getItem('active-company-id')! ===
                  item.company.id
                : currentUser?.data?.activeCompanyId === item.company.id
            }
            name={item.company.name}
            key={item.company.id}
            src={item.company.avatar}
            onClick={() => {
              setActiveCompany({
                user_id: currentUser.data.id,
                company_id: item.company.id,
              });
              localStorage.setItem(
                'active-company-id',
                item.company.id.toString()
              );
            }}
          />
        );
      })}
    </div>
  );
};

export default DrawerCompanyList;
