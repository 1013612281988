import { IAppDetailInfo } from 'Components/interfaces';
import styles from './applicationDetailInfo.module.scss';

interface IProps {
  data: IAppDetailInfo[];
  dismissal?: boolean;
}

const ApplicationDetailInfo: React.FC<IProps> = (props) => {
  const {data,dismissal} = props;
  
  return (
    <div className={styles['detail-info__container']}>
      {data?.map((item: IAppDetailInfo, index: number) => {
        return (
          <div
            className={
              dismissal ? styles['dismissal-row'] : styles['row']
            }
            key={index}
          >
            <span className={styles['label']}>{item.label}</span>
            <span className={styles['value']}>{item.value}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ApplicationDetailInfo;
