import React, { Dispatch, SetStateAction } from "react";
import {
  FieldValues,
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  Controller,
  Control,
} from "react-hook-form";
import Button from "Components/Button/button";
import {
  AutoComplete,
  InputField,
  TextArea,
} from "Components/AuthComponents/AuthInputs";
import Arrow from "Assets/Icons/arrow_back.svg";
import { IRole } from "Services/responce_types";
import { ButtonTypes } from "Components/interfaces";
import { useGeneralHooks } from "Pages/general.hooks";
import styles from "../appliacationItems.module.scss";

interface IAbsenceFormProps {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  onSubmit: () => void;
  total: number;
  isValid: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errors: FieldErrors<FieldValues>;
  setShowDataTable?: Dispatch<SetStateAction<boolean>>;
  role: string | undefined;
  control: Control<FieldValues, any>;
  employeeList: IRole[] | undefined;
}

const AbsenceForm: React.FC<IAbsenceFormProps> = (props) => {
  const {
    watch,
    register,
    onSubmit,
    setShowDataTable,
    employeeList,
    isLoading,
    control,
    errors,
    total,
    role,
  } = props;
  
  const { t, largeScreen } = useGeneralHooks();
  const minDate = new Date().toISOString().split("T")[0];  

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.header}>
          {!largeScreen && (
            <div className={styles.title}>
              {t("Aplication_Page.Tab_Labels.Absence")}
            </div>
          )}
          <div className={styles.describtion}>
            {t("Aplication_Page.Vacation.Description")}
          </div>
        </div>
        <form onSubmit={onSubmit} className={styles.formBox} id="absenceForm">
          <div className={styles.form}>
            <div className={styles.datePicker}>
              <InputField
                type="date"
                minDate={minDate}
                id="dateStart"
                label={
                  largeScreen
                    ? t("Aplication_Page.Absence.Start_Date")
                    : t("Aplication_Page.Absence.Start")
                }
                register={register}
                registerName="start_date"
                error={errors.startDate}
                validation={(value) =>
                  value >= minDate || t("Input_Errors.Invalid_Date")
                }
                inputStyle={styles.inputField}
              />
              <InputField
                type="date"
                minDate={minDate}
                id="endDate"
                label={
                  largeScreen
                    ? t("Aplication_Page.Absence.End_Date")
                    : t("Aplication_Page.Absence.End")
                }
                register={register}
                registerName="end_date"
                validation={(value) =>
                  value >= watch("start_date") || t("Input_Errors.Invalid_Date")
                }
                error={errors.end_date}
                inputStyle={styles.inputField}
              />
            </div>
            <div className={styles.totalItemBox}>
              <div className={styles.totalLabel}>
                {t("Aplication_Page.Vacation.Total")}
              </div>
              <div className={styles.totalInput}>
                <div className={styles.totalResult}>{total > 0 && total}</div>
              </div>
            </div>
          </div>
          <div className={styles.footerBox}>
            <div>
              <div className={styles.absenceReason}>
                <TextArea
                  label={t("Aplication_Page.Absence.Reason")}
                  placeholder={t(
                    "Aplication_Page.Absence.Textarea_PlaceHolder"
                  )}
                  register={register}
                  registerName="reason"
                  id="absence"
                  error={errors.reason}
                  inputStyle={styles.dismissalField}
                  rows={5}
                />
              </div>
              {role && role === "EMP" ? (
                <InputField
                  type="text"
                  disabled={true}
                  id="worker"
                  label={t("Aplication_Page.Desmissal.Employee")}
                  register={register}
                  registerName="user_id"
                  inputStyle={styles.inputField}
                />
              ) : (
                <Controller
                  control={control}
                  name="user_id"
                  rules={{
                    required: t("Input_Errors.Required"),
                  }}
                  render={({ field: { onChange, name } }) => {
                    return (
                      <AutoComplete
                        name={name}
                        onChange={onChange}
                        id="user_id"
                        data={employeeList}
                        label={t("Aplication_Page.Desmissal.Employee")}
                        placeholder={t(
                          "Aplication_Page.Desmissal.Employee_placeholder"
                        )}
                        error={errors.user_id}
                      />
                    );
                  }}
                />
              )}
            </div>
            <div className={styles.buttonContent}>
              <Button
                title={t("Aplication_Page.Button")}
                type="button"
                buttonStyle={styles.withoutBtn}
                buttonTitleStyle={styles.withoutBtnTitle}
                buttonType={ButtonTypes.WithoutBg}
                onClick={() => {
                  setShowDataTable!(true);
                }}
              />
              <Button
                type="submit"
                rightIcon={Arrow}
                buttonTitleStyle={styles.BtnTitle}
                title={t("Aplication_Page.Vacation.Buuton_Apply")}
                buttonType={ButtonTypes.ButtonWithIcon}
                buttonStyle={styles.btn}
                isLoading={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AbsenceForm;
