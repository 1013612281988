import React, { ReactNode } from 'react';
import Logo from 'Components/Logo/logo';
import styles from './leftSide.module.scss';
import hrLogo from 'Assets/Images/hrLoopLogo.png';

interface ILeftSide {
  title: string;
  describtion: string;
  children?: ReactNode;
}
const LeftSide: React.FC<ILeftSide> = (props) => {
  const { title, describtion, children } = props;
  return (
    <div className={styles.container}>
      <img src={hrLogo} className={styles['logo-img']} />
      <div className={styles.titleBox}>
        <div className={styles.title}>{title}</div>
        <div className={styles.describtion}>{describtion}</div>
      </div>
      <div className={styles.picture}>{children}</div>
    </div>
  );
};

export default LeftSide;
