import React, { Dispatch, SetStateAction } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  AbsenceComponent,
  Dismissal,
  Pension,
  ProvideReference,
  VacationComponent,
}
  from 'Components/Application/ApplicationItems';
import {
  ApplicationTypes,
  IApplicationTabs,
  IcustomTabPanelDataItem,
  TabPanelProps,
} from 'Components/interfaces';
import { useApplyTabulation } from './applyTabulation.hook';
import {
  ResponsivePageAbsence,
  ResponsivePageDisMissal,
  ResponsivePagePension,
  ResponsivePageReference,
  ResponsivePageVacation,
} from 'Pages';
import { useGeneralHooks } from 'Pages/general.hooks';
import styles from './applyTabulation.module.scss';
import { AppScreenType } from 'Pages/interfaces';

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '30px' }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface IApplyTabulationProps {
  type: ApplicationTypes,
  setScreenType: Dispatch<SetStateAction<AppScreenType>>,
  screenType: AppScreenType

}

export const ApplyTabulation: React.FC<IApplyTabulationProps> = (props) => {
  const { type, setScreenType, screenType } = props;
  const { value, appTabs, handleChange, navigate, a11yProps } =
    useApplyTabulation();
  const { mobileScreen, largeScreen } = useGeneralHooks();

  const customTabPanelData: IcustomTabPanelDataItem[] = [
    {
      index: 0,
      component: <VacationComponent ordered_by={type} />,
    },
    {
      index: 1,
      component: <AbsenceComponent ordered_by={type} />,
    },
    {
      index: 2,
      component: <Pension ordered_by={type} />,
    },
    {
      index: 3,
      component: <Dismissal ordered_by={type} />,
    },
    {
      index: 4,
      component: <ProvideReference ordered_by={type} />,
    },
  ];

  const mobileTabPanelData: IcustomTabPanelDataItem[] = [
    {
      index: 0,
      component:
        <ResponsivePageVacation
          ordered_by={type}
          screenType={screenType}
          setScreenType={setScreenType}
        />,
    },
    {
      index: 1,
      component:
        <ResponsivePageAbsence
          ordered_by={type}
          screenType={screenType}
          setScreenType={setScreenType}
        />,
    },
    {
      index: 2,
      component:
        <ResponsivePagePension
          ordered_by={type}
          screenType={screenType}
          setScreenType={setScreenType}
        />,
    },
    {
      index: 3,
      component:
        <ResponsivePageDisMissal
          ordered_by={type}
          screenType={screenType}
          setScreenType={setScreenType}
        />,
    },
    {
      index: 4,
      component:
        <ResponsivePageReference
          ordered_by={type}
          screenType={screenType}
          setScreenType={setScreenType}
        />,
    },
  ];
  const data = mobileScreen ? mobileTabPanelData : customTabPanelData;

  return (
    <div className={`${styles.heightContainer} mainContainer`}>
      <div className={styles.container}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              variant={!largeScreen ? "scrollable" : "standard"}
              scrollButtons={mobileScreen ? false : true}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#1B2559',
                },
              }}
            >
              {appTabs.map((item: IApplicationTabs, index) => {
                return (
                  <Tab
                    label={item.label}
                    onClick={() => navigate(item.path)}
                    {...a11yProps(index)}
                    key={index}
                  />
                );
              })}
            </Tabs>
          </Box>
          {data.map((item: IcustomTabPanelDataItem) => {
            return (
              <CustomTabPanel value={value} index={item.index} key={item.index}>
                {item.component}
              </CustomTabPanel>
            );
          })}
        </Box>
      </div>
    </div>
  );
};

export default ApplyTabulation;
