import { Control, FieldErrors, FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form"
import { CreateCompanyFormValues } from "Pages/interfaces";
import { CreateCompanyWebForm } from "./Web";
import { useGeneralHooks } from "Pages/general.hooks";
import CreateCompanyMobileForm from "./MobileDevice/createCompanyMobileForm";
import { Dispatch, SetStateAction } from "react";

export interface ICreateCompanyProps {
  onSubmit?:()=>void,
  setNext?: Dispatch<SetStateAction<boolean>>
  register?: UseFormRegister<FieldValues | CreateCompanyFormValues>,
  errors: FieldErrors<FieldValues | CreateCompanyFormValues>,
  control: Control<FieldValues | CreateCompanyFormValues, any>,
  watch: UseFormWatch<CreateCompanyFormValues | FieldValues>,
  isValid?: boolean,
  isLoading?: boolean,
};

const CreatCompontyForm: React.FC<ICreateCompanyProps> = (props) => {
  const { register, control, watch, onSubmit, isValid, isLoading, errors } = props;
  const { mobileScreen } = useGeneralHooks();

  return (
    <>
      {mobileScreen ?
        <CreateCompanyMobileForm
          register={register}
          control={control}
          watch={watch}
          onSubmit={onSubmit}
          isValid={isValid}
          isLoading={isLoading}
          errors={errors}
        />
        :
        <CreateCompanyWebForm
          register={register}
          control={control}
          watch={watch}
          onSubmit={onSubmit}
          isValid={isValid}
          isLoading={isLoading}
          errors={errors}
        />
       } 
    </>
  )
}

export default CreatCompontyForm