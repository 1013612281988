import { useEffect } from 'react';
import {
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useChangeVacationStatusMutation,
  useGetVacationAvailableStatusesQuery,
  useGetVacationByIdQuery,
} from 'Services/Applications/vacationApi';
import { IAvailableStatusesRespResult, IChangeStatusData } from 'Services/responce_types';
import { useApplicationItem } from 'Components/Application/ApplicationItems/applicationItem.hooks';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import { CurrentStatus } from 'Components/Application/ApplicationItems';
import { IVacationStatusChangeValues } from 'Pages/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import { IAppDetailInfo } from 'Components/interfaces';
import useRoleHooks from 'Routers/role-hooks';

const useVacationDetailHooks = () => {
  const { t, params } = useGeneralHooks();

  const { formatDate } = useApplicationItem();

  const { data: vacationByIdInfo, isFetching } = useGetVacationByIdQuery(+params.id!);
  const { data: vacationAvailableStatuses } = useGetVacationAvailableStatusesQuery(+vacationByIdInfo?.result?.status?.id);
  const [changeVacationStatus, { isSuccess }] = useChangeVacationStatusMutation();
  const {currentRole}= useRoleHooks();
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IVacationStatusChangeValues | FieldValues>({ mode: 'all' });

  const onSubmit: SubmitHandler<IVacationStatusChangeValues | FieldValues> = (data) => {
    const payload: IChangeStatusData = {
      id: +params.id!,
      description: data.description,
      status_to_id: data.status_id.id,
      status_from_id: vacationByIdInfo?.result?.status?.id,
      userId: vacationByIdInfo?.result?.user?.id
    };
    changeVacationStatus(payload);
  };
  const statuses = vacationAvailableStatuses?.result?.map((item: IAvailableStatusesRespResult) => ({
    name: t(`Statuses_From_Server.${item.statusTo.name}`),
    id: item.statusTo.id,
  }));

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        t('Aplication_Page.App_Status.Status_Successfully_Changed')
      );
      reset();
      handleClose();
      // navigate('/main/documents/applications/vacation');
    }
  }, [isSuccess]);


  const renderItemData: IAppDetailInfo[] = [
    {
      label: t('Aplication_Page.AppHistory.Name'),
      value: `${vacationByIdInfo?.result?.user?.name} ${vacationByIdInfo?.result?.user?.surname}`,
    },
    {
      label:
        window.innerWidth > 320
          ? t('Aplication_Page.Vacation.Start_Date')
          : t('Aplication_Page.Vacation.Start'),
      value: formatDate(vacationByIdInfo?.result?.startDate!),
    },
    {
      label:
        window.innerWidth > 320
          ? t('Aplication_Page.Vacation.End_Date')
          : t('Aplication_Page.Vacation.End'),
      value: formatDate(vacationByIdInfo?.result?.endDate!),
    },
    {
      label: t('Aplication_Page.Vacation.Total'),
      value: `${vacationByIdInfo?.result?.total} ${t(
        'Aplication_Page.Vacation.Day'
      )}`,
    },
    {
      label: t('Aplication_Page.Vacation.Status'),
      value:
        <CurrentStatus
          onClick={currentRole !== 'EMP' ? handleOpen : () => { }}
          statusName={t(`Statuses_From_Server.${vacationByIdInfo?.result?.status?.name}`)}
          bgColor={vacationByIdInfo?.result?.status?.color!}
        />,
    },
  ];

  return {
    vacationAvailableStatuses,
    vacationByIdInfo,
    renderItemData,
    isFetching,
    openModal,
    statuses,
    control,
    params,
    errors,
    onSubmit,
    register,
    handleClose,
    handleSubmit,
  };
};

export default useVacationDetailHooks;
