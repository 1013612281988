import React from 'react';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ProfileBriefInfo } from 'Components/ProfileBriefInfo/profileBriefInfo';
import DrawerCompanies from 'Components/DrawerCompanies/DrawerCompanies';
import LogOutComponent from 'Components/LogOut/logOutComponent';
import { ILayoutData, INavList } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import LogoLayout from 'Assets/Images/hrLoopLogo.jpg';
import { useLayoutHooks } from './layout-hooks';
import styles from './layout.module.scss';

interface ILayoutProps {
  onDrawerClose?: () => void;
}
export const Layout: React.FC<ILayoutProps> = (props) => {
  const { onDrawerClose } = props;
  const { expanded, handleChange, renderMenu, sx, setExpanded, CurrentUser } =
    useLayoutHooks();
  const { largeScreen } = useGeneralHooks();
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.logo}>
          {largeScreen ? (
            <img
              src={LogoLayout}
              alt='HR Online'
              className={styles['drawer-logo']}
            />
          ) : (
            <ProfileBriefInfo
              avatar={CurrentUser?.data?.avatar}
              name={`${CurrentUser?.data?.name} ${CurrentUser?.data?.surname}`}
              email={CurrentUser?.data?.email}
              setExpanded={setExpanded}
            />
          )}
        </div>
        <DrawerCompanies />
        <div className={styles.navMenuContainer}>
          {renderMenu.map((navMenu: ILayoutData, i: number) => {
            let active = expanded === navMenu.activeMenu;
            return (
              <div key={i} className='accordion'>
                <Accordion
                  defaultChecked={true}
                  expanded={navMenu.list ? active : false}
                  onChange={handleChange(navMenu)}
                  sx={sx}
                  disabled={!navMenu.available}
                >
                  <AccordionSummary
                    sx={active ? { backgroundColor: '#006AF5' } : null}
                  >
                    <div className={styles.flexStyle}>
                      {active ? (
                        <img src={navMenu.whiteIcon} alt='whiteIcon' />
                      ) : (
                        <img src={navMenu.grayIcon} alt='grayIcon' />
                      )}
                      <h3 style={active ? { color: 'white' } : {}}>
                        {navMenu.title}
                      </h3>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <div className={`${styles.navList} navList`}>
                      {navMenu.list &&
                        navMenu.list.map((listItem: INavList, i: number) => {
                          return (
                            <NavLink
                              key={i}
                              to={listItem.link}
                              onClick={onDrawerClose}
                            >
                              {listItem.title}
                            </NavLink>
                          );
                        })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.userContent}>
        {largeScreen && (
          <ProfileBriefInfo
            setExpanded={setExpanded}
            avatar={CurrentUser?.data?.avatar}
            name={`${CurrentUser?.data?.name} ${CurrentUser?.data?.surname}`}
            email={CurrentUser?.data?.email}
          />
        )}
        <LogOutComponent />
      </div>
    </div>
  );
};
