import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useGeneralHooks } from 'Pages/general.hooks';
import useEmployeeHistoryHooks from './employeeHistory-hooks';
import EmployeeHistoryList from '../EmployeeHistoryList/employeeHistoryList';
import EmployeeHistoryFilterForm from '../FilterForm/employeeHistoryFilterForm';
import styles from './appistory.module.scss';

interface IProps {
  employeeId?: number
}

const EmployeeAppHistory: React.FC<IProps> = (props) => {
  const { employeeId } = props;
  const {
    LARGE_SCREEN_INNER,
    activePage,
    appTypeOptions,
    isLoading,
    isFilter,
    renderStatusOptions,
    setActivePage,
    toggleFilter,
    handleSubmit,
    onSubmit,
    register,
    control } = useEmployeeHistoryHooks(employeeId!);

  const { t } = useGeneralHooks();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>
          {t("Employee_History.History")}
        </span>
      </div>
      <div>
        <div className={styles.navTitle}>
          <div>
            <span className={styles.subTitle}>
              {t("LayoutMenu.NavList.Applications")}
            </span>
          </div>
          <div onClick={toggleFilter} className={styles.filterButton}>
            {isFilter
              ?
              <FilterListIcon />
              :
              <FilterListOffIcon />
            }

            <span> {t("Employee_History.Filter")}</span>
          </div>
        </div>
        <div className={isFilter ? styles.filterForm : styles.hidden}>
          <EmployeeHistoryFilterForm
            register={register}
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            appTypeOptions={appTypeOptions}
            statuseOptions={renderStatusOptions()}
            isFilter={isFilter}
          />
        </div>
        <EmployeeHistoryList
          data={LARGE_SCREEN_INNER}
          activePage={activePage}
          setActivePage={setActivePage}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default EmployeeAppHistory
