import React from "react";
import { useTranslation } from "react-i18next";
import Button from "Components/Button/button";
import { ILoginFormProps } from "../AuthForms/loginForm";
import AuthFormTitle from "../AuthForms/authFormTitle";
import { InputField } from "../AuthInputs";
import BottomLink from "../BottomLink/bottomLink";
import styles from "./forgetPassword.module.scss";
import { ButtonTypes } from "Components/interfaces";

const ForgetPasswordEmail: React.FC<ILoginFormProps> = (props) => {
  const { onSubmit, register, errors, isValid, isLoading } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.formContainer}>
        <AuthFormTitle
          title={t("Forget_Password.First_page.Title")}
          description={t("Forget_Password.First_page.Descrition")}
        />
        <form onSubmit={onSubmit} className={styles.forgetPasswordForm}>
          <InputField
            type="text"
            id="email"
            placeholder={t("Forget_Password.First_page.Email_placeholder")}
            label={t("Forget_Password.First_page.Email_label")}
            register={register}
            registerName="email"
            message={t("Input_Errors.Email")}
            patternValue={/^[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}$/i}
            error={errors.email}
          />
          <Button
            buttonType={isValid ? ButtonTypes.Primery : ButtonTypes.Disabled}
            type="submit"
            title={t("Forget_Password.First_page.Send")}
            disable={!isValid}
            isLoading={isLoading}
          />
        </form>
      </div>
      <div className={styles.bottomBox}>
        <BottomLink
          text={t("Forget_Password.First_page.Rememebre_password")}
          linkTitle={t("Forget_Password.First_page.Sign_in")}
          link="/"
        />
      </div>
    </>
  );
};

export default ForgetPasswordEmail;
