import React from "react";
import UseNotificationHooks from "Components/Notifications/notification.hooks";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import NotificationList from "Components/Notifications/notificationList";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { useGeneralHooks } from "Pages/general.hooks";
import { HeaderTypes } from "Components/interfaces";
import NoData from "Components/NoData/noData";
import { Header } from "Components";
import styles from "./notifications.module.scss";

const Notifications: React.FC = () => {
  const { largeScreen, navigate, t } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();
  const { notifications } = UseNotificationHooks();

  return (
    <div className={styles.container}>
      {!largeScreen ? (
        <Header
          type={HeaderTypes.Navigation}
          navTitle={t("Notifications.Title")}
          iconOnClick={() => navigate(-1)}
        />
      ) : (
        <div>
          <BreadCrumbs data={pathsToRender} />
          <div className={styles.NotificationsTitle}>
            {t("Notifications.Title")}
          </div>
        </div>
      )}
      {notifications?.count === 0 ? (
        <div className={styles.resoponsive_container}>
          <NoData
            withButton={false}
            title={t("Notifications.Title")}
            description={t("Notifications.NoData")}
          />
        </div>
      ) : (
          <NotificationList />
      )}
    </div>
  );
};

export default Notifications;
