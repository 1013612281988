import React from 'react';
import { useGeneralHooks } from 'Pages/general.hooks';
import { useApplicationItem } from 'Components/Application/ApplicationItems/applicationItem.hooks';
import { TableCell, TableRow } from '@mui/material';
import HistoryItemStatus from 'Components/Application/AppHistory/Status/historyItemStatus';
import styles from '../../Application/AppHistory/history.module.scss';
import { IEmployeAppHistoryItem } from 'Services/responce_types';

interface IProps {
  data: IEmployeAppHistoryItem[]
}

const EmployeeHistoryItem: React.FC<IProps> = ({ data }) => {
  const { formatDate } = useApplicationItem();
  const { navigate, t } = useGeneralHooks();
  const options: IEmployeAppHistoryItem[] = (data ?? []).map((item: any) => item);

  return (
    <>
      {options?.map((item: IEmployeAppHistoryItem, index: number) => {
        return (
          <TableRow
            className={styles['application-row']}
            onClick={() =>
              navigate(`/main/documents/applications/${item?.type}/${item?.id}`)
            }
            key={index}
          >
            <TableCell>
              {t(`APP_Types_From_Server.${item?.type}`)}
            </TableCell>
            <TableCell>{formatDate(item?.created_at)}</TableCell>
            <TableCell>
              <HistoryItemStatus
                bgColor={item?.status_color}
                text={t(`Statuses_From_Server.${item?.status_name}`)}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  )
}

export default EmployeeHistoryItem
