import { useEffect } from 'react';
import { useSwitchCompanyMutation } from 'Services/companiesApi';
import { useGetMeQuery } from 'Services/authApi';

const useDrawerCompaniesHooks = () => {
  const { data: currentUser } = useGetMeQuery();
  const [setActiveCompany, { isSuccess }] = useSwitchCompanyMutation();

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  useEffect(() => {
    const companyExists = currentUser?.data?.userRoleInCompany.some(
      (item) => item.id === +localStorage.getItem('active-company-id')!
    );
    if (!companyExists) {
      localStorage.removeItem('active-company-id');
    }
  }, [currentUser]);

  return {
    currentUser,
    setActiveCompany,
  };
};

export default useDrawerCompaniesHooks;
