import React from 'react';
import { useGeneralHooks } from 'Pages/general.hooks';
import Button from 'Components/Button/button';
import { ButtonTypes } from 'Components/interfaces';
import styles from '../../../authForm.module.scss';
import { ICreateCompanyProps } from '../../createCompanyForm';
import FirstForm from './firstForm';

const CreateFirstCompanyScreen: React.FC<ICreateCompanyProps> = (props) => {
  const { setNext, register, control, watch, isValid, errors } = props;
  const { t } = useGeneralHooks();
  
  const setNextPage = () => {
    setNext && setNext(true)
  };

  return (
    <div style={{ width: "100%" }}>
      <form className={`${styles.form} ${styles.createCompanyForm}`} >
        <FirstForm
          register={register}
          control={control}
          watch={watch}
          errors={errors}
        />
        <Button
          disable={isValid ? false : true}
          buttonType={isValid ? ButtonTypes.Primery : ButtonTypes.Disabled}
          title={t("CreateCompany.ButtonFirst")}
          onClick={setNextPage}
        />
      </form>
    </div>
  );
};

export default CreateFirstCompanyScreen;


