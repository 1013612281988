import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import styles from './rightSide.module.scss';

interface IProps {
  children: ReactElement;
}

const RightSide: React.FC<IProps> = (props) => {
  const { children } = props;
  const location = useLocation();

  return (
    <div className={styles.rightSide}>
      {(location.pathname.includes('createCompany') ||
        location.pathname.includes('invite')) && (
        <Link
          to={'/main/userProfile'}
          className={styles['skip']}
        >
          {t('CreateCompany.Skip')}
        </Link>
      )}
      <div
        className={
          location.pathname.includes('createCompany') ||
          location.pathname.includes('invite')
            ? styles.containerWithSkip
            : styles.container
        }
      >
        {children}
      </div>
    </div>
  );
};

export default RightSide;
