import { t } from "i18next";
import styles from "./groupDetails.module.scss";

interface IProps {
  name: string | undefined;
  teamLeads: any;
  groupMembersCount: number | undefined;
}

const GroupDetails: React.FC<IProps> = (props) => {
  const { name, teamLeads, groupMembersCount } = props;
  return (
    <div className={styles["information"]}>
      <div className={styles["info-item"]}>
        <span className={styles["info-label"]}>{t("Groups.Group_Name")}</span>
        <span className={styles["info-value"]}>{name}</span>
      </div>
      {/* <div className={styles["info-item"]}>
        <span className={styles["info-label"]}>{t("Groups.Teamlead")}</span>
        <span className={styles["info-value"]}>{teamLeads}</span>
      </div> */}
      <div className={styles["info-item"]}>
        <span className={styles["info-label"]}>{t("Groups.Team_Members")}</span>
        <span className={styles["info-value"]}>{groupMembersCount}</span>
      </div>
    </div>
  );
};

export default GroupDetails;
