import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';
import {
  IEmployeeResp,
  IEmployeedResp,
  IPaginationQuery,
  IEmployeesFullList,
  IUserRespById,
  IAllUsersResp,
  IGetEmployeeAppHistoryData,
  IEmployeeAppHistoryResp,
} from './responce_types';
import { FieldValues } from 'react-hook-form';
import { IMakeEmployeeFields } from 'Pages/interfaces';

export const employeesApi = createApi({
  reducerPath: 'employeesApi',
  tagTypes: ['Employees'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getEmployees: builder.query<IEmployeedResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/employee/${page}/${offset}`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    getEmployeById: builder.query<IEmployeeResp, string>({
      query: (id) => ({
        url: `/employee/${id}`,
        method: 'GET',
      }),
      providesTags: ['Employees']
    }),
    getEmployeesFullList: builder.query<IEmployeesFullList, void>({
      query: () => ({
        url: `/employee/full_list`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    // any must be changed
    deleteEmployee: builder.mutation<any, number>({
      query: (id) => ({
        url: `/employee/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employees'],
    }),
    getAllUsers: builder.query<IAllUsersResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/users/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    getUserById: builder.query<IUserRespById, number | string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    // any must be changed
    makeEmployee: builder.mutation<any, IMakeEmployeeFields | FieldValues>({
      query: (data: any) => ({
        url: '/employee/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Employees']
    }),
    getEmployeeAppHistory: builder.query<IEmployeeAppHistoryResp,IGetEmployeeAppHistoryData >({
      query: ({id,limit,data}) => ({
        url: `/users/app_history/${id}/${limit.offset}/${limit.page}`,
        method: 'GET',
        params: data
      }),
      providesTags: ['Employees'],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeByIdQuery,
  useGetUserByIdQuery,
  useGetAllUsersQuery,
  useGetEmployeesFullListQuery,
  useDeleteEmployeeMutation,
  useMakeEmployeeMutation,
  useGetEmployeeAppHistoryQuery
} = employeesApi;
