import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import styles from './termsAndConditions.module.scss';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface IProps {
  cb: (val: boolean) => void;
  checked: boolean;
}

const TermsAndConditions: React.FC<IProps> = ({ cb, checked }) => {
  return (
    <div className={styles.termsAndConditionsCont}>
      <Checkbox
        {...label}
        defaultChecked
        sx={{
          color: grey[800],
          '&.Mui-checked': {
            color: grey[600],
          },
        }}
        onChange={() => cb(!checked)}
        checked={checked}
        value={checked}
      />
      <div className={styles.description}>
        {t('Terms_and_Conditions.first')}
        <Link to={'/'} className={styles.link}>
          {t('Terms_and_Conditions.second')}
        </Link>
        {t('Terms_and_Conditions.third')}
        <Link to={'/'} className={styles.link}>
          {t('Terms_and_Conditions.forth')}
        </Link>
      </div>
    </div>
  );
};

export default TermsAndConditions;
