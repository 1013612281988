import { useMemo, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useGetEmployeeAppHistoryQuery } from "Services/employeesApi";
import { useGetAppStatusesQuery } from "Services/generalApi";
import { IEmployeeAppHistoryFilterData, IResponseDataItem, IStatus } from "Services/responce_types";
import { useGeneralHooks } from "Pages/general.hooks";
import { EmployeeHistoryFilterFormValues } from "Components/interfaces";
import EmployeeHistoryItem from "../EmployeeHistoryItem/employeeHistoryItem";

const defaultFilterData: IEmployeeAppHistoryFilterData = {
    filter: {
        from: "",
        to: "",
        type: "",
        status: ""
    }
};

const useEmployeeHistoryHooks = (employeeId: number) => {
    const { t } = useGeneralHooks();
    const [filterData, setFilterData] = useState<IEmployeeAppHistoryFilterData>(defaultFilterData);
    const [activePage, setActivePage] = useState<number>(1);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const { data: Statuses } = useGetAppStatusesQuery();
    const statusOptions = Statuses?.result
    const renderStatusOptions = (): IResponseDataItem[] => {
        const options = statusOptions?.map((option: IStatus) => {
            return {
                id: option.id,
                name: t(`Statuses_From_Server.${option.name}`)
            }
        })
        return options as IResponseDataItem[]
    };
    const { register, handleSubmit, reset, control, } = useForm<EmployeeHistoryFilterFormValues | FieldValues>({
        defaultValues: defaultFilterData.filter
    });
    const { data: AppHistory, isLoading } =
        useGetEmployeeAppHistoryQuery({
            id: employeeId,
            limit: {
                page: activePage,
                offset: 10
            },
            data: filterData
        });

    const toggleFilter = () => {
        if (isFilter) {
            reset(defaultFilterData.filter)
            setFilterData(defaultFilterData)
        }
        setIsFilter(!isFilter)
    };

    const onSubmit: SubmitHandler<EmployeeHistoryFilterFormValues | FieldValues> = (
        values: EmployeeHistoryFilterFormValues | FieldValues
    ) => {
        const data: IEmployeeAppHistoryFilterData = {
            filter: {
                from: values.from,
                to: values.to,
                type: values.type.id,
                status: values.status.id
            }
        }
        setFilterData(data)
    };

    const appTypeOptions: IResponseDataItem[] = [{
        id: "vacation",
        name: t("Aplication_Page.Tab_Labels.Vacation")
    },
    {
        id: "absence",
        name: t("Aplication_Page.Tab_Labels.Absence")
    },
    {
        id: "pension",
        name: t("Aplication_Page.Tab_Labels.Pension")
    },
    {
        id: "release",
        name: t("Aplication_Page.Tab_Labels.Release")
    },
    {
        id: "Reference",
        name: t("Aplication_Page.Tab_Labels.Reference")
    },
    ]

    const LARGE_SCREEN_INNER = useMemo(() => {
        return {
            tabHeader: [
                t('Employee_History.App_Type'),
                t('Employee_History.Created_At'),
                t('Employee_History.Status'),
            ],
            tabBody: <EmployeeHistoryItem data={AppHistory ? AppHistory?.result : []} />
        }
    }, [AppHistory]);

    return {
        LARGE_SCREEN_INNER,
        activePage,
        isLoading,
        appTypeOptions,
        control,
        isFilter,
        setActivePage,
        toggleFilter,
        register,
        handleSubmit,
        onSubmit,
        renderStatusOptions
    }
};

export default useEmployeeHistoryHooks