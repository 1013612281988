import { HeaderTypes } from "Components/interfaces";
import styles from "./header.module.scss";

const useHeaderHooks = () => {
    const AUTH_HEADER_STYLES = {
        [HeaderTypes.Navigation]: {
            container: `${styles.container} ${styles.primeryContainer}`
        },
        [HeaderTypes.Logo]: {
            container: `${styles.container}`
        },
    };
    return {
        AUTH_HEADER_STYLES
    }
};

export default useHeaderHooks