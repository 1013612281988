import React from "react";
import { t } from "i18next";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import useAddMemberInGroupHooks from "./addmemberToGroup.hooks";
import { ButtonTypes, HeaderTypes } from "Components/interfaces";
import { SearchInput } from "Components/AuthComponents/AuthInputs";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { EmployeeItem, Header } from "Components";
import Button from "Components/Button/button";
import employeeProfile from "../EmployeeProfile/employeeProfile.module.scss";
import styles from "./addMember.module.scss";
import CustomPagination from "Components/Pagination/pagination";
import NoData from "Components/NoData/noData";

const AddMemberToGroup: React.FC = () => {
  const { pathsToRender } = useAppPathsHooks();
  const {
    addMembersReq,
    checkMembers,
    largeScreen,
    navigate,
    membersToJoinTheGroup,
    page,
    setPage,
  } = useAddMemberInGroupHooks();  

  return (
    <div className={employeeProfile.container}>
      {largeScreen ? (
        <div className={employeeProfile.headerContainer}>
          <div className={employeeProfile.breadCrumbBox}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
          <div className={styles.heading}>
            <p className={styles.title}>{t("Groups.Add_Member")}</p>
            <Button
              title={t("Groups.Choose_Button")}
              buttonStyle={styles.chooseBtn}
              buttonType={ButtonTypes.Primery}
              onClick={addMembersReq}
            />
          </div>
        </div>
      ) : (
        <div className={employeeProfile.mobileBox}>
          <Header
            type={HeaderTypes.Navigation}
            navTitle={
              window.innerWidth < 375
                ? t("Groups.Add_Member_Mobile")
                : t("Groups.Add_Member")
            }
            iconOnClick={() => navigate(-1)}
          />
        </div>
      )}
      {membersToJoinTheGroup?.count! > 0 ? (
        <>
          <div className={styles["employee-list__cont"]}>
            {membersToJoinTheGroup?.result?.map((item: any) => {
              return (
                <div>
                  <EmployeeItem
                    // role={item.role.name}
                    src={item.user.avatar}
                    typeChecked={true}
                    key={item.id}
                    name={`${item?.user?.name} ${item?.user?.surname}`}
                    onClick={() => checkMembers(item.userId)}
                  />
                </div>
              );
            })}
          </div>

          {+membersToJoinTheGroup?.count! > 12 && (
            <CustomPagination
              count={Math.ceil(+membersToJoinTheGroup?.count! / 12)}
              page={page}
              onChange={(_, page) => {
                setPage(page);
                window.scrollTo(0, 0);
              }}
            />
          )}
        </>
      ) : (
        <NoData withButton={false} title={t("Toastify_error.noEmployee")} />
      )}
      {!largeScreen ? (
        <div className={styles.buttonContent}>
          <Button
            title={t("Groups.Choose_Button")}
            buttonStyle={styles.chooseBtn}
            buttonType={ButtonTypes.Primery}
            onClick={addMembersReq}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AddMemberToGroup;
