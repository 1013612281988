import { Header } from 'Components';
import { HeaderTypes } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import MyInvitationsList from '../../Components/MyInvitations/myInvitationsList';
import styles from './myInvitations.module.scss';

const MyInvitations: React.FC = () => {
  const { largeScreen, navigate, t } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();

  return (
    <div className={styles['my-invitations-page']}>
      {!largeScreen && (
        <Header
          type={HeaderTypes.Navigation}
          navTitle={
            window.innerWidth > 320
              ? t('My_Invitations.Title')
              : t('My_Invitations.Title_Short')
          }
          iconOnClick={() => navigate(-1)}
        />
      )}
      {largeScreen && (
        <div>
          <BreadCrumbs data={pathsToRender} />
          <div className={styles['my-invitations-page__title']}>
            {t('My_Invitations.Title')}
          </div>
        </div>
      )}
      <MyInvitationsList />
    </div>
  );
};

export default MyInvitations;
