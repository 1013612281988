import { t } from "i18next";
import defaultImg from "../../Assets/Images/Employee.png";
import styles from "./notification.module.scss";

interface IProps {
  img?: string;
  name: string;
  date: string;
  read: number;
  changedStatus: string;
  statusColor: string;
  onClick?: () => void
}

const NotificationItem: React.FC<IProps> = (props) => {
  const { img, name, date, changedStatus, statusColor, onClick, read } = props;
  return (
    <div className={read ? styles["notification-item-read"] : styles["notification-item-unread"]} onClick={onClick} >
      <img className={styles["img"]} src={img ? `${process.env.REACT_APP_API_KEY}${img}` : defaultImg}
          alt="notification-img"/>
      <div className={styles["content"]}>
        <div className={styles["first-row"]}>
          <span className={styles["name"]}>{name}</span>
          <span className={styles["date"]}>{date}</span>
        </div>
        <div className={styles["second-row"]}>
          {t("Notifications.Changed_Status_To")} <span className={styles["changedStatus-text"]} style={{color: statusColor}}>{changedStatus}</span>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
