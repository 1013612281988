import { FieldValues } from 'react-hook-form';
import { createApi } from '@reduxjs/toolkit/query/react';
import { VacationFormValues } from 'Components/interfaces';
import axiosBaseQuery from '../API';
import {
  IAbsenceByIdResp,
  IAbsenseResp,
  IAvailableStatusesResp,
  IChangeStatusData,
  IPaginationQuery,
} from '../responce_types';

export const absenceApi = createApi({
  reducerPath: 'absenceApi',
  tagTypes: ['Absence'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getAbsenceList: builder.query<IAbsenseResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/absence/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Absence'],
    }),
    getUserAbsenceList: builder.query<IAbsenseResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/absence/my_list/${offset}/${page}`,
        method: "GET",
      }),
      providesTags: ["Absence"],
    }),
    getAbsenceById: builder.query<IAbsenceByIdResp, number>({
      query: (id) => ({
        url: `/absence/${id}`,
        method: 'GET',
      }),
      providesTags: ["Absence"],
    }),
    createAbsence: builder.mutation<number, VacationFormValues | FieldValues>({
      query: (absenceData) => ({
        url: '/absence',
        method: 'POST',
        data: absenceData,
      }),
      invalidatesTags: ['Absence'],
    }),
    getAbsenceAvailableStatuses: builder.query<IAvailableStatusesResp, number>({
      query: (id) => ({
        url: `/absence/available_statuses/${id}`,
        method: "GET",
      }),
    }),
    changeAbsenceStatus: builder.mutation<number, IChangeStatusData>({
      query: (data) => ({
        url: `/absence/update_status/${data?.id}`,
        method: "PUT",
        data: {
          description: data.description,
          status_from_id: data.status_from_id,
          status_to_id: data.status_to_id,
          userId: data?.userId
        },
      }),
      invalidatesTags: ["Absence"],
    }),
  }),
});

export const {
  useGetAbsenceListQuery,
  useGetAbsenceAvailableStatusesQuery,
  useChangeAbsenceStatusMutation,
  useGetUserAbsenceListQuery,
  useCreateAbsenceMutation,
  useGetAbsenceByIdQuery,
} = absenceApi;
