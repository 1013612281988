import React from 'react';
import { useGeneralHooks } from 'Pages/general.hooks';
import Button from 'Components/Button/button';
import { ButtonTypes } from 'Components/interfaces';
import { ICreateCompanyProps } from '../../createCompanyForm';
import SecondForm from './secondForm';
import styles from '../../../authForm.module.scss';

const CreateCompanySecondScreen: React.FC<ICreateCompanyProps> = (props) => {
  const { onSubmit, control, watch, errors, isValid, isLoading, setNext} = props;
  const { t } = useGeneralHooks();

  return (
    <div style={{ width: "100%" }}>
      <form className={styles.form}>
        <SecondForm
          control={control}
          watch={watch}
          errors={errors}
        />
        <div className={styles.companyButtons}>
          <Button
            title={t('Aplication_Page.Button')}
            type='button'
            buttonType={ButtonTypes.WithoutBg}
            onClick={() => {setNext && setNext(false)}} />
          <Button
            title={t('CreateCompany.ButtonSecond')}
            type='submit'
            onClick={onSubmit}
            disable={isValid ? false : true}
            buttonType={isValid ? ButtonTypes.Primery : ButtonTypes.Disabled}
            isLoading={isLoading}
          />
        </div>

      </form>
    </div>
  );
};

export default CreateCompanySecondScreen;


