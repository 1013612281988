import { FieldValues, UseFormRegister } from 'react-hook-form';

export interface CreateCompanyFormValues {
  name: string;
  address: string;
  oltId: string;
  cityId: string;
  sphereOfActivityId: string;
  typeOfTaxId: string;
  tin: string;
  contactPerson?: null;
  contactPersonEmail?: null;
  contactPersonPhone?: null;
  avatar?: Object | null;
  roleId: string;
}

export interface IForgetPassword {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ILoginRespData {
  email: string;
  password: string;
  id: number;
  name: string;
  surname: string;
  avatar: string | null;
  birthDate: string | null;
  emailValidationToken: string | null;
  emailVerified: boolean;
  forgetPasswordValidationToken: string | null;
  gender: string | null;
  number: string | null;
  socialSecurityCard: string | null;
  token: string;
}

export interface LoginFormValues {
  result: ILoginRespData;
  error: null | string;
  email: string;
  password: string;
}

export type RegisterFormValues = {
  name: string;
  surname: string;
  email: string;
  password: string;
};
export interface RegisterResponse {
  error: null | string;
  result: null | string;
}

export interface SearchInputValues {
  search: string;
}

export type InputRegisterTypes = UseFormRegister<
  FieldValues | LoginFormValues | SearchInputValues
>;

export interface ICreateGroupForm {
  name: string;
}

export interface IMekumToken {
  mekum_token: string;
}

export interface IVacationStatusChangeValues {
  status_id: number;
  description: string;
}

export interface IMakeEmployeeFields {
  document_type_id: string;
  passport_number: string;
  date_of_issue: string;
  authority: string;
}

export enum AppScreenType {
  HISTORY = 'history',
  FORM = 'form'
}
