import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  connectMekumUser: any;
}

const initialState: IInitState = {
  connectMekumUser: [],
};

const mekumSlice = createSlice({
  name: "keepMekumUser",
  initialState,
  reducers: {
    keepMekumUser(state, action: PayloadAction<any>) {
      state.connectMekumUser = action.payload;
    },
  },
});

export const { keepMekumUser } = mekumSlice.actions;
export default mekumSlice.reducer;
