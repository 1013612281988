import React from 'react';
import useUserImageHooks from './userImage.hooks';
import EmployeeImage from 'Assets/Images/Employee.png';
import Camera from "Assets/Images/Camera.png";
import styles from './mediaPicker.module.scss';

interface IUserImagePickerProps {
  id?: string,
  image?: string | null
}
const UserImageUpload: React.FC<IUserImagePickerProps> = (props) => {
  const { id, image } = props;
  const { handleFileInputChange, selectedImage } = useUserImageHooks();

  return (
    <>
      <div className={styles.uploadUserImgContainer}>
        <input
          id={id}
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          style={{ display: "none" }}
        />
        <div className={styles.userImageBox}>
          <label htmlFor={id}>
            {image ?
              <div className={styles.userImage}>
                <img src={!selectedImage ? `${process.env.REACT_APP_API_KEY}${image}` : `${selectedImage}`} className={styles.userImage} alt="userAvatar" />
              </div>
              :
              <div
                className={styles.userImage}
                style={{
                  backgroundImage: selectedImage
                    ? `url("${selectedImage}")`
                    :
                    `url("${EmployeeImage}")`,
                }}
              />
            }

            <div className={styles.iconDiv}>
              <div className={styles.cameradiv}>
                <img src={Camera} alt="Camera" />
              </div>
            </div>
          </label>
        </div>
      </div>
    </>
  )
}

export default UserImageUpload
