import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';

export interface IBreadCrumbProps {
  data: string[],
  innerElement?: string
}
const BreadCrumbs: React.FC<IBreadCrumbProps> = (props) => {
  const { data } = props;

  return (
    <div className='breadCrumbContainer'>
      <Breadcrumbs aria-label="breadcrumb">
        {data?.map((path: string, index: number) => {
          return (index < data.length - 1 ?
            <div key={index}>{path}</div>
            :
            <Typography key={index}>{path}</Typography>
          )
        })}
      </Breadcrumbs>
    </div>
  )
};

export default BreadCrumbs