import React, { useMemo, useState } from 'react';
import { t } from 'i18next';
import { ApplicationTypes, HistoryTypes } from 'Components/interfaces';
import { useGetAbsenceListQuery, useGetUserAbsenceListQuery } from 'Services/Applications/absenceApi';
import { useGetPensionsListQuery, useGetUserPensionListQuery } from 'Services/Applications/pensionApi';
import {
  useGetUserVacationListQuery,
  useGetVacationListQuery,
} from 'Services/Applications/vacationApi';
import { useGetDismissalListQuery, useGetUserDismissalListQuery } from 'Services/Applications/dismissalApi';
import { useGetReferenceListQuery, useGetUserReferenceListQuery } from 'Services/Applications/referenceApi';
import DismissalHistoryItem from './HistoryItems/dismissalHistoryItem';
import ReferenceHistoryItem from './HistoryItems/referenceHistoryItem';
import VacationHistoryItem from './HistoryItems/vacationHistoryItem';
import PensionHistoryItem from './HistoryItems/pensionHistoryItem';

const useHistory = () => {
  const [activePage, setActivePage] = useState<number>(1);

  const { data: VacationList, isLoading: vacationListIsLoading } =
    useGetVacationListQuery({
      page: activePage,
      offset: 10,
    });

  const { data: UserVacationList, isLoading: userVacationListISLoading } =
    useGetUserVacationListQuery({
      page: activePage,
      offset: 10,
    });

  const { data: AbsenceList, isLoading: absenceListIsLoading } =
    useGetAbsenceListQuery({
      page: activePage,
      offset: 10,
    });
  const { data: UserAbsenceList, isLoading: userAbsenceListISLoading } =
    useGetUserAbsenceListQuery({
      page: activePage,
      offset: 10
    })
    const { data: PensionList, isLoading: pensionListIsLoading } =
    useGetPensionsListQuery({
      page: activePage,
      offset: 10,
    });


  const { data: ReferenceList, isLoading: referenceListIsLoading } =
    useGetReferenceListQuery({
      page: activePage,
      offset: 10,
    });

    const { data: UserReferenceList, isLoading: userReferenceListIsLoading } =
    useGetUserReferenceListQuery({
      page: activePage,
      offset: 10,
    });

  const { data: DismissalList, isLoading: dismissalListIsLoading } =
    useGetDismissalListQuery({
      page: activePage,
      offset: 10,
    });

    const { data: UserDismissalList, isLoading: userDismissalListIsLoading } =
    useGetUserDismissalListQuery({
      page: activePage,
      offset: 10,
    });

    const { data: UserPensionList, isLoading: userPensionListISLoading } =
    useGetUserPensionListQuery({
      page: activePage,
      offset: 10
    })

  const RESPONSIVE_ITEM_DATA = {
    vacation: {
      [ApplicationTypes.Company_Applications]: VacationList,
      [ApplicationTypes.User_Applications]: UserVacationList,
    },
    absence: {
      [ApplicationTypes.Company_Applications]: AbsenceList,
      [ApplicationTypes.User_Applications]: UserAbsenceList,
    },
    dismissal: {
      [ApplicationTypes.Company_Applications]: DismissalList,
      [ApplicationTypes.User_Applications]: UserDismissalList,
    },
    pension: {
      [ApplicationTypes.Company_Applications]: PensionList,
      [ApplicationTypes.User_Applications]: UserPensionList,
    },
    reference: {
      [ApplicationTypes.Company_Applications]: ReferenceList,
      [ApplicationTypes.User_Applications]: UserReferenceList,
    },
  };  

  const HISTORY_INNER = useMemo(() => {
    return {
      [HistoryTypes.Vacation]: {
        tabHeader: [
          t('Aplication_Page.AppHistory.Name'),
          t('Aplication_Page.AppHistory.Qount'),
          t('Aplication_Page.AppHistory.From'),
          t('Aplication_Page.AppHistory.To'),
          t('Aplication_Page.AppHistory.Status'),
        ],
        tabBody: {
          [ApplicationTypes.Company_Applications]: (
            <VacationHistoryItem
              data={VacationList ? VacationList?.result : []}
            />
          ),
          [ApplicationTypes.User_Applications]: (
            <VacationHistoryItem
              data={UserVacationList ? UserVacationList?.result : []}
            />
          ),
        },
        count: {
          [ApplicationTypes.Company_Applications]:
            VacationList && VacationList?.count,
          [ApplicationTypes.User_Applications]:
            UserVacationList && UserVacationList?.count,
        },
        isloading: {
          [ApplicationTypes.Company_Applications]: vacationListIsLoading,
          [ApplicationTypes.User_Applications]: userVacationListISLoading,
        },
      },
      [HistoryTypes.Absence]: {
        tabHeader: [
          t('Aplication_Page.AppHistory.Name'),
          t('Aplication_Page.AppHistory.Qount'),
          t('Aplication_Page.AppHistory.From'),
          t('Aplication_Page.AppHistory.To'),
          t('Aplication_Page.AppHistory.Status'),
        ],
        tabBody: {
          [ApplicationTypes.Company_Applications]: (
            <VacationHistoryItem data={AbsenceList ? AbsenceList.result : []} />
          ),
          [ApplicationTypes.User_Applications]: (
            <VacationHistoryItem data={UserAbsenceList ? UserAbsenceList?.result : []} />
          ),
        },
        count: {
          [ApplicationTypes.Company_Applications]:
            AbsenceList && AbsenceList?.count,
          [ApplicationTypes.User_Applications]:
            UserAbsenceList && UserAbsenceList?.count,
        },
        isloading: {
          [ApplicationTypes.Company_Applications]: absenceListIsLoading,
          [ApplicationTypes.User_Applications]: userAbsenceListISLoading,
        },
      },
      [HistoryTypes.Pension]: {
        tabHeader: [
          t('Aplication_Page.AppHistory.Name'),
          t('Aplication_Page.AppHistory.From'),
          t('Aplication_Page.AppHistory.To'),
          t('Aplication_Page.AppHistory.Status'),
        ],
        tabBody: {
          [ApplicationTypes.Company_Applications]: (
            <PensionHistoryItem data={PensionList ? PensionList.result : []} />
          ),
          [ApplicationTypes.User_Applications]: (
            <PensionHistoryItem data={UserPensionList ? UserPensionList.result : []} />
          ),
        },
        count: {
          [ApplicationTypes.Company_Applications]:
            PensionList && PensionList?.count,
          [ApplicationTypes.User_Applications]:
            UserPensionList && UserPensionList?.count,
        },
        isloading: {
          [ApplicationTypes.Company_Applications]: pensionListIsLoading,
          [ApplicationTypes.User_Applications]: userPensionListISLoading,
        },
      },
      [HistoryTypes.Dismissal]: {
        tabHeader: [
          t('Aplication_Page.AppHistory.Name'),
          t('Aplication_Page.AppHistory.Date'),
          t('Aplication_Page.AppHistory.Status'),
        ],
        tabBody: {
          [ApplicationTypes.Company_Applications]: (
            <DismissalHistoryItem
              data={DismissalList ? DismissalList.result : []}
            />
          ),
          [ApplicationTypes.User_Applications]: (
            <DismissalHistoryItem
              data={UserDismissalList ? UserDismissalList.result : []}
            />
          ),
        },
        count: {
          [ApplicationTypes.Company_Applications]:
            DismissalList && DismissalList?.count,
          [ApplicationTypes.User_Applications]:
          UserDismissalList && UserDismissalList?.count,
        },
        isloading: {
          [ApplicationTypes.Company_Applications]: dismissalListIsLoading,
          [ApplicationTypes.User_Applications]: userDismissalListIsLoading,
        },
      },
      [HistoryTypes.Reference]: {
        tabHeader: [
          t('Aplication_Page.AppHistory.Place'),
          t('Aplication_Page.AppHistory.Date'),
          t('Aplication_Page.AppHistory.Status'),
        ],
        tabBody: {
          [ApplicationTypes.Company_Applications]: (
            <ReferenceHistoryItem
              data={ReferenceList && ReferenceList?.result}
            />
          ),
          [ApplicationTypes.User_Applications]: (
            <ReferenceHistoryItem
              data={UserReferenceList && UserReferenceList?.result}
            />
          ),
        },
        count: {
          [ApplicationTypes.Company_Applications]:
            ReferenceList && ReferenceList?.count,
          [ApplicationTypes.User_Applications]:
            UserReferenceList && UserReferenceList?.count,
        },
        isloading: {
          [ApplicationTypes.Company_Applications]: referenceListIsLoading,
          [ApplicationTypes.User_Applications]: userReferenceListIsLoading,
        },
      },
    };
  }, [
    ReferenceList,
    AbsenceList,
    DismissalList,
    PensionList,
    VacationList,
    UserVacationList,
    UserAbsenceList,
    UserPensionList,
    UserDismissalList,
    UserReferenceList
  ]);

  return {
    HISTORY_INNER,
    RESPONSIVE_ITEM_DATA,
    activePage,
    setActivePage,
  };
};

export default useHistory;
