import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IPensionData } from 'Components/interfaces';
import { useGetEmployeesFullListQuery } from 'Services/employeesApi';
import { useGetMeQuery } from 'Services/authApi';
import { toast } from 'react-toastify';
import { useCreatePensionMutation } from 'Services/Applications/pensionApi';
import { useUploadMultipleMutation } from 'Services/applicationsApi';
import useRoleHooks from 'Routers/role-hooks';

export const usePensionHooks = () => {
  const { t } = useTranslation();
  const minDate = new Date().toISOString().split('T')[0];
  const [showDataTable, setShowDataTable] = useState<boolean>(true);
  const [selectedFiles, setSelectedFiles] = useState<Array<any>>([]);
  const [createPension, { isSuccess, isLoading }] = useCreatePensionMutation();
  const [UploadFilesRequest, { data, isSuccess: filesUploadedSuccessfully }] =
    useUploadMultipleMutation();
  const { data: employees } = useGetEmployeesFullListQuery();
  const { data: currentUser } = useGetMeQuery();
  const { currentRole } = useRoleHooks();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm<IPensionData | FieldValues>({
    mode: 'all',
  });

  const deleteFile = (i: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(i, 1);
    setSelectedFiles(updatedFiles);
    setValue('files', updatedFiles);
  };

  useEffect(() => {
    if (filesUploadedSuccessfully && data?.result) {
      const files = [...selectedFiles, ...data.result];
      setSelectedFiles(files);
      setValue('files', files);
    }
  }, [filesUploadedSuccessfully]);

  useEffect(() => {
    if (currentUser && currentUser?.data?.employee) {
      setValue(
        'user_id',
        currentUser && currentUser?.data?.employee
          ? `${currentUser?.data.name} ${currentUser?.data.surname}`
          : undefined
      );
    }
  }, [currentUser]);

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files;
    const selected = [...selectedFiles];
    if (files) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`images`, files[i]);
        selected.push(files[i]);
      }
      // @ts-ignore
      UploadFilesRequest(formData);
    }
  };

  const onSubmit = (data: any) => {
    const pensionData = {
      user_id: data.user_id.id ? data.user_id.id : currentUser?.data?.id,
      start_date: data.start_date,
      end_date: data.end_date,
      files: data.files,
      company_id: currentUser?.data?.activeCompanyId,
    };
    // @ts-ignore
    createPension(pensionData);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowDataTable(true);
      toast.success(t('Aplication_Page.Successfully_Created'));
      reset();
    }
  }, [isSuccess]);

  return {
    t,
    currentRole,
    errors,
    minDate,
    control,
    isLoading,
    employees,
    currentUser,
    showDataTable,
    selectedFiles,
    setSelectedFiles,
    setShowDataTable,
    onChangeFile,
    handleSubmit,
    deleteFile,
    onSubmit,
    register,
    setValue,
    watch,
  };
};
