import React from "react";
import { Controller } from "react-hook-form";
import { InputRegisterTypes } from "Pages/interfaces";
import { useGeneralHooks } from "Pages/general.hooks";
import useCreateCompanyHooks from "Pages/CreateCompany/createCompany.hooks";
import { ICreateCompanyProps } from "../../createCompanyForm";
import MediaPicker from "Components/AuthComponents/MediaPicker/mediaPicker";
import { AutoComplete, InputField } from "Components/AuthComponents/AuthInputs";
import styles from "../../../authForm.module.scss";


const FirstForm: React.FC<ICreateCompanyProps> = (props) => {
  const { register, control, watch, errors } = props;
  const { t } = useGeneralHooks();
  const { Activities } = useCreateCompanyHooks();
  return (
    <div className={styles.firstForm}>
      <div className={styles.mediaPickerContainer}>
        <div className={styles.mediaPicker}>
          <Controller
            name='avatar'
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <MediaPicker
                  id='image'
                  onChange={onChange}
                />
              )
            }}
          />
        </div>
      </div>
      <InputField
        type="text"
        id="name"
        placeholder={t('CreateCompany.CompanyName')}
        label={t('CreateCompany.Company')}
        register={register as InputRegisterTypes}
        registerName="name"
        message={t('CreateCompany.CompanyName_Error')}
        error={errors.name}
      />
      <InputField
        type="text"
        id="tin"
        patternValue={/^\d{8}$/}
        placeholder={t('CreateCompany.AVC')}
        label={t('CreateCompany.AVC_label')}
        register={register as InputRegisterTypes}
        registerName="tin"
        message={t('Input_Errors.AVC')}
        error={errors.tin}
      />
      <InputField
        type="text"
        id="address"
        placeholder={t('CreateCompany.LegalAddress')}
        label={t('CreateCompany.Address_label')}
        register={register as InputRegisterTypes}
        registerName="address"
        message={t('CreateCompany.LegalAddress')}
        error={errors.address}
      />
      <Controller
        control={control}
        name="sphereOfActivityId"
        rules={{
          validate: () => watch("sphereOfActivityId") !== undefined || "Error",
        }}
        render={({ field: { onChange, name, value }, }) => {
          return (
            <AutoComplete
              value={value}
              name={name}
              onChange={onChange}
              id="activity"
              data={Activities?.data}
              label={t('CreateCompany.Activity_label')}
              placeholder={t('CreateCompany.FieldOfActivity')}
              error={errors.sphereOfActivityId}
            />
          )
        }}
      />
    </div>
  )
};

export default FirstForm