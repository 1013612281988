import { useState } from 'react';
import {
  useGetActivitiesQuery,
  useGetCitiesQuery,
  useGetOrganizationTypesQuery,
  useGetRolesQuery,
  useGetTaxTypesQuery,
} from 'Services/authApi';

const useCreateCompanyHooks = () => {
  const [next, setNext] = useState<boolean>(false);
  const { data: Activities } = useGetActivitiesQuery();
  const { data: TaxTypes } = useGetTaxTypesQuery();
  const { data: OrgTypes } = useGetOrganizationTypesQuery();
  const { data: Cities } = useGetCitiesQuery();
  const { data: RoleTypes } = useGetRolesQuery();

  return {
    next,
    setNext,
    Activities,
    TaxTypes,
    OrgTypes,
    Cities,
    RoleTypes,
  };
};

export default useCreateCompanyHooks;
