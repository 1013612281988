import React from 'react';
import styles from "../../authForm.module.scss";
import AuthFormTitle from '../../authFormTitle';
import { useGeneralHooks } from 'Pages/general.hooks';
import { ICreateCompanyProps } from '../createCompanyForm';
import FirstForm from '../Web/FirstScreen/firstForm';
import SecondForm from '../Web/SecondScreen/secondForm';
import Button from 'Components/Button/button';
import { ButtonTypes } from 'Components/interfaces';


const CreateCompanyMobileForm: React.FC<ICreateCompanyProps> = (props) => {
    const { onSubmit, register, control, errors, watch, isValid, isLoading } = props;
    const { t } = useGeneralHooks();

    return (
        <div className={styles.container} style={{marginTop:"150px"}} >
            <div className={styles.createCompanyContainer}>
                <AuthFormTitle
                    title={t("CreateCompany.Title")}
                />
                <form onSubmit={onSubmit} className={styles.form} style={{marginTop:"0px"}} >
                    <FirstForm
                        register={register}
                        control={control}
                        watch={watch}
                        errors={errors}
                    />
                    <SecondForm
                        control={control}
                        watch={watch}
                        errors={errors}
                    />
                    <Button
                        title={t('Next')}
                        type='submit'
                        onClick={onSubmit}
                        disable={isValid ? false : true}
                        buttonType={isValid ? ButtonTypes.Primery : ButtonTypes.Disabled}
                        isLoading={isLoading}
                    />
                </form>
            </div>
        </div>
    )
}

export default CreateCompanyMobileForm
