import React from 'react';
import styles from './status.module.scss';

interface ICardStatusProps {
  bgColor: string;
  text: string;
}

const HistoryItemStatus: React.FC<ICardStatusProps> = (props) => {
  const { bgColor, text } = props;

  return (
    <div style={{ backgroundColor: bgColor }} className={styles['container']}>
      {text}
    </div>
  );
};

export default HistoryItemStatus;
