import React from 'react';
import { UseFormRegister, FieldError, FieldValues } from "react-hook-form";
import deleteIcon from "Assets/Icons/delete_request.svg";
import { useBorder } from '../AuthInputs/authInput-hooks';
import AuthError from '../AuthError/authError';
import { InviteError, RequestError, RequestFormValues } from 'Components/interfaces';
import { IResponseDataItem } from 'Services/responce_types';
import { useGeneralHooks } from 'Pages/general.hooks';
import styles from "./inviteRequest.module.scss";

interface IProps {
  emailValue: string,
  roleValue: string,
  register: UseFormRegister<FieldValues | RequestFormValues>,
  emailError: RequestError | FieldError | undefined,
  roleError: RequestError | FieldError | undefined,
  iconOnClick: () => void,
  roles: IResponseDataItem[] | undefined
};

const InviteInput: React.FC<IProps> = (props) => {
  const { emailValue, roleValue, register, emailError, roleError, iconOnClick, roles } = props;
  const {t} = useGeneralHooks();
  const { border, removeBorder, renderBorder } = useBorder();
  return (
    <div>
      <div
        className={border ? `${styles.container} ${styles.border}` : `${styles.container}`}
        onClick={renderBorder}
        onBlur={removeBorder}
      >
        <div className={styles.inputBox}>
          <div className={styles.inputField}>
            <input
              className={styles.input}
              type='text'
              placeholder={window.innerWidth > 768 ? t("Invite_Request.Input_Placeholder"):t("Invite_Request.Input_2_Placeholder") }
              {...register(emailValue, {
                required: t("Input_Errors.Required"),
                pattern: {
                  value: /^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i,
                  message: t("Input_Errors.Email")
                }
              })}
            />
          </div>
          <div className={styles.selectBox}>
            <select
              defaultValue={'role'}
              {...register(roleValue, {
                required: t("Input_Errors.Required"),
                validate: (value) => value !== "role" || t("Input_Errors.Required"),
              })}  >
              <option id={'role'}value="role" disabled hidden >{t("Invite_Request.Role")}</option>
              {roles?.map((role: IResponseDataItem, index: number) => {
                return (
                  <option key={index} value={role.id}>{role.name}</option>
                )
              })}
            </select>
            <div className={styles.icon} onClick={iconOnClick} >
              <img src={deleteIcon} alt='remove' />
            </div>
          </div>

        </div>
      </div>
      <div className={styles.error}>
        <AuthError text={emailError && (emailError as InviteError).message} />
        <AuthError text={roleError && (roleError as InviteError).message} />
      </div>
    </div>
  )
}

export default InviteInput