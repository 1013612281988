import { useCallback, useState } from "react"

const useMobileNavDrawerHooks = ()=>{
    const [open,setOpen] = useState<boolean>(false);

    const onDrawerClose = useCallback(()=>{
     open && setOpen(false)
    },[open]);
    return {
        open,
        setOpen,
        onDrawerClose
    }
};

export default useMobileNavDrawerHooks