import { useEffect, useState } from "react";
import { IChangePasswordData } from "Components/AuthComponents/AuthForms/ChangePassword/changePasswordForm";
import { IEditUserInfoProps } from "Components/AuthComponents/AuthForms/EditUserInfo/editUserInfoForm";
import { IChangeUserInfoData, UserInfoFormValues, UserProfilePages } from "Components/interfaces";
import { useChangePasswordMutation, useChangeUserDataMutation, useGetMeQuery } from "Services/authApi";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useGeneralHooks } from "Pages/general.hooks";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const useUserProfileHooks = () => {
    const { t, largeScreen } = useGeneralHooks();
    const { data: UserData, isLoading } = useGetMeQuery();
    const [edit, { isSuccess, isLoading: editLoading }] = useChangeUserDataMutation();
    const [changePassword, { isSuccess: isSuccessPassword, isLoading: changePasswordLoading }] = useChangePasswordMutation()
    const [currentPage, setCurrentPage] = useState<UserProfilePages>(UserProfilePages.Main);
    const { register, handleSubmit, formState: { errors }, watch } = useForm<UserInfoFormValues | FieldValues>({ mode: "all" });
    const navigate = useNavigate()
    
    const onEditSubmit: SubmitHandler<UserInfoFormValues | FieldValues> = async (values) => {
        try {
            const payload: IChangeUserInfoData = {
                data: {
                    name: values.name,
                    surname: values.surname
                },
                id: UserData?.data.id as number
            }
            await edit(payload)
        } catch (error) {
            console.log(error);
        }
    };
    const onChangePasswordSubmit: SubmitHandler<UserInfoFormValues | FieldValues> = async (values) => {
        delete values.confirmPassword
        changePassword(values)
    };
    const onChangeEmail:SubmitHandler<UserInfoFormValues | FieldValues>  = (values)=>{
        console.log(values.email)
    }
    useEffect(() => {
        if (isSuccessPassword) {            
            toast.success(t('Change_Password.Password_Changed_Successfully'));
            localStorage.removeItem("access-token")
            navigate('/')
        }
    }, [isSuccessPassword])

    const editFormProps: IEditUserInfoProps = {
        name: UserData?.data.name as string,
        surname: UserData?.data.surname as string,
        email: UserData?.data.email as string,
        errors: errors,
        onChangeEmail: onChangeEmail,
        handleSubmit: handleSubmit,
        register: register,
        registerEmail: 'email',
        registerForName: "name",
        registerForSurname: "surname"
    };

    const changePasswordProps: IChangePasswordData = {
        watch: watch,
        errors: errors,
        register: register,
        confirmPassword: 'confirmPassword',
        password: "password",
        NewPassword: "newPassword"
    };

    return {
        t,
        watch,
        largeScreen,
        UserData,
        isSuccess,
        isLoading,
        editLoading,
        currentPage,
        setCurrentPage,
        handleSubmit,
        onEditSubmit,
        onChangePasswordSubmit,
        editFormProps,
        changePasswordProps,
        changePasswordLoading
    }
};

export default useUserProfileHooks