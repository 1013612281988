import React from 'react';
import useCreateCompany from 'Pages/CreateCompany/createCompany.hooks';
import { useGeneralHooks } from 'Pages/general.hooks';
import AuthFormTitle from '../../authFormTitle';
import CreateFirstCompanyScreen from './FirstScreen/createComanyFirstScreen';
import CreateCompanySecondScreen from './SecondScreen/createCompanySecondScreen';
import { ICreateCompanyProps } from '../createCompanyForm';
import styles from '../../authForm.module.scss';


const CreateCompanyWebForm: React.FC<ICreateCompanyProps> = (props) => {
    const { onSubmit, register, control, errors, watch, isValid, isLoading } = props;
    const { next, setNext } = useCreateCompany();
    const {t}  = useGeneralHooks();

    return (
        <div className={styles.container}>
            <div className={styles.createCompanyContainer}>
            <AuthFormTitle
                title={t("CreateCompany.Title")}
            />
            {next === false ?
                <CreateFirstCompanyScreen
                    control={control}
                    register={register}
                    setNext={setNext}
                    errors={errors}
                    isValid={isValid}
                    watch={watch}
                />
                :
                <CreateCompanySecondScreen
                    control={control}
                    errors={errors}
                    watch={watch}
                    setNext={setNext}
                    onSubmit={onSubmit}
                    isValid={isValid }
                    isLoading={isLoading}
                />
            }
            </div>
        </div>
    )
}

export default CreateCompanyWebForm
