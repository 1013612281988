import { InvitationsStatus } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import UseMyInvitationsHooks from './myInvitations.hooks';
import declineIcon from 'Assets/Icons/close-square.svg';
import acceptIcon from 'Assets/Icons/tick-square.svg';
import styles from './myInvitations.module.scss';

interface IProps {
  invitationId: number | undefined;
}

const InviteButtons: React.FC<IProps> = (props) => {
  const { acceptOrDeclineInvitationReq } = UseMyInvitationsHooks();
  const { t } = useGeneralHooks();

  return (
    <div className={styles['my-invitation-item__buttons']}>
      <div
        className={styles['button-container']}
        onClick={() =>
          acceptOrDeclineInvitationReq({
            invitationId: props.invitationId,
            status: InvitationsStatus.Accepted,
          })
        }
      >
        <img src={acceptIcon} alt='acceptIcon' />
        <span className={styles['accept-btn-text']}>
          {t('My_Invitations.Accept_Btn')}
        </span>
      </div>
      <div
        className={styles['button-container']}
        onClick={() =>
          acceptOrDeclineInvitationReq({
            invitationId: props.invitationId,
            status: InvitationsStatus.Rejected,
          })
        }
      >
        <img src={declineIcon} alt='declineIcon' />
        <span className={styles['decline-btn-text']}>
          {t('My_Invitations.Decline_Btn')}
        </span>
      </div>
    </div>
  );
};

export default InviteButtons;
