import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { referenceApi } from 'Services/Applications/referenceApi';
import { dismissalApi } from 'Services/Applications/dismissalApi';
import { vacationApi } from 'Services/Applications/vacationApi';
import { absenceApi } from 'Services/Applications/absenceApi';
import { pensionApi } from 'Services/Applications/pensionApi';
import { notificationsApi } from 'Services/notificationsApi';
import { applicationsApi } from 'Services/applicationsApi';
import { socialLoginApi } from 'Services/socialLoginApi';
import { companiesApi } from 'Services/companiesApi';
import { employeesApi } from 'Services/employeesApi';
import { configureStore } from '@reduxjs/toolkit';
import { generalApi } from 'Services/generalApi';
import { groupsApi } from 'Services/groupsApi';
import { mekumApi } from 'Services/mekumApi';
import mekumSlice from './Slices/mekumSlice';
import { authApi } from 'Services/authApi';

export const store = configureStore({
  reducer: {
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [applicationsApi.reducerPath]: applicationsApi.reducer,
    [socialLoginApi.reducerPath]: socialLoginApi.reducer,
    [employeesApi.reducerPath]: employeesApi.reducer,
    [referenceApi.reducerPath]: referenceApi.reducer,
    [dismissalApi.reducerPath]: dismissalApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [vacationApi.reducerPath]: vacationApi.reducer,
    [absenceApi.reducerPath]: absenceApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [pensionApi.reducerPath]: pensionApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [mekumApi.reducerPath]: mekumApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    mekum: mekumSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      notificationsApi.middleware,
      applicationsApi.middleware,
      socialLoginApi.middleware,
      referenceApi.middleware,
      employeesApi.middleware,
      dismissalApi.middleware,
      companiesApi.middleware,
      vacationApi.middleware,
      absenceApi.middleware,
      generalApi.middleware,
      pensionApi.middleware,
      groupsApi.middleware,
      mekumApi.middleware,
      authApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
