import React, { useEffect } from "react";
import RefernceProfideForm from "./refernceProfideForm";
import { useProvideReferenceHooks } from "./provideReference-hooks";
import AppHistory from "Components/Application/AppHistory/appHistory";
import { ApplicationTypes, HistoryTypes } from "Components/interfaces";
import { IApplicationComponentProps } from "../Vacation/vacationComponent";

const ProvideReference: React.FC<IApplicationComponentProps> = (props) => {
  const { ordered_by } = props;
  const {
    register,
    onSubmit,
    handleSubmit,
    setShowDataTable,
    showDataTable,
    errors,
    employeeList,
    control,
    currentRole,
  } = useProvideReferenceHooks();

  useEffect(() => {
    setShowDataTable(true)
  }, [ordered_by]);

  return (
    <>
    {
      ordered_by === ApplicationTypes.Company_Applications
      ?
      <AppHistory
      type={HistoryTypes.Reference}
      ordered_by={ordered_by}
      setShow={setShowDataTable}
    />
    :
    showDataTable ? (
      <AppHistory
        type={HistoryTypes.Reference}
        ordered_by={ordered_by}
        setShow={setShowDataTable}
      />
    ) : (
      <RefernceProfideForm
        register={register}
        onSubmit={handleSubmit(onSubmit)}
        setShowDataTable={setShowDataTable}
        errors={errors}
        employeeList={employeeList}
        control={control}
        role={currentRole}
      />
    )}
    </>
  );
};

export default ProvideReference;
