import styles from '../../AppHistory/Status/status.module.scss';

interface ICurrentStatusProps {
    onClick:()=>void,
    statusName:string,
    bgColor:string
}

const CurrentStatus: React.FC<ICurrentStatusProps> = (props) => {
    const {onClick,statusName,bgColor} = props;
    return (
      <div style={{ backgroundColor: bgColor }} className={styles['container']}>
        <div onClick={onClick}>
          {statusName}
        </div>
      </div>
    )
  };

  export default CurrentStatus