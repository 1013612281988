import { Link } from 'react-router-dom';
import { t } from 'i18next';
import DrawerCompanyList from './DrawerCompanyList';
import addIcon from '../../Assets/Icons/add-icon.svg';
import styles from './drawerCompanies.module.scss';

const DrawerCompanies: React.FC = () => {
  return (
    <div className={styles['drawer-companies-container']}>
      <DrawerCompanyList />
      <Link
        className={styles['drawer-create-company__container']}
        to={'/createCompany'}
      >
        <img src={addIcon} className={styles['icon']} alt="addIcon"/>
        <span className={styles['text']}>{t('LayoutMenu.Create_Company')}</span>
      </Link>
    </div>
  );
};

export default DrawerCompanies;
