import React, { useState } from 'react';
import { t } from 'i18next';
import Employee from 'Assets/Images/Employee.png';
import styles from './employeeComponet.module.scss';

interface IProps {
  src?: string;
  name: string;
  role?: string;
  onClick?: (val?: number) => void;
  typeChecked?: boolean;
  checkSuperior?: boolean;
}

const EmployeeItem: React.FC<IProps> = (props) => {
  const { src, name, role, onClick, typeChecked, checkSuperior } = props;
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <div
      className={`${styles['employee-item']}  ${
        checked || checkSuperior ? styles.checked : ''
      } `}
      onClick={() => {
        typeChecked && setChecked(!checked);
        onClick && onClick();
      }}
    >
      <img
        src={src ? `${process.env.REACT_APP_API_KEY}${src}` : Employee}
        alt='employeeItem'
        className={styles['employee-item__img']}
      />
      <div className={`${styles['employee-content']} `}>
        <div
          className={`${styles['employee-item__name']} ${
            checked || checkSuperior ? styles.checkedName : ''
          } `}
        >
          {name}
        </div>
        <div className={styles['employee-item__role']}>
          {role ? role : t('Employees.user')}
        </div>
      </div>
    </div>
  );
};

export default EmployeeItem;
