import React from "react";
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { AutoComplete, TextArea } from "Components/AuthComponents/AuthInputs";
import { IVacationStatusChangeValues } from "Pages/interfaces";
import { useGeneralHooks } from "Pages/general.hooks";
import styles from '../../ApplicationItems/appliacationItems.module.scss';

interface IProps {
    control: Control<FieldValues | IVacationStatusChangeValues, any>,
    statuses: any,
    errors: FieldErrors<FieldValues | IVacationStatusChangeValues>,
    register: UseFormRegister<FieldValues | IVacationStatusChangeValues>
}

const ChangeStatusModal: React.FC<IProps> = (props) => {
    const { control, statuses, errors, register } = props;
    const { t } = useGeneralHooks();

    return (
        <div>
            <Controller
                control={control}
                name='status_id'
                rules={{
                    required: t('Input_Errors.Required'),
                }}
                render={({ field: { onChange, name, value } }) => {
                    return (
                        <AutoComplete
                            value={value}
                            name={name}
                            onChange={onChange}
                            id='status_id'
                            data={statuses}
                            label={t('Aplication_Page.App_Status.App_Status')}
                            placeholder={t('Aplication_Page.App_Status.Chose_App_Status')}
                            error={errors.status_id}
                        />
                    );
                }}
            />
            <Controller
                control={control}
                name='description'
                rules={{
                    required: t('Input_Errors.Required'),
                }}
                render={({ }) => {
                    return (
                        <TextArea
                            label={t('Aplication_Page.Absence.Reason')}
                            placeholder={t(
                                'Aplication_Page.Absence.Textarea_PlaceHolder'
                            )}
                            register={register}
                            registerName='description'
                            id='absence'
                            error={errors.description}
                            inputStyle={styles.modalTextArea}
                            rows={5}
                        />
                    );
                }}
            />
        </div>
    );
};

export default ChangeStatusModal