import React, { useEffect } from "react";
import { useGeneralHooks } from "Pages/general.hooks";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { AddGroupForm } from "Components/AuthComponents/AuthForms";
import useCreateGroupHooks from "./createGroup.hooks";
import styles from "./createGroup.module.scss"
import { Header } from "Components";
import { HeaderTypes } from "Components/interfaces";

const CreateGroupPage: React.FC = () => {
  const { register, onSubmit, handleSubmit, errors, isLoading, isSuccess } = useCreateGroupHooks();
  const { t, largeScreen, navigate } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();

  useEffect(() => {
    isSuccess && navigate("/main/groups")
  }, [isSuccess, navigate]);

  return (
    <>
      <div className={styles.container}>
        {largeScreen ?
          <div >
            <BreadCrumbs data={pathsToRender} />
          </div>
          :
          <Header
            type={HeaderTypes.Navigation}
            navTitle={t('Aplication_Page.Application')}
            iconOnClick={() => navigate("/main/groups")}
          />
        }
        <div className={styles.form}>
          <AddGroupForm
            register={register}
            onSubmit={handleSubmit(onSubmit)}
            errors={errors}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  )
};

export default CreateGroupPage;
