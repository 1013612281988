import React, { useState } from 'react';
import { AppScreenType } from 'Pages/interfaces';
import useMobileNavDrawerHooks from 'Components/MobileNavDrawer/mobileNavDrawer-hooks';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import MobileNavDrawer from 'Components/MobileNavDrawer/mobileNavDrawer';
import { ApplicationTypes, HeaderTypes } from 'Components/interfaces';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import { ApplyTabulation } from 'Components/ApplyTabulation';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useGeneralHooks } from 'Pages/general.hooks';
import Header from 'Components/Header/header';
import styles from './applications.module.scss';
import '../../App/App.scss';
import { MenuSVG } from 'Assets';

interface IApplicationProps {
  type: ApplicationTypes
}

const Applications: React.FC<IApplicationProps> = (props) => {
  const { type } = props;
  const { t, largeScreen } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();
  const { open, setOpen, onDrawerClose } = useMobileNavDrawerHooks();
  const [screenType, setScreenType] = useState<AppScreenType>(AppScreenType.HISTORY)

  return (
    <>
      {largeScreen ? (
        <div className='page'>
          <div className={styles.searchContainer}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
          <div className={styles.title}>{t('Aplication_Page.Application')}</div>
          <ApplyTabulation
            screenType={screenType}
            setScreenType={setScreenType}
            type={type}
          />
        </div>
      ) : (
        <>
          <div className={styles.mobileBox}>
            <Header
              type={HeaderTypes.Navigation}
              icon={screenType === AppScreenType.HISTORY ? MenuSVG : undefined}
              navTitle={type === ApplicationTypes.Company_Applications ? t('Aplication_Page.Application') : t('LayoutMenu.NavList.My_Applications')}
              iconOnClick={() => {
                screenType === AppScreenType.HISTORY
                ?
                 setOpen(true)
                 :
                 setScreenType(AppScreenType.HISTORY)
                 }}
            />
            <MobileNavDrawer open={open} onClose={onDrawerClose} />
            <div className={styles.mobileContent}>
              <ApplyTabulation
                screenType={screenType}
                setScreenType={setScreenType}
                type={type}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Applications;
