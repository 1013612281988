import { useEffect, useState } from "react";
import { IForgetPassword } from "Pages/interfaces";
import { FieldValues, SubmitHandler } from "react-hook-form";
import {
  useForgetPasswordMutation,
  useSendCodeToEmailMutation,
  useVerifyCodeMutation,
} from "Services/authApi";

const useForgetPassword = () => {
  const [activeComponent, setActiveComponent] = useState<string>("enterEmail");
  const [userEmail, setUserEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [
    sendCodeToEmailRequest,
    { isLoading: sendCodeLoading, isSuccess: codeSendSuccessfully },
  ] = useSendCodeToEmailMutation();
  const [forgetPasswordReq] = useForgetPasswordMutation();
  const [verifyCode, { isSuccess: codeVerifiedSuccessfully }] =
    useVerifyCodeMutation();
  const otpChange = (newValue: string): void => {
    setOtp(newValue);
  };

  const sendEmail: SubmitHandler<IForgetPassword | FieldValues> = async (
    data
  ) => {
    sendCodeToEmailRequest({ email: data.email });
    setUserEmail(data.email);
  };

  useEffect(() => {
    if (codeSendSuccessfully) setActiveComponent("enterCode");
  }, [codeSendSuccessfully]);

  const sendCode = () => {    
    verifyCode({
      email: userEmail,
      code: otp,
    });
  };

  useEffect(() => {
    if (codeVerifiedSuccessfully) {
      setActiveComponent("enterPassword");
    } else {
      setOtp("")
    }
  }, [codeVerifiedSuccessfully]);

  const sendPassword: SubmitHandler<IForgetPassword | FieldValues> = (data) => {
    forgetPasswordReq({
      code: otp,
      email: data.email,
      password: data.password,
    }).then(
      (res: any) =>
        res.data.code === 200 && setActiveComponent("passwordChanged")
    );
  };

  return {
    otp,
    userEmail,
    sendCodeLoading,
    activeComponent,
    sendCode,
    sendEmail,
    otpChange,
    sendPassword,
    verifyCode,
  };
};

export default useForgetPassword;
