import React from 'react';
import { FieldErrors, FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { InputField } from 'Components/AuthComponents/AuthInputs';
import { UserInfoFormValues } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import styles from './changePassword.module.scss'

export interface IChangePasswordData {
  register: UseFormRegister<FieldValues | UserInfoFormValues>,
  errors: FieldErrors<FieldValues | UserInfoFormValues>,
  password: string,
  confirmPassword: string,
  NewPassword: string,
  watch: UseFormWatch<UserInfoFormValues | FieldValues>
};

export interface IChangePasswordProps {
  data: IChangePasswordData
}

const ChangePasswordForm: React.FC<IChangePasswordProps> = (props) => {
  const { register, errors, NewPassword, password, confirmPassword, watch } = props.data;
  const { t } = useGeneralHooks();
  return (
    <div className={styles.container}>
      <InputField
        inputStyle={styles.inputField}
        type={"text"}
        error={errors.password}
        id="password"
        placeholder={t("Login_Form.Password_label")}
        label={t("Login_Form.Password_label")}
        register={register}
        registerName={password}
        message={t('Input_Errors.Password')}
        patternValue={
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#/+_,.^])[A-Za-z\d@$#!%*.,^/+_?&]{8,}$/gm
        }
      />
      <InputField
        label={t('Forget_Password.Third_page.New_password_label')}
        placeholder={t('Forget_Password.Third_page.New_password_label')}
        id='newPassword'
        register={register}
        registerName={NewPassword}
        inputStyle={styles.inputField}
        message={t('Input_Errors.Password')}
        patternValue={
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#/+_,.^])[A-Za-z\d@$#!%*.,^/+_?&]{8,}$/gm
        }
        error={errors.newPassword}
      />
      <InputField
        label={t('Forget_Password.Third_page.Confirm_Password_label')}
        placeholder={t('Forget_Password.Third_page.New_password_label')}
        id='newPassword'
        register={register}
        registerName={confirmPassword}
        inputStyle={styles.inputField}
        message={t('Input_Errors.Password')}
        validation={
          (val) => watch(NewPassword) == val || t('Input_Errors.PasswordMatch')
        }
        error={errors.confirmPassword}
      />

    </div>
  )
}

export default ChangePasswordForm
