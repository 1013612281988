import { useGetMeQuery } from "Services/authApi"

const useRoleHooks = () => {
    const { data } = useGetMeQuery();
    const activeCompanyId = data?.data.activeCompanyId;
    const mekumId = data?.data.employee?.mekumId;
    const currentRole = data?.data.userRoleInCompany?.filter((company) => {
        return company.companyId === activeCompanyId
    })[0]?.role?.name; 
    const highPositions = ["HR", "CEO", "TL"];
    const highAccesses = ["HR", "CEO"];
    const highPosition = highPositions.includes(currentRole as string);
    const hasHighAccess = highAccesses.includes(currentRole as string);
    const isVisitor = !data?.data.employee?.id ? true : false

    return {
        highPosition,
        hasHighAccess,
        isVisitor,
        currentRole,
        mekumId
    }

}

export default useRoleHooks