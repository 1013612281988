import { FieldValues } from "react-hook-form";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";
import {
  IAbsenseResp,
  IPaginationQuery,
  IVacationByIdResp,
  IChangeStatusData,
  ICreateVacationResp,
  IAvailableStatusesResp,
} from "../responce_types";

export const vacationApi = createApi({
  reducerPath: "vacationApi",
  tagTypes: ["Vacation"],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getVacationList: builder.query<IAbsenseResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/vacation/${offset}/${page}`,
        method: "GET",
      }),
      providesTags: ["Vacation"],
    }),
    getUserVacationList: builder.query<IAbsenseResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/vacation/my_list/${offset}/${page}`,
        method: "GET",
      }),
      providesTags: ["Vacation"],
    }),
    createVacation: builder.mutation<ICreateVacationResp, FieldValues>({
      query: (referenceData) => ({
        url: "/vacation/",
        method: "POST",
        data: referenceData,
      }),
      invalidatesTags: ["Vacation"],
    }),
    getVacationById: builder.query<IVacationByIdResp, number>({
      query: (id) => ({
        url: `/vacation/${id}`,
        method: "GET",
      }),
      providesTags: ["Vacation"]

    }),
    getVacationAvailableStatuses: builder.query<IAvailableStatusesResp, number>({
        query: (id) => ({
          url: `/vacation/available_statuses/${id}`,
          method: "GET",
        }),
      }
    ),
    changeVacationStatus: builder.mutation<number, IChangeStatusData>({
      query: (data) => ({
        url: `/vacation/update_status/${data?.id}`,
        method: "PUT",
        data: {
          description: data.description,
          status_from_id: data.status_from_id,
          status_to_id: data.status_to_id,
          userId: data?.userId
        },
      }),
      invalidatesTags: ["Vacation"],
    }),
  }),
});

export const {
  useGetVacationListQuery,
  useGetUserVacationListQuery,
  useGetVacationByIdQuery,
  useCreateVacationMutation,
  useChangeVacationStatusMutation,
  useGetVacationAvailableStatusesQuery,
} = vacationApi;
