import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonTypes } from 'Components/interfaces'
import Button from 'Components/Button/button'
import styles from './forgetPassword.module.scss'
import AuthFormTitle from '../AuthForms/authFormTitle'
import BottomLink from '../BottomLink/bottomLink'
import { Link } from 'react-router-dom'

const PasswordChanged: React.FC = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className={styles.passwordChanged}>
                <AuthFormTitle title={t('Forget_Password.Fourth_page.Title')} description={t('Forget_Password.Fourth_page.Description')} />
                <div className={styles.button}>
                    <Link className={styles.link} to='/'>
                        <Button
                            buttonType={ButtonTypes.Primery}
                            type='button'
                            title={t('Forget_Password.Fourth_page.Sign_in')}
                        />
                    </Link>
                </div>
                {!(window.innerWidth <= 920) && <div className={styles.bottomBox}>
                    <BottomLink
                        text={t('Forget_Password.Fourth_page.Remember_password')}
                        linkTitle={t('Forget_Password.Fourth_page.Sign_in')}
                        link='/' />
                </div>}
            </div>
        </React.Fragment>
    )
}

export default PasswordChanged
