import React from 'react';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import { ButtonTypes } from 'Components/interfaces';
import Button from 'Components/Button/button';
import { EmployeeList } from 'Components';
import { Header } from 'Components';
import styles from './employees.module.scss';
import useRoleHooks from 'Routers/role-hooks';
import UseEmployeesHooks from 'Components/Employees/employees.hooks';

const Employees: React.FC = () => {
  const { pathsToRender } = useAppPathsHooks();
  const { navigate, t, largeScreen } = useGeneralHooks();
  const { hasHighAccess } = useRoleHooks();
  const { allUsersList, setPage } = UseEmployeesHooks();

  return (
    <div className={styles.container}>
      {largeScreen ? (
        <div>
          <div className={styles.searchContainer}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
          <div className={styles.textbtn}>
            <p className={styles.emptext}>{t('Employees.Header')}</p>
            <div className={styles.btn}>
              {
                hasHighAccess &&
                <Button
                  buttonType={ButtonTypes.Primery}
                  buttonStyle='width: 28px;'
                  title={t('Employees.Add_Employee_Title')}
                  type='submit'
                  onClick={() => navigate('AddEmployee')}
                />
              }
            </div>
          </div>
          <EmployeeList onClick={(id) => navigate(`/main/employees/${id}`)} />
        </div>
      ) : (
        <div className={styles['mob-emp__cont']}>
          <div>
            <Header
              type={HeaderTypes.Navigation}
              navTitle={t('Employees.Header')}
              iconOnClick={() => navigate(-1)}
            />
            <div className={styles.itemcontainer}>
              <EmployeeList
                onClick={(id) => navigate(`/main/employees/${id}`)}
              />
            </div>
          </div>
          <div className={styles.btn}>
            {hasHighAccess && <Button
              buttonType={ButtonTypes.Primery}
              buttonStyle='width: 28px;'
              title={t('Employees.Add_Employee_Title')}
              type='submit'
              onClick={() => navigate('AddEmployee')}
            />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Employees;
