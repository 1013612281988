import React from 'react';
import { Header, LeftSide, RightSide } from 'Components';
import InviteForm from 'Components/AuthComponents/AuthForms/InviteForm/inviteForm';
import { Vector } from 'Assets';
import styles from './inviteRequest.module.scss';
import { useGetRolesQuery } from 'Services/authApi';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import { t } from 'i18next';

const InviteRequestPage: React.FC = () => {
  const { data: rolesData } = useGetRolesQuery();
  const { mobileScreen } = useGeneralHooks();
  return (
    <div className={styles.container}>
      {!mobileScreen ? (
        <LeftSide
          title={t('Leftside_Texts.Title')}
          describtion={t('Leftside_Texts.Description')}
        >
          <img src={Vector} alt='Vector_Image' />
        </LeftSide>
      ) : (
        <Header type={HeaderTypes.Logo} />
      )}
      <RightSide>
        <InviteForm
          roles={rolesData?.data}
          title={t('Invite_Request.Form_Title')}
        />
      </RightSide>
    </div>
  );
};

export default InviteRequestPage;
