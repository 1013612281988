import { t } from 'i18next';
import defaultIcon from 'Assets/Images/Employee.png';
import { InvitationsStatus } from 'Components/interfaces';
import useMyInvitationsHooks from './myInvitations.hooks';
import styles from './myInvitations.module.scss';

interface IProps {
  src: string | null;
  name: string;
  surname: string;
  time: string;
  invitor: string;
  role: string;
  invitationId: number;
  currentStatus: InvitationsStatus;
  id: number
}

const MyInvitationsItem: React.FC<IProps> = (props) => {
  const { src, name, surname, time, invitor, role, currentStatus, id } = props;
  const { InvitationStatuses } = useMyInvitationsHooks(id);

  return (
    <div className={styles['my-invitation-item__container']}>
      <div className={styles['my-invitation-item__content']}>
        <img
          className={styles['img']}
          src={src ? `${process.env.REACT_APP_API_KEY}${src}` : defaultIcon}
          alt='invitationImg'
        />
        <div className={styles['info']}>
          <div className={styles['name-time']}>
            <span className={styles['name']}>
              {name} {surname}
            </span>
            <span className={styles['time']}>{time}</span>
          </div>
          <div className={styles['author-role']}>
            <span>{invitor}</span> {t('My_Invitations.Invited_You_As')}{' '}
            <span>{role}</span>
          </div>
        </div>
      </div>
      <>{InvitationStatuses[`${currentStatus}`]}</>
    </div>
  );
};

export default MyInvitationsItem;
