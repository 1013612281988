import React from "react";
import { useParams } from "react-router";
import { t } from "i18next";
import useRoleHooks from "Routers/role-hooks";
import { HeaderTypes } from "Components/interfaces";
import { useGeneralHooks } from "Pages/general.hooks";
import { useGetUserByIdQuery } from "Services/employeesApi";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import EmployeeProfileButtons from "./employeeProfileButtons";
import { Header, Loading, MobileNavDrawer } from "Components";
import { EmployeeAppHistory } from "Components/EmployeeHistory";
import { SearchInput } from "Components/AuthComponents/AuthInputs";
import useMobileNavDrawerHooks from "Components/MobileNavDrawer/mobileNavDrawer-hooks";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import EmployeeInfo from "./employeeInfo";
import styles from "./employeeProfile.module.scss";

export type EmployeeTypes = "employee" | "user";

export interface IEmployeeProfile {
  type?: EmployeeTypes;
  image?: string | null;
  role?: string;
  email?: string;
  name?: string;
}

const EmployeeProfile: React.FC<IEmployeeProfile> = () => {
  const { id } = useParams();
  const { largeScreen } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();
  const { hasHighAccess } = useRoleHooks();
  const { open, setOpen, onDrawerClose } = useMobileNavDrawerHooks();
  const { data: user, isLoading } = useGetUserByIdQuery(id!);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {largeScreen ? (
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbBox}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
              <h2 className={styles.title}>
                {`${user?.data?.name} ${user?.data?.surname}`}
              </h2>
            </div>
          ) : (
            <div className={styles.mobileBox}>
              <Header
                type={HeaderTypes.Navigation}
                navTitle={`${user?.data?.name} ${user?.data?.surname}`}
                iconOnClick={() => setOpen(true)}
              />
              <MobileNavDrawer open={open} onClose={onDrawerClose} />
            </div>
          )}
          <div className={styles.employeProfile}>
            <div>
              <EmployeeInfo
                image={user?.data.avatar}
                role={user?.data?.userRoleInCompany[0]?.role?.name}
                name={`${user?.data.name} ${user?.data.surname}`}
                email={user?.data?.email}
              />
              {user?.data?.employee?.mekumId && (
                <div className={styles["already-connected"]}>
                  {t("Mekum.Already_Connected")}
                </div>
              )}
            </div>
            <div className={styles.buttons}>
              {hasHighAccess && (
                <EmployeeProfileButtons
                  employeeId={user?.data?.employee?.id!}
                  employeeInfo={user?.data}
                  id={user?.data?.id}
                />
              )}
            </div>
          </div>
          {hasHighAccess && 
          <EmployeeAppHistory
          employeeId={user?.data.id}
          />
          }
        </>
      )}
    </div>
  );
};
export default EmployeeProfile;
