import React from 'react'
import useLogOutHooks from './logOut-hooks';
import { LogOutIcon } from 'Assets';
import styles from './logOut.module.scss';

interface ILogOutProps {
    containerStyles?: string
};

const LogOutComponent: React.FC<ILogOutProps> = (props) => {
    const { containerStyles } = props;
    const { title, logOut } = useLogOutHooks();
    return (
        <>
            <div
                className={`${styles.container} ${containerStyles}`}
                onClick={logOut}
            >
                <div>
                    <img src={LogOutIcon} alt='logOutIcon' />
                </div>
                <div className={styles.logOutText}>{title}</div>
            </div>
        </>
    )
}

export default LogOutComponent
