import React, { Dispatch, SetStateAction } from "react";
import {
  FieldValues,
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  Controller,
  Control,
} from "react-hook-form";
import { AutoComplete, InputField } from "Components/AuthComponents/AuthInputs";
import { ButtonTypes, VacationFormValues } from "Components/interfaces";
import { useGeneralHooks } from "Pages/general.hooks";
import Arrow from "Assets/Icons/arrow_back.svg";
import { IRole } from "Services/responce_types";
import Button from "Components/Button/button";
import styles from "../appliacationItems.module.scss";

interface IVacationFormProps {
  onSubmit: () => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  setShowDataTable?: Dispatch<SetStateAction<boolean>>;
  watch: UseFormWatch<FieldValues>;
  total?: number;
  employeeList?: IRole[];
  control?: Control<FieldValues | VacationFormValues, any>;
  role?: string;
}

const VacationForm: React.FC<IVacationFormProps> = (props) => {
  const { t, largeScreen, mobileScreen } = useGeneralHooks();
  const {
    register,
    errors,
    isValid,
    watch,
    onSubmit,
    total,
    setShowDataTable,
    employeeList,
    control,
    role,
  } = props;

  const minDate = new Date().toISOString().split("T")[0];

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.header}>
          {!largeScreen && (
            <div className={styles.title}>
              {t("Aplication_Page.Vacation.Title")}
            </div>
          )}
          <div className={styles.describtion}>
            {t("Aplication_Page.Vacation.Description")}
          </div>
        </div>
        <form onSubmit={onSubmit} className={`${styles.formBox} formBox`}>
          <div>
            <div className={styles.form}>
              <div className={styles.datePicker}>
                <InputField
                  type="date"
                  minDate={minDate}
                  id="dateStart"
                  label={
                    mobileScreen
                      ? t("Aplication_Page.Vacation.Start")
                      : t("Aplication_Page.Vacation.Start_Date")
                  }
                  register={register}
                  registerName="start_date"
                  error={errors.startDate}
                  validation={(value) =>
                    value >= minDate || t("Input_Errors.Invalid_Date")
                  }
                  inputStyle={styles.inputField}
                />
                <InputField
                  type="date"
                  minDate={minDate}
                  id="endDate"
                  label={
                    mobileScreen
                      ? t("Aplication_Page.Vacation.End")
                      : t("Aplication_Page.Vacation.End_Date")
                  }
                  register={register}
                  registerName="end_date"
                  validation={(value) =>
                    value >= watch("start_date") ||
                    t("Input_Errors.Invalid_Date")
                  }
                  error={errors.endDate}
                  inputStyle={styles.inputField}
                />
              </div>
              <div className={styles.totalItemBox}>
                <div className={styles.totalLabel}>
                  {t("Aplication_Page.Vacation.Total")}
                </div>
                <div className={styles.totalInput}>
                  <div className={styles.totalResult}>{isValid && total}</div>
                </div>
              </div>
            </div>
            {role && role === "EMP" ? (
              <InputField
                type="text"
                disabled={true}
                id="worker"
                label={t("Aplication_Page.Desmissal.Employee")}
                register={register}
                registerName="user_id"
                inputStyle={styles.inputField}
              />
            ) : (
              <Controller
                control={control}
                name="user_id"
                rules={{
                  required: t("Input_Errors.Required"),
                }}
                render={({ field: { onChange, name } }) => {
                  return (
                    <AutoComplete
                      name={name}
                      onChange={onChange}
                      id="user_id"
                      data={employeeList}
                      label={t("Aplication_Page.Desmissal.Employee")}
                      placeholder={t("Aplication_Page.Desmissal.Employee_placeholder")}
                      error={errors.user_id}
                    />
                  );
                }}
              />
            )}
          </div>
          <div className={styles.buttonContent}>
            <Button
              title={t("Aplication_Page.Button")}
              type="button"
              buttonStyle={styles.withoutBtn}
              buttonTitleStyle={styles.withoutBtnTitle}
              buttonType={ButtonTypes.WithoutBg}
              onClick={() => {
                setShowDataTable!(true);
              }}
            />
            <Button
              type="submit"
              rightIcon={Arrow}
              buttonTitleStyle={styles.BtnTitle}
              title={t("Aplication_Page.Vacation.Buuton_Apply")}
              buttonType={ButtonTypes.ButtonWithIcon}
              buttonStyle={styles.btn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default VacationForm;
