import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { useApplicationItem } from 'Components/Application/ApplicationItems/applicationItem.hooks';
import { useGeneralHooks } from 'Pages/general.hooks';
import styles from '../history.module.scss';
import HistoryItemStatus from '../Status/historyItemStatus';
import { IPensionByIdRespResult } from 'Services/responce_types';
import { t } from 'i18next';

export interface IPensionHistoryProps {
  data: IPensionByIdRespResult[];
}
const PensionHistoryItem: React.FC<IPensionHistoryProps> = (props) => {
  const { data } = props;
  const { formatDate } = useApplicationItem();
  const options = (data ?? []).map((item) => item);
  const { navigate } = useGeneralHooks();

  return (
    <>
      {options?.map((item, index) => {
        return (
          <TableRow
            className={styles['application-row']}
            key={index}
            onClick={() =>
              navigate(`/main/documents/applications/pension/${item.id}`)
            }
          >
            <TableCell>{`${item.user.name} ${item.user.surname}`}</TableCell>
            <TableCell>{formatDate(item.startDate)}</TableCell>
            <TableCell>{formatDate(item.endDate)}</TableCell>
            <TableCell>
              <HistoryItemStatus
                bgColor={item?.status?.color}
                text={t(`Statuses_From_Server.${item?.status?.name}`)}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default PensionHistoryItem;
