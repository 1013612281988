import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetMekumEmployeesQuery } from "Services/mekumApi";
import { RootState } from "Store/store";

const UseMekumEmployeeHooks = () => {
  const [page, setPage] = useState<number>(1);
  const [inputValue, setInputValue] = useState<string>("");
  const mekumUserInfo = useSelector((state: RootState) => state?.mekum?.connectMekumUser);

  const {
    data: mekumEmployeeList,
    isLoading,
    refetch,
  } = useGetMekumEmployeesQuery({
    page,
    offset: 10,
    search: inputValue,
  });

  return {
    mekumEmployeeList,
    setPage,
    page,
    isLoading,
    mekumUserInfo,
    setInputValue,
    refetch,
  };
};

export default UseMekumEmployeeHooks;
