import { IApplicationTabs } from 'Components/interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useApplyTabulation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate('vacation');
  }, []);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const appTabs: IApplicationTabs[] = [
    {
      label: t('Aplication_Page.Tab_Labels.Vacation'),
      path: 'vacation',
    },
    {
      label: t('Aplication_Page.Tab_Labels.Absence'),
      path: 'absence',
    },
    {
      label: t('Aplication_Page.Tab_Labels.Pension'),
      path: 'pension',
    },
    {
      label: t('Aplication_Page.Tab_Labels.Release'),
      path: 'dismissal',
    },
    {
      label: t('Aplication_Page.Tab_Labels.Reference'),
      path: 'references',
    },
  ];

  return {
    value,
    appTabs,
    handleChange,
    navigate,
    a11yProps,
  };
};
