import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginSocialFacebook, IResolveParams } from 'reactjs-social-login';
import FacebookLogo from 'Assets/Images/Facebook.png';
import GoogleLogo from 'Assets/Images/google.jpg';
import AppleLogo from 'Assets/Images/apple.jpg';
import styles from './socialLogin.module.scss';
import {
  useFacebookSignInMutation,
  useGoogleSignInMutation,
} from 'Services/socialLoginApi';

const SocialLogin: React.FC = () => {
  const { t } = useTranslation();
  const [signInWithGoogle, { isSuccess: googleSignedIn }] =
    useGoogleSignInMutation();
  // const [signInWithFacebook, { isSuccess: facebookSignedIn }] =
  //   useFacebookSignInMutation();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      signInWithGoogle({ id_token: tokenResponse.access_token });
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.linePart}>
        <div className={styles.line} />
        <div className={styles.text}>{t('Login_Form.Social_Title')}</div>
        <div className={styles.line} />
      </div>
      <div className={styles.social}>
        <LoginSocialFacebook
          isOnlyGetToken
          appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
          onResolve={({ data }: IResolveParams) => {
            signInWithGoogle(data?.accessToken);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <img
            src={FacebookLogo}
            alt='Facebook'
            className={styles.socialIcon}
          />
        </LoginSocialFacebook>
        <div onClick={() => login()}>
          <img src={GoogleLogo} alt='Google' className={styles.socialIcon} />
        </div>
      </div>
    </div>
  );
};

export default SocialLogin;
