import { ILanguages } from 'Components/interfaces';
import useLanguagesHooks from './languages.hooks';
import styles from './languages.module.scss';

const Languages: React.FC = () => {
  const {
    show,
    activeLangSrc,
    filteredLanguages,
    changeActiveLanguage,
    setActiveLangSrc,
    setShow,
  } = useLanguagesHooks();

  return (
    <div className={styles['languages-container']}>
      <img
        onClick={() => setShow(!show)}
        src={activeLangSrc}
        className={styles['acttive-lang']}
        alt='langIcon'
      />
      {show && (
        <div className={styles['languages-list__container']}>
          {filteredLanguages.map((item: ILanguages) => {
            return (
              <img
                src={item.icon}
                key={item.id}
                onClick={() => {
                  setShow(false);
                  setActiveLangSrc(item.icon);
                  changeActiveLanguage(item.code);
                }}
                alt='langIcon'
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Languages;
