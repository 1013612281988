import React from 'react'
import { Link } from 'react-router-dom';
import styles from "./authForm.module.scss";
import { getI18n } from 'react-i18next';


interface IProps {
  title: string,
  description?: string | boolean,
  linkTitle?: string,
  link?: string,
  email?: string
}

const AuthFormTitle: React.FC<IProps> = (props) => {
  const { title, link, description, linkTitle, email } = props
  const language = getI18n().language
  return (
    <>
      <div className={styles.title}>
        {title}
      </div>
      {description && <div className={styles.description}>
        {description}
        {link && <Link to={link} className={styles.link}>
          {linkTitle}
        </Link>}
        {email && <span><span className={styles.email}>{email}</span> {language === 'am' && 'էլ․ հասցեին'} </span>}
      </div>}
    </>
  )
}

export default AuthFormTitle