import { t } from "i18next";
import MekumEmployeeList from "./mekumEmployeeList";
import searchEmpIcon from "Assets/Icons/user-search.svg";
import UseMekumEmployeeHooks from "./mekumEmployee.hooks";
import styles from "./mekumEmployee.module.scss";

interface IProps {
  handleClose?:()=>void
};

const MekumEmployeeModal: React.FC<IProps> = ({handleClose}) => {
  const { setInputValue, refetch, mekumEmployeeList } = UseMekumEmployeeHooks();

  return (
    <div>
      <div className={styles["search-input-container"]} >
        <img src={searchEmpIcon} onClick={refetch} alt="searchIcon" />
        <input type="text" placeholder={t("Mekum.Search_Mekum_Emp_Placeholder")} onChange={(e) => setInputValue(e.target.value)} className={styles["search-mekum-input"]}></input>
      </div>
      <MekumEmployeeList
       mekumEmployeeList={mekumEmployeeList}
       handleClose={handleClose}
       />
    </div>
  );
};

export default MekumEmployeeModal;
