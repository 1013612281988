import { FieldValues } from 'react-hook-form';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../API';
import {
  IAvailableStatusesResp,
  IChangeStatusData,
  IPaginationQuery,
  IReferenceByIdResp,
  IReferenceListResp,
} from '../responce_types';

export const referenceApi = createApi({
  reducerPath: 'referenceApi',
  tagTypes: ['Reference'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getReferenceList: builder.query<IReferenceListResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/reference/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Reference'],
    }),
    getUserReferenceList: builder.query<IReferenceListResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/reference/my_list/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Reference'],
    }),
    createReference: builder.mutation<number, FieldValues>({
      query: (referenceData) => ({
        url: '/reference',
        method: 'POST',
        data: referenceData,
      }),
      invalidatesTags: ['Reference'],
    }),
    getReferenceById: builder.query<IReferenceByIdResp, number>({
      query: (id) => ({
        url: `/reference/${id}`,
        method: 'GET',
      }),
      providesTags: ['Reference'],
    }),
    getReferenceAvailableStatuses: builder.query<IAvailableStatusesResp, number>({
      query: (id) => ({
        url: `/reference/available_statuses/${id}`,
        method: "GET",
      }),
    }
  ),
  changeReferenceStatus: builder.mutation<number, IChangeStatusData>({
    query: (data) => ({
      url: `/reference/update_status/${data?.id}`,
      method: "PUT",
      data: {
        description: data.description,
        status_from_id: data.status_from_id,
        status_to_id: data.status_to_id,
        userId: data?.userId
      },
    }),
    invalidatesTags: ['Reference'],
  }),
  }),
});

export const {
  useGetReferenceListQuery,
  useCreateReferenceMutation,
  useGetReferenceByIdQuery,
  useGetUserReferenceListQuery,
  useGetReferenceAvailableStatusesQuery,
  useChangeReferenceStatusMutation
} = referenceApi;
