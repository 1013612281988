import { useState } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useGeneralHooks } from "Pages/general.hooks";

const useMarkSuperiorHooks = () => {
    const { largeScreen, navigate } = useGeneralHooks();
    const [superiorId, setSuperiorId] = useState<number | null>();

    const markSuperiorReq = () => {
       if(!superiorId){
            toast.warning(t("Groups.Warning_Message"))
            return
       }
      
    }
    const checkSuperior = (userId: number) => {
        if(superiorId === userId){
            setSuperiorId(null)
        }else{
            setSuperiorId(userId)
        }
    }

    return {
        markSuperiorReq,
        checkSuperior,
        navigate,
        superiorId,
        largeScreen,
    }
}

export default useMarkSuperiorHooks